<template>
  <v-card class="milestonesCard">
    <v-tabs v-model="tab" dark align-tabs="start">
      <v-tab value="safetyMilestones">Safety Project Milestones</v-tab>
      <v-tab value="documents">Documents</v-tab>
    </v-tabs>
    <div v-if="showBanner">
      <BannerMessage
        :successBanner="true"
        :message="'Safety Milestones Saved Successfully.'"
        @hideBanner="hideSuccessBanner"
      ></BannerMessage>
    </div>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="safetyMilestones">
          <router-link :to="`/GCMS/${id}`">
            &lt; Return to Project Information Page
          </router-link>
          <div class="page-title">
            <h1>Safety Project Milestones</h1>
          </div>
          <div class="mb-40">
            <v-row cols="12">
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <div class="d-flex justify-content-end mb-20">
                  <span class="expand-toggle" @click="expandToggle('expand')"
                    >Expand All</span
                  >
                  <span class="ml-10">|</span>
                  <span
                    class="expand-toggle ml-10 mr-10"
                    @click="expandToggle('collapse')"
                    >Collapse All</span
                  >
                </div>
                <v-expansion-panels v-model="panel" multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-title class="pannelColor"
                      ><div class="panel-header">
                        Scoping
                      </div></v-expansion-panel-title
                    >
                    <v-expansion-panel-text>
                      <ScopingMilestones
                        :id="id"
                        @saveMilestone="saveData"
                        @updateRefreshFlag="updateScopingData"
                        :refreshData="fetchScoping"
                      ></ScopingMilestones>
                    </v-expansion-panel-text>
                  </v-expansion-panel>

                  <v-expansion-panel class="mt-20">
                    <v-expansion-panel-title class="pannelColor"
                      ><div class="panel-header">
                        Authorization
                      </div></v-expansion-panel-title
                    >
                    <v-expansion-panel-text>
                      <AuthorizationMilestones
                        :id="id"
                        @saveMilestone="saveData"
                        @updateRefreshFlag="updateAuthorizationData"
                        :refreshData="fetchAuthorization"
                      ></AuthorizationMilestones>
                    </v-expansion-panel-text>
                  </v-expansion-panel>

                  <v-expansion-panel class="mt-20">
                    <v-expansion-panel-title class="pannelColor"
                      ><div class="panel-header">
                        Coordination
                      </div></v-expansion-panel-title
                    >
                    <v-expansion-panel-text>
                      <CoordinationMilestones
                        :id="id"
                        @saveMilestone="saveData"
                        @updateRefreshFlag="updateCoordinationData"
                        :refreshData="fetchCoordination"
                      ></CoordinationMilestones>
                    </v-expansion-panel-text>
                  </v-expansion-panel>

                  <v-expansion-panel class="mt-20">
                    <v-expansion-panel-title class="pannelColor"
                      ><div class="panel-header">
                        Contract Development
                      </div></v-expansion-panel-title
                    >
                    <v-expansion-panel-text>
                      <ContractDevelopmentMilestones
                        :id="id"
                        @saveMilestone="saveData"
                        @updateRefreshFlag="updateContractDevelopmentData"
                        :refreshData="fetchContractDevelopment"
                      ></ContractDevelopmentMilestones>
                    </v-expansion-panel-text>
                  </v-expansion-panel>

                  <v-expansion-panel class="mt-20">
                    <v-expansion-panel-title class="pannelColor"
                      ><div class="panel-header">
                        Construction
                      </div></v-expansion-panel-title
                    >
                    <v-expansion-panel-text>
                      <ConstructionMilestones
                        :id="id"
                        @saveMilestone="saveData"
                        @updateRefreshFlag="updateConstruction"
                        :refreshData="fetchConstructionData"
                      ></ConstructionMilestones>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br />
                <div class="d-flex justify-content-end align-items-center">
                  <PrimaryButton
                    :buttonText="'Save'"
                    @buttonClicked="postData"
                    :disabled="enablePost || !isUserMilestonesAccess.update"
                  ></PrimaryButton>
                  <SecondaryButton
                    :buttonText="'Cancel'"
                    @buttonClicked="clear"
                    class="ml-10"
                  ></SecondaryButton>
                </div>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </div>
        </v-window-item>
        <v-window-item value="documents">
          <div class="page-title">
            <h1>Safety Milestones Documents</h1>
          </div>
          <ViewUploadSafetyMilestoneDocuments
            @updateRefreshFlag="refreshData($event)"
          />
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>
  
  <script>
import SafetyMilestones from "@/modules/manageproject/scripts/SafetyMilestones.js";
export default SafetyMilestones;
</script>
  <style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
@import "@/modules/manageproject/assets/milestones.scss";
</style>
  