import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { reactive, ref, toRefs, watch } from "vue";
import { CONFIG_ENDPOINT } from "../../../../../constants/Endpoints";
export default {
  components: { PrimaryButton, SecondaryButton },
  setup() {
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      event: null,
    });
    let valueChanged = ref(false);
    const originalValue = ref();
    const isInitialised = ref(false);
    const open = (title, event) => {
      state.dialog = true;
      state.title = title;
      getLookupList(event);
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const getLookupList = (event) => {
      axios
        .post(CONFIG_ENDPOINT.GET_ASSIGN_DISTRICTS_CHECKBOX_LIST, event)
        .then((res) => {
          state.event = {
            ...event,
            lookup: res.data.lookup.map((district) => {
              return {
                title: district,
                checked: res.data?.assignedDistricts
                  ?.split(",")
                  ?.includes(district),
              };
            }),
          };
          originalValue.value = JSON.parse(
            JSON.stringify(state.event?.assignedDistricts)
          );
          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
        })
        .catch((err) => {});
    };
    watch(
      () => [state?.event?.lookup],
      () => {
        if (
          isInitialised.value &&
          originalValue.value !=
            state.event.lookup
              ?.filter((district) => district?.checked)
              ?.map((district) => district.title)
              ?.join(",")
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );

    const saveData = () => {
      let payload = {
        userId: state.event.userId,
        userName: state.event.userName,
        districtNumber: state.event.lookup
          .filter((district) => district.checked)
          .map((district) => district.title),
        roles: state.event.roles,
        userRoles: state.event.userRoles,
        assignedDistricts: state.event.lookup
          .filter((district) => district.checked)
          .map((district) => district.title)
          .join(","),
        organization: state.event.organization,
      };
      axios
        .post(CONFIG_ENDPOINT.UPDATE_ASSIGN_DISTRICTS, payload)
        .then((res) => {
          state.resolve({ success: true });
          state.dialog = false;
        })
        .catch((err) => {
          state.resolve({ success: false, errorMsg: err });
          state.dialog = false;
        });
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          state.resolve(false);
          state.dialog = false;
          valueChanged.value = false;
        }
      } else {
        state.resolve(false);
        state.dialog = false;
        valueChanged.value = false;
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = null;
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    return {
      ...toRefs(state),
      saveData,
      closeDialog,
      open,
      afterModalLeave,
    };
  },
};
