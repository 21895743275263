<template>
  <v-card-text>
    <SafetyMilestones
      @refreshMpmsData="$emit('refreshMpmsData')"
    ></SafetyMilestones>
  </v-card-text>
</template>
  <script>
import SafetyMilestones from "@/modules/manageproject/components/SafetyMilestones.vue";
export default {
  name: "ViewSafetyMilestones-page",
  components: {
    SafetyMilestones: SafetyMilestones,
  },
};
</script>
  