import useFieldEntry from "@/composables/fieldEntry";import {
  arrayRequiredRule,
  minimumRule,
  numericRule,
  reasonForUpdateRequiredRule,
  requiredRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import ReasonForUpdate from "@/modules/dot-details/components/ReasonForUpdate.vue";
import MonthYearPicker from "@/modules/shared/components/MonthYearPicker.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";

import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";

export default {
  name: "PartIIITrafficControl",
  emits: ["showBanner", "valueChanged"],
  props: {
    dotId: { default: null },
  },
  components: {
    PrimaryButton,
    SecondaryButton,
    ReasonForUpdate,
    MonthYearPicker,
  },
  setup(props, context) {
    const reasonToUpdateList = ref([]);
    const AdvanceWarningSigns = ref([]);
    const GChannelization = ref([]);
    const GateConfiguration = ref([]);
    const GateConfiguration2 = ref([]);
    const IncandescantOrLed = ref([]);
    const trainActiveWarning = ref([]);
    const SignalInterconnection = ref([]);
    const MonitoringDevice = ref([]);
    const pavementMarkings = ref([]);
    const LED = ref([]);
    const lowGroundClearance = ref([
      {
        label: "Yes",
        count: 10,
        enabled: false,
      },
      { label: "No", count: 10, enabled: false },
    ]);
    const PartIIILookupData = ref();
    const hwyTrafficSignalPreemption = ref([]);
    const yesNoDropdown = ref(["Yes", "No"]);
    const signsOrSignals = ref();
    let PartIIIDetails = ref(null);
    let isEdit = ref(false);
    let isFormValid = ref(false);
    let partIIIForm = ref(null);
    const updatedDataFlagger = ref(false);
    const initialLoad = ref(false);

    const isInitialised = ref(false);
    const valueChanged = ref(false);
    let originalValue = ref(null);
    let originalAdvanceWarningSignsValue = ref(null);
    const { allowAlphanumericOnly } = useFieldEntry();
    const isUserGradeCrossingAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const gradeCrossingAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_CRTUPD_ACCESS
      );
      isUserGradeCrossingAccess.value.read = readResponse || false;
      isUserGradeCrossingAccess.value.update = updateResponse || false;
    };

    onMounted(async () => {
      await gradeCrossingAccessCheck();
      if (isUserGradeCrossingAccess.value.read) {
        await getPartDetails();
      }
    });
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    async function getPartDetails() {
      await axios
        .get(`${CONFIG_ENDPOINT.PARTIII}${dotId.value}`)
        .then((response) => {
          PartIIIDetails.value = response?.data;
          reasonToUpdateList.value = [];
          signsOrSignals.value = [];
          AdvanceWarningSigns.value = [];
          pavementMarkings.value = [];
          GChannelization.value = [];
          GateConfiguration.value = [];
          GateConfiguration2.value = [];
          IncandescantOrLed.value = [];
          trainActiveWarning.value = [];
          MonitoringDevice.value = [];
          SignalInterconnection.value = [];
          hwyTrafficSignalPreemption.value = [];
          Object.keys(PartIIIDetails.value?.gradeCrossingPartThreeDTO).forEach(
            (key) => {
              if (
                typeof PartIIIDetails.value?.gradeCrossingPartThreeDTO[key] ===
                "number"
              ) {
                PartIIIDetails.value.gradeCrossingPartThreeDTO[key] =
                  PartIIIDetails.value.gradeCrossingPartThreeDTO[
                    key
                  ]?.toString();
              }
            }
          );
          LED.value = [];
          PartIIIDetails.value.gradeCrossingPartThreeDTO = {
            ...PartIIIDetails.value.gradeCrossingPartThreeDTO,
            otherMUTCDSignsDetails: { specifyType: "", count: "" },
            mastMountedFlashingLightsCountType:
              PartIIIDetails.value?.gradeCrossingPartThreeDTO
                ?.mastMountedFlashingLightsCount == 0
                ? "None"
                : PartIIIDetails.value?.gradeCrossingPartThreeDTO
                    .mastMountedFlashingLightsCountType,
            reasonForUpdate:
              PartIIIDetails.value?.gradeCrossingPartThreeDTO?.reasonForUpdate,
            waysideHornInstallationDate: response?.data
              ?.gradeCrossingPartThreeDTO?.waysideHornInstallationDate
              ? response?.data?.gradeCrossingPartThreeDTO?.waysideHornInstallationDate?.split(
                  "-"
                )[0] +
                "-" +
                response?.data?.gradeCrossingPartThreeDTO?.waysideHornInstallationDate?.split(
                  "-"
                )[1]
              : "",
            installationDateOfCurrentActiveWarningDevices: response?.data
              ?.gradeCrossingPartThreeDTO
              ?.installationDateOfCurrentActiveWarningDevices
              ? response?.data?.gradeCrossingPartThreeDTO?.installationDateOfCurrentActiveWarningDevices?.split(
                  "-"
                )[0] +
                "-" +
                response?.data?.gradeCrossingPartThreeDTO?.installationDateOfCurrentActiveWarningDevices?.split(
                  "-"
                )[1]
              : "",
          };
          PartIIILookupData.value = response?.data?.lookupData;

          let advanceWarningSignsValues = new Array();
          advanceWarningSignsValues.push(
            response.data.gradeCrossingPartThreeDTO.advw101Value
          );
          advanceWarningSignsValues.push(
            response.data.gradeCrossingPartThreeDTO.advw102Value
          );
          advanceWarningSignsValues.push(
            response.data.gradeCrossingPartThreeDTO.advw103Value
          );
          advanceWarningSignsValues.push(
            response.data.gradeCrossingPartThreeDTO.advw104Value
          );
          advanceWarningSignsValues.push(
            response.data.gradeCrossingPartThreeDTO.advw1011Value
          );
          advanceWarningSignsValues.push(
            response.data.gradeCrossingPartThreeDTO.advw1012Value
          );

          response?.data?.lookupData?.advanceWarningSigns?.forEach(
            (selectedSign, index) => {
              let temp = advanceWarningSignsValues[index];
              if (temp != null) {
                AdvanceWarningSigns.value.push({
                  label: selectedSign,
                  enabled: true,
                  value: temp,
                });
              } else {
                AdvanceWarningSigns.value.push({
                  label: selectedSign,
                  enabled: false,
                  value: 0,
                });
              }
            }
          );

          response?.data?.lookupData?.hwyMonitoringDevices.forEach(
            (preemption) => {
              MonitoringDevice.value.push({
                label: preemption,
                enabled: false,
                value: 0,
              });
            }
          );
          initialLoad.value = true;
          originalValue.value = JSON.parse(
            JSON.stringify({ ...PartIIIDetails.value })
          );
          originalAdvanceWarningSignsValue.value = JSON.parse(
            JSON.stringify({ ...AdvanceWarningSigns.value })
          );

          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
        })
        .catch((err) => {});
    }
    const dotId = computed({
      get: () => {
        return props?.dotId;
      },
    });
    const saveClicked = () => {
      if (
        PartIIIDetails.value.gradeCrossingPartThreeDTO?.waysideHorn === "No" &&
        !PartIIIDetails.value.gradeCrossingPartThreeDTO
          .waysideHornInstallationDate
      ) {
        PartIIIDetails.value.gradeCrossingPartThreeDTO.waysideHornInstallationDate =
          null;
      }
      PartIIIDetails.value.gradeCrossingPartThreeDTO.userID =
        userData.value?.userID;
      let advanceWarningSignsMap = new Map();
      advanceWarningSignsMap.set("W10-1", "advw101");
      advanceWarningSignsMap.set("W10-2", "advw102");
      advanceWarningSignsMap.set("W10-3", "advw103");
      advanceWarningSignsMap.set("W10-4", "advw104");
      advanceWarningSignsMap.set("W10-11", "advw1011");
      advanceWarningSignsMap.set("W10-12", "advw1012");
      AdvanceWarningSigns.value.forEach((sign) => {
        if (sign.enabled) {
          PartIIIDetails.value.gradeCrossingPartThreeDTO[
            advanceWarningSignsMap.get(sign.label)
          ] = sign.label;
          let val = advanceWarningSignsMap.get(sign.label) + "Value";
          PartIIIDetails.value.gradeCrossingPartThreeDTO[val] = sign.value;
        } else {
          PartIIIDetails.value.gradeCrossingPartThreeDTO[
            advanceWarningSignsMap.get(sign.label)
          ] = null;
          let val = advanceWarningSignsMap.get(sign.label) + "Value";
          PartIIIDetails.value.gradeCrossingPartThreeDTO[val] = null;
        }
      });
      axios
        .put(
          CONFIG_ENDPOINT.PARTIII + dotId.value,
          PartIIIDetails.value.gradeCrossingPartThreeDTO
        )
        .then(() => {
          isInitialised.value = false;
          valueChanged.value = false;
          context.emit("showBanner", "Saved Successfully.");
          getPartDetails();
        })
        .catch((err) => {
          context.emit("showBanner", err.response.data.businessMessage);
        });
    };
    const cancelClicked = () => {
      getPartDetails();
      isInitialised.value = false;
      valueChanged.value = false;
      context.emit("ValueChanged", valueChanged.value);
      getPartDetails();
    };
    const valueChange = (value) => {
      PartIIIDetails.value.gradeCrossingPartThreeDTO.reasonForUpdate = value;
    };

    watch(
      () => [PartIIIDetails.value, AdvanceWarningSigns.value],
      () => {
        if (initialLoad.value) {
          initialLoad.value = false;
        } else {
          updatedDataFlagger.value = true;
        }
        if (
          isInitialised.value &&
          (originalValue.value.gradeCrossingPartThreeDTO !=
            PartIIIDetails.value.gradeCrossingPartThreeDTO ||
            AdvanceWarningSigns.value != originalAdvanceWarningSignsValue.value)
        ) {
          valueChanged.value = true;
          console.log("inwatch");
          console.log("valueChanged", valueChanged.value);
          console.log("isFormValid", isFormValid.value);
        } else {
          valueChanged.value = false;
          context.emit("ValueChanged", valueChanged.value);
        }
      },
      { deep: true }
    );
    const arraysEqual = (a, b) => {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a?.length !== b?.length) return false;

      for (let i = 0; i < a.length; ++i) {
        if (!objectsAreSame(a[i], b[i])) return false;
      }
      return true;
    };
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      for (let propertyName in x) {
        if (x[propertyName] !== y[propertyName]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    const checkboxUpdated = (sign) => {
      if (!sign.enabled) {
        partIIIForm.value.resetValidation();
        let index = AdvanceWarningSigns.value.findIndex(
          (warning) => warning.label === sign.label
        );
        if (index >= 0) {
          AdvanceWarningSigns.value[index].value = 0;
        }
      } else {
        partIIIForm.value.validate();
      }
      updateAdvanceWarning(sign);
    };
    const updateAdvanceWarning = (sign) => {
      if (sign.enabled && sign.label === "None") {
        let index = AdvanceWarningSigns.value.findIndex(
          (warning) => warning.enabled && warning.label === "None"
        );
        if (index >= 0) {
          AdvanceWarningSigns.value.forEach((warning, warningIndex) => {
            if (index != warningIndex) {
              AdvanceWarningSigns.value[warningIndex] = {
                ...warning,
                enabled: false,
                value: 0,
              };
            }
          });
        }
      } else if (sign.label != "None") {
        let index = AdvanceWarningSigns.value.findIndex(
          (warning) => warning.label === "None"
        );
        if (index >= 0 && AdvanceWarningSigns.value[index].enabled) {
          AdvanceWarningSigns.value[index].enabled = false;
        }
      }
    };
    const checkNoneSelected = (event, property) => {
      if (event[event.length - 1] === "None") {
        PartIIIDetails.value.gradeCrossingPartThreeDTO[property] = ["None"];
      } else {
        let index = PartIIIDetails.value.gradeCrossingPartThreeDTO[
          property
        ].findIndex((value) => value === "None");
        if (index >= 0) {
          PartIIIDetails.value.gradeCrossingPartThreeDTO[property].splice(
            index,
            1
          );
        }
      }
    };
    function keydownAllowNumberOnly(event) {
      if (isLeftUpRightDownBackspaceDeleteTab(event)) {
        return;
      }

      let regex = /\d/;

      if (!regex.test(event.key)) {
        event.preventDefault();
        return false;
      }
    }
    function isLeftUpRightDownBackspaceDeleteTab(event) {
      let key = event.key || event.code;
      return (
        key === "ArrowLeft" ||
        key === 37 ||
        key === "ArrowUp" ||
        key === 38 ||
        key === "ArrowRight" ||
        key === 39 ||
        key === "ArrowDown" ||
        key === 40 ||
        key === "Backspace" ||
        key === 8 ||
        key === "Delete" ||
        key === 46 ||
        key === "Tab" ||
        key === 9
      );
    }
    const InstallationDateChange = (event) => {
      if (event?.target?.value) {
        updateInstallationDateRequired(event.target.value);
      } else if (event) {
        updateInstallationDateRequired(event);
      }
    };
    const waysideHornDateChange = (event) => {
      if (event?.target?.value) {
        PartIIIDetails.value.gradeCrossingPartThreeDTO.waysideHornInstallationDate =
          event.target.value;
      } else {
        PartIIIDetails.value.gradeCrossingPartThreeDTO.waysideHornInstallationDate =
          null;
      }
    };
    const IsValidationInstallationDate = ref(false);
    const IsWaysideHornDateValid = ref(false);
    const checkValidationInstallationDate = (value) => {
      IsValidationInstallationDate.value = value;
    };
    const checkIsWaysideHornDateValid = (value) => {
      IsWaysideHornDateValid.value = value;
    };
    const updateInstallationDateRequired = (selectedDate) => {
      if (selectedDate) {
        PartIIIDetails.value.gradeCrossingPartThreeDTO.installationDateOfCurrentActiveWarningDevices =
          selectedDate;

        PartIIIDetails.value.gradeCrossingPartThreeDTO.installationDateRequired = false;
      } else {
        PartIIIDetails.value.gradeCrossingPartThreeDTO.installationDateOfCurrentActiveWarningDevices =
          null;

        PartIIIDetails.value.gradeCrossingPartThreeDTO.installationDateRequired = true;
      }
    };
    const checkFormValidation = computed({
      get: () => {
        let returnValue = isFormValid.value;
        let conditionReturn = true;
        if (
          !PartIIIDetails.value.gradeCrossingPartThreeDTO
            .installationDateRequired
        ) {
          conditionReturn = IsValidationInstallationDate.value;
        }
        if (
          PartIIIDetails.value.gradeCrossingPartThreeDTO?.waysideHorn === "Yes"
        ) {
          conditionReturn =
            conditionReturn &&
            PartIIIDetails.value.gradeCrossingPartThreeDTO
              .waysideHornInstallationDate &&
            IsWaysideHornDateValid.value;
          console.log("conditionReturn from condition", conditionReturn);
        }
        console.log("conditionReturn", conditionReturn);
        return conditionReturn && returnValue;
      },
    });
    return {
      checkFormValidation,
      IsValidationInstallationDate,
      checkIsWaysideHornDateValid,
      checkValidationInstallationDate,
      waysideHornDateChange,
      InstallationDateChange,
      dotId,
      PartIIIDetails,
      isEdit,
      reasonToUpdateList,
      requiredRule,
      numericRule,
      hwyTrafficSignalPreemption,
      AdvanceWarningSigns,
      lowGroundClearance,
      pavementMarkings,
      yesNoDropdown,
      signsOrSignals,
      GChannelization,
      GateConfiguration,
      GateConfiguration2,
      IncandescantOrLed,
      trainActiveWarning,
      MonitoringDevice,
      arraysEqual,
      SignalInterconnection,
      saveClicked,
      isFormValid,
      allowAlphanumericOnly,
      cancelClicked,
      ReasonForUpdate,
      valueChange,
      updatedDataFlagger,
      initialLoad,
      reasonForUpdateRequiredRule,
      checkboxUpdated,
      keydownAllowNumberOnly,
      updateAdvanceWarning,
      PartIIILookupData,
      checkNoneSelected,
      isLeftUpRightDownBackspaceDeleteTab,
      minimumRule,
      partIIIForm,
      arrayRequiredRule,
      DROPDOWN_NO_DATA,
      valueChanged,
      isUserGradeCrossingAccess,
    };
  },
};
