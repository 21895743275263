<template>
  <v-card-text>
    <ConditionalRailroadCertifications
      @refreshMpmsData="$emit('refreshMpmsData')"
    ></ConditionalRailroadCertifications>
  </v-card-text>
</template>
  <script>
import ConditionalRailroadCertifications from "@/modules/manageproject/components/ConditionalRailroadCertifications.vue";

export default {
  name: "ViewConditionalRailroadCertification-page",
  components: {
    ConditionalRailroadCertifications: ConditionalRailroadCertifications,
  },
};
</script>
  