<template>
  <v-card-text>
    <projectInvolvement
      @BannerUpdate="$emit('BannerUpdate', $event)"
    ></projectInvolvement>
  </v-card-text>
</template>
<script>
import projectInvolvement from "@/modules/manageproject/components/projectInvolvement.vue";

export default {
  name: "ViewProjectInvolvement-page",
  components: {
    projectInvolvement: projectInvolvement,
  },
};
</script>
