import useSharedFunctions from "@/composables/shared";import ContactInfo from "@/modules/gcmsmain/admin/submenu/components/ContactList.vue";
import RailroadCompanyInformationForm from "@/modules/gcmsmain/admin/submenu/components/RailroadCompanyInformationForm.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "AddUpdateRailroadCompany",
  components: {
    RailroadCompanyInformationForm,
    BannerMessage,
    ContactInfo,
    OnlineHelpPOC,
  },
  setup() {
    const { toTitleCase } = useSharedFunctions();
    const route = useRoute();
    const addOrEdit = route.params.action;
    const railroadId = route.params.id;
    const tab = ref("RailroadInformation");
    const SuccessBannerData = ref(null);
    const ErrorBannerData = ref(null);
    const savedButtonClicked = ref(false);

    const BannerUpdate = (event) => {
      if (event?.bannerType === "success") {
        SuccessBannerData.value = event;
      } else if (event?.bannerType === "error") {
        ErrorBannerData.value = event;
      }
    };
    const hideSuccessBanner = () => {
      SuccessBannerData.value = null;
    };

    const hideErrorBannerMessage = () => {
      ErrorBannerData.value = null;
    };

    const saveClicked = (clicked) => {
      savedButtonClicked.value = clicked;
    };
    return {
      addOrEdit,
      railroadId,
      tab,
      toTitleCase,
      BannerUpdate,
      SuccessBannerData,
      hideSuccessBanner,
      hideErrorBannerMessage,
      ErrorBannerData,
      saveClicked,
      savedButtonClicked,
      ContactInfo,
    };
  },
};
