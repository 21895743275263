<template>
  <v-card-text>
    <EditRailroadEstimates
      @refreshMpmsData="$emit('refreshMpmsData')"
    ></EditRailroadEstimates>
  </v-card-text>
</template>
  <script>
import EditRailroadEstimates from "@/modules/manageproject/components/EditRailroadEstimates.vue";

export default {
  name: "ViewEditRailroadEstimates-page",
  components: {
    EditRailroadEstimates: EditRailroadEstimates,
  },
};
</script>
  