<template>
  <div>
    <div class="select-btn">
      <PrimaryButton
        :buttonText="'Select files to upload'"
        @buttonClicked="openFileInput"
      ></PrimaryButton>
    </div>
    <div
      class="file-upload-container"
      @drop.prevent="handleDrop"
      @dragover.prevent
    >
      <div v-if="dragging" class="drop-indicator">
        <span>Drop Them Here</span> <span class="smaller">to add them</span>
      </div>
      <v-file-input
        multiple
        ref="fileInput"
        v-model="uploadedFile"
        id="fileInput"
        @update:modelValue="handleFileInput"
        accept=".xlsx, .pdf, .jpg, .png, .csv, .jpeg"
        style="visibility: hidden; height: 100%; width: 100%"
      >
      </v-file-input>
      <div
        class="drag-drop-img d-flex justify-content-center align-items-center"
      >
        <img alt="dragDrop" src="../../shared/images/DragDropImage.png" />
      </div>
    </div>
    <div v-if="invalidFiles" class="invalid-files">
      <div class="w-80 d-flex align-items-center justify-content-center">
        <div class="close-icon mr-20 ml-20">
          <v-icon small>mdi-close </v-icon>
        </div>
        <span class="color-error">
          The file you wanted to upload has an incorrect file type. The
          allowable types are pdf, xlsx, jpg, png, csv
          <span v-for="(file, index) in incorrectFiles" :key="file"
            >{{ file.name }} {{ file.size
            }}<span v-if="index + 1 != incorrectFiles.length">, </span>
          </span>
        </span>
      </div>
      <div class="color-blue mr-20 ml-20">
        <v-icon @click="removeError()" small>mdi-close </v-icon>
      </div>
    </div>
    <div
      v-if="checkMaximumFilesUploaded && maximumFilesUploaded"
      class="invalid-files"
    >
      <div class="w-100 d-flex align-items-center justify-content-center">
        <div class="close-icon mr-20 ml-20">
          <v-icon small>mdi-close </v-icon>
        </div>
        <span class="color-error">
          Only one document can be uploaded as a new version
        </span>
        <div class="color-blue mr-20 ml-20">
          <v-icon @click="removeMaxError()" small>mdi-close </v-icon>
        </div>
      </div>
    </div>
    <div v-if="checkMaxFileSize && showMaxFileError" class="invalid-files">
      <div class="w-100 d-flex align-items-center justify-content-center">
        <div class="close-icon mr-20 ml-20">
          <v-icon small>mdi-close </v-icon>
        </div>
        <span class="color-error"> Maximum File Limit (500MB) Exceeded. </span>
        <div class="color-blue mr-20 ml-20">
          <v-icon @click="removeMaxFileSizeError()" small>mdi-close </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropZone from "@/modules/shared/scripts/DropZone.js";
export default DropZone;
</script>

<style >
@import "@/modules/shared/assets/dropZone.scss";
@import "@/assets/css/style.scss";
</style>