import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  name: "StatusChangeInfoChangeDialog",
  components: { PrimaryButton, SecondaryButton },
  setup() {
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      id: null,
      status: null,
    });
    const lookupData = ref([]);
    const router = useRouter();
    const infoMessage = ref("");
    const open = (id, status) => {
      state.dialog = true;
      state.id = id;
      state.status = status;
      setNextStatus(status);
      getLookupList();
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const nextStatus = ref("");
    const setNextStatus = (status) => {
      let nextsts;
      switch (status) {
        case "Authorization": {
          nextsts = "Coordination";
          break;
        }
        case "Coordination": {
          nextsts = "Contract Development";
          break;
        }
        case "Contract Development": {
          nextsts = "Construction";
          break;
        }
        case "Scoping": {
          nextsts = "Authorization";
          break;
        }
        default: {
          nextsts = "";
        }
      }
      nextStatus.value = nextsts;
    };
    const getLookupList = () => {
      axios
        .get(CONFIG_ENDPOINT.GET_STATUS_CHANGE_INFO + state.id)
        .then((res) => {
          console.log("Res", res.data);
          lookupData.value = res.data?.data || [];
          lookupData.value = lookupData.value.map((item) => {
            return {
              ...item,
              actionLink: item?.actionLink
                ? item.actionLink.replace("{mpmsNumber}", state.id)
                : null,
            };
          });
        })
        .catch((err) => {});
      lookupData.value = [];
      lookupData.value = lookupData.value.map((item) => {
        return {
          ...item,
          actionLink: item?.actionLink?.replace("{mpmsNumber}", state.id),
        };
      });
    };
    const closeDialog = () => {
      if (state.resolve) {
        state.resolve(false);
      }
      state.dialog = false;
    };
    const navigateAndClose = (link) => {
      if (!link) return;
      closeDialog();
      router.push(link);
    };
    return {
      ...toRefs(state),
      closeDialog,
      navigateAndClose,
      open,
      lookupData,
      nextStatus,
      infoMessage,
    };
  },
};
