import { computed, ref } from "vue";export default {
  name: "CurrencyInput",
  props: {
    modelValue: { default: "" },
  },
  setup(props, context) {
    const isInputActive = ref(false);

    const computedValue = computed({
      get: () => {
        if (!props.modelValue) {
          return "";
        }
        if (isInputActive.value) {
          if (typeof props.modelValue === "string") {
            return parseFloat(props.modelValue.replace(/[^\d.]/g, "")) || 0;
          } else {
            return props.modelValue;
          }
        } else {
          if (typeof props.modelValue === "string") {
            if (props.modelValue.startsWith("$")) {
              return props.modelValue;
            }
            return "$ " + formatNumber(props.modelValue);
          }
          return "$ " + formatNumber(props.modelValue);
        }
      },
      set: (modifiedValue) => {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, "")) || 0;
        context.emit("update:modelValue", newValue);
      },
    });
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",

      minimumFractionDigits: 2,

      maximumFractionDigits: 2,
    });

    function formatNumber(value) {
      if (isNaN(value) || value === null || value === undefined) return "0.00";

      return formatter.format(parseFloat(value));
    }

    return {
      isInputActive,
      computedValue,
    };
  },
};
