import DialogComponent from "@/modules/projectinformation/components/ProjectPriorityDocumentUpdate.vue";import UploadScopingFieldViewDocument from "@/modules/scoping-field-view/components/UploadScopingFieldViewDocument.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import { useDocumentStore } from "@/stores/document";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  name: "ViewUploadSafetyMilestoneDocuments",
  components: {
    DocumentsList,
    UploadScopingFieldViewDocument,
    LoadingOverlay,
    DialogComponent,
  },
  emits: ["uploadFilesCall", "updateRefreshFlag"],
  setup(props, { emit }) {
    const ralroadEstimatesDocumentsDialogRef = ref(null);
    const route = useRoute();
    const documentStore = useDocumentStore();
    const inventorylist = ref([]);
    const loading = ref(false);
    const updateDocumentList = ref(false);
    const lookupData = ref(null);
    let UpdateModalOpened = ref(false);
    onMounted(() => {
      fetchData();
    });
    function fetchData() {
      updateDocumentList.value = true;
      emit("updateRefreshFlag", true);
    }
    const mpmsId = computed({
      get: () => {
        return route.params.id;
      },
    });
    const uploadFiles = async (files) => {
      loading.value = true;
      let id = route.params.id;
      let docType = "SFT_DOCTYP";
      let endpoint = CONFIG_ENDPOINT.UPLOAD_SCOPING_DOCUMENTS;
      document.getElementById("list-section").scrollIntoView();
      await documentStore.uploadDocuments(
        id,
        files,
        docType,
        endpoint,
        true,
        true,
        true
      );
      loading.value = false;
      emit("updateRefreshFlag", true);
      fetchData();
    };
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };
    const updateButtonClicked = (event) => {
      openDialog(event);
    };
    const openDialog = (event) => {
      UpdateModalOpened.value = true;
      ralroadEstimatesDocumentsDialogRef.value
        .open("Update Documents", mpmsId.value, event)
        .then((resolve) => {
          if (resolve) {
            fetchData();
            UpdateModalOpened.value = false;
            emit("updateRefreshFlag", true);
          }
        })
        .catch(() => {
          UpdateModalOpened.value = false;
        });
    };
    return {
      updateDocumentListFlag,
      updateDocumentList,
      inventorylist,
      loading,
      lookupData,
      fetchData,
      uploadFiles,
      mpmsId,
      UpdateModalOpened,
      updateButtonClicked,
      ralroadEstimatesDocumentsDialogRef,
    };
  },
};
