import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent from "@/modules/manageproject/components/UploadRailroadProjectPlansDialog.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import CurrencyInput from "@/modules/shared/components/CurrencyInput.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";

import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";

export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    BannerMessage,
    CurrencyInput,
    DialogComponent,
    DocumentsList,
    LoadingOverlay,
  },
  emits: ["BannerUpdate", "refreshMpmsData"],
  name: "RailroadPlanReview",
  props: ["dotId"],
  setup(props, { emit }) {
    const route = useRoute();
    const id = route.params.id;
    const selectedRailroad = ref([]);
    const projectPlanSendData = ref();


    const uploadRailroadProjectPlanDialogRef = ref(null);
    const loading = ref(false);

    const isUserRailroadPlanAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();

    const railroadPlanCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROAD_PLAN_REVIEW_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROAD_PLAN_REVIEW_PAGE_CRTUPD_ACCESS
      );
      isUserRailroadPlanAccess.value.read = readResponse || false;
      isUserRailroadPlanAccess.value.update = updateResponse || false;
      loading.value = false;
    };

    onMounted(async () => {
      await railroadPlanCheck();
      if (isUserRailroadPlanAccess.value.read) {
        fetchProjectPlanSendData();
        fetchRailroad();
      }
    });
    const fetchProjectPlanSendData = () => {
      axios
        .get(CONFIG_ENDPOINT.GET_PROJECT_PLANS_SEND_LIST + id)
        .then((response) => {
          projectPlanSendData.value = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    function fetchRailroad() {
      axios
        .get(CONFIG_ENDPOINT.GET_RAILROAD_PLAN_RAILROAD_DROPDOWN_LIST + id)
        .then((response) => {
          selectedRailroad.value = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    /* Estimat Documents */
    const filesList = ref([]);
    const uploadButtonClicked = (isProjectPlan) => {
      openDialog(isProjectPlan, null, false);
    };
    const updateButtonClicked = (event, isProjectPlan) => {
      console.log("event", event);
      openDialog(isProjectPlan, event, true);
    };
    const formData = ref([]);
    const updateDocumentList = ref(false);
    const updateRailroadResourceDocumentList = ref(false);
    const openDialog = (isProjectPlan, updateEvent, isUpdate) => {
      formData.value = [];
      uploadRailroadProjectPlanDialogRef.value
        .open(
          isProjectPlan
            ? "Upload Project Plan Documents"
            : "Upload Railroad Plan Review Documents",
          id,
          selectedRailroad.value,
          isProjectPlan ? "projectPlan" : "railroadResponse",
          updateEvent,
          isUpdate
        )
        .then((resolve) => {
          if (resolve) {
            // refresh data of required
            if (isProjectPlan) {
              fetchProjectPlanFiles();
            } else {
              fetchRailroadResourceFiles();
            }
          }
        });
    };
    async function fetchProjectPlanFiles() {
      updateDocumentList.value = true;
      fetchProjectPlanSendData();
    }
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };

    async function fetchRailroadResourceFiles() {
      updateRailroadResourceDocumentList.value = true;
    }
    const updateRailroadResourceFlag = () => {
      updateRailroadResourceDocumentList.value = false;
    };

    const sendClicked = (type) => {
      loading.value = true;
      axios
        .get(CONFIG_ENDPOINT.SEND_PROJECT_PLAN_RAILROAD + id + "/" + type)
        .then((response) => {
          fetchProjectPlanSendData();
          emit("BannerUpdate", {
            bannerType: "success",
            bannerMessage: "Sent Successfully.",
            showBanner: true,
          });
          emit("refreshMpmsData", true);
          window.scrollTo(0, 0);
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
          emit("BannerUpdate", {
            bannerType: "error",
            bannerMessage: "Something Went Wrong",
            showBanner: true,
            hasList: false,
            errorList: [],
          });
          window.scrollTo(0, 0);
          console.log(err);
        });
    };

    return {
      id,
      uploadButtonClicked,
      uploadRailroadProjectPlanDialogRef,
      filesList,
      updateDocumentListFlag,
      updateDocumentList,
      fetchRailroadResourceFiles,
      updateRailroadResourceFlag,
      updateRailroadResourceDocumentList,
      projectPlanSendData,
      sendClicked,
      loading,
      updateButtonClicked,
      isUserRailroadPlanAccess,
    };
  },
};
