import { requiredRule } from "@/composables/validationRules";import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useProjectTeamStore } from "@/stores/projectTeam";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  components: { PrimaryButton, SecondaryButton },
  name: "ProjectTeam",
  props: ["dotId"],
  setup(props, { emit }) {
    const projectTeamStore = useProjectTeamStore();
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const route = useRoute();
    const id = route.params.id;
    const isFormValid = ref(false);
    const formChanged = ref(false);
    const apiData = ref({
      projectManager: [],
      projectDesigner: [],
      pucApplicant: [],
      gradeCrossingCoordinator: [],
      districtAgreementCoordinator: [],
      centralOfficeCoordinator: [],
      constructionCoordinator: [],
      closeoutCoordinator: [],
    });
    const teamListCode = ref([
      "projectManager",
      "projectDesigner",
      "pucApplicant",
      "gradeCrossingCoordinator",
      "districtAgreementCoordinator",
      "centralOfficeCoordinator",
      "constructionCoordinator",
      "closeoutCoordinator",
    ]);
    const teamList = ref([
      "Project Manager",
      "Project Designer",
      "PUC Application Coordinator",
      "Grade Crossing Coordinator",
      "District Agreement Coordinator",
      "Central Office Coordinator",
      "Construction Coordinator",
      "Closeout Coordinator",
    ]);
    const projectTeamList = ref([]);
    const selectedProject = ref("");
    const showBannerMessage = ref(false);
    const saveClicked = ref(true);
    const isUserAccess = ref({
      read: false,
      update: false,
    });
    const accessCheck = async () => {
      const readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PENNDOT_PROJECTTEAM_PAGE_READ_ACCESS
      );
      const updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PENNDOT_PROJECTTEAM_PAGE_CRTUPD_ACCESS
      );
      isUserAccess.value.read = readResponse || false;
      isUserAccess.value.update = updateResponse || false;
    };
    onMounted(async () => {
      await accessCheck();
      fetchData();
      teamListCode.value.forEach(async (code) => {
        await projectTeamStore.getLookup(code, id);
        let response = projectTeamStore.LookupData[code];
        apiData.value[code] = response;
      });
    });
    const getNullOrData = (projectIndex, response, key) => {
      return projectIndex >= 0 ? response.data[projectIndex]?.[key] : null;
    };

    async function fetchDataHelper(response) {
      teamList.value.forEach((team) => {
        let projectIndex = response.data.findIndex(
          (project) => project.projectTeamMemberRole === team
        );
        projectTeamList.value.push({
          mpmsNumber: Number(id),
          projectTeamMemberRole: team,
          userID: getNullOrData(projectIndex, response, "userID"),
          fullName: getNullOrData(projectIndex, response, "fullName"),
          firstName: getNullOrData(projectIndex, response, "firstName"),
          lastName: getNullOrData(projectIndex, response, "lastName"),
          emailAddress: getNullOrData(projectIndex, response, "emailAddress"),
          roles: getNullOrData(projectIndex, response, "roles"),
          displayName: getNullOrData(projectIndex, response, "displayName"),
        });
      });
    }

    const fetchData = () => {
      formChanged.value = false;
      projectTeamList.value = [];
      axios
        .get(CONFIG_ENDPOINT.GET_PROJECT_TEAM_DETAILS + id)
        .then(async (response) => {
          await fetchDataHelper(response);
        })
        .catch(() => {
          teamList.value.forEach((team) => {
            projectTeamList.value.push({
              mpmsNumber: Number(id),
              projectTeamMemberRole: team,
              userID: null,
              fullName: null,
              firstName: null,
              lastName: null,
              emailAddress: null,
              roles: null,
              displayName: null,
            });
          });
        });
    };
    const fetchProjectLookupData = async (project) => {
      if (!isUserAccess.value.update) return; // Prevent fetching lookup data if no update access
      selectedProject.value = project;
      let response = projectTeamStore.LookupData[project];
      if (!response?.length) {
        await projectTeamStore.getLookup(project);
        response = projectTeamStore.LookupData[project];
      }
      apiData.value[project] = response;
    };
    const inputFocus = (project) => {
      if (!isUserAccess.value.update) return; // Prevent interaction if no update access
      if (selectedProject.value != project) {
        fetchProjectLookupData(project);
      }
    };
    const saveChanges = () => {
      if (!isUserAccess.value.update) return; // Prevent saving if no update access
      saveClicked.value = true;
      let payload = projectTeamList.value.filter(
        (project) => project.displayName
      );
      axios
        .post(CONFIG_ENDPOINT.UPDATE_PROJECT_TEAM_DETAILS + id, payload)
        .then(() => {
          fetchData();
          showBannerMessage.value = true;
          emit("BannerUpdate", {
            bannerType: "success",
            bannerMessage: "Saved Successfully.",
            showBanner: showBannerMessage.value,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const updateDisplayName = (value, index) => {
      if (!isUserAccess.value.update) return; // Prevent updates if no update access
      saveClicked.value = false;
      let lookupData = apiData.value[teamListCode.value[index]];
      let selectedIndex = lookupData.findIndex(
        (user) => user.displayName === value
      );
      projectTeamList.value[index] = {
        ...projectTeamList.value[index],
        displayName: lookupData[selectedIndex]?.displayName || null,
        userID: lookupData[selectedIndex]?.userID || null,
        emailAddress: lookupData[selectedIndex]?.emailAddress || null,
        firstName: lookupData[selectedIndex]?.firstName || null,
        fullName: lookupData[selectedIndex]?.fullName || null,
        lastName: lookupData[selectedIndex]?.lastName || null,
        roles: lookupData[selectedIndex]?.roles || null,
      };
      formChanged.value = true;
    };
    onBeforeRouteLeave((to, from, next) => {
      if (!saveClicked.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    });
    window.onbeforeunload = function () {
      if (!saveClicked.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    return {
      apiData,
      teamList,
      inputFocus,
      projectTeamList,
      teamListCode,
      selectedProject,
      saveChanges,
      fetchData,
      updateDisplayName,
      requiredRule,
      isFormValid,
      id,
      formChanged,
      isUserAccess,
    };
  },
};
