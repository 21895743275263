<template>
  <v-card-text>
    <ContractorBid @refreshMpmsData="$emit('refreshMpmsData')"></ContractorBid>
  </v-card-text>
</template>
  <script>
import ContractorBid from "@/modules/manageproject/components/ContractorBid.vue";

export default {
  name: "ViewContractorBid-page",
  components: {
    ContractorBid: ContractorBid,
  },
};
</script>
  