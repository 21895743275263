import useDateField from "@/composables/dateField.js";import useFieldEntry from "@/composables/fieldEntry";
import useMapFunctions from "@/composables/gisMap";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import CrossingDetailsTabView from "@/modules/dot-details/components/CrossingDetailsTabView.vue";
import GradeCrossingDocuments from "@/modules/dot-details/components/GradeCrossingDocuments.vue";
import GradeCrossingHistory from "@/modules/dot-details/components/GradeCrossingHistory.vue";
import GradeCrossingProjects from "@/modules/dot-details/components/GradeCrossingProjects.vue";
import Inspections from "@/modules/dot-details/components/Inspections.vue";
import PucDocuments from "@/modules/dot-details/components/PucDocuments.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useProjectServiceStore } from "@/stores/projectService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";

import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "GradeInventoryDetails",
  components: {
    OnlineHelpPOC,
    CrossingDetailsTabView,
    GradeCrossingProjects,
    GradeCrossingHistory,
    GradeCrossingDocuments,
    PucDocuments,
    Inspections,
    PrimaryButton,
    SecondaryButton,
    BannerMessage,
  },

  props: ["dotId"],
  setup() {
    const { keydownAllowNumberOnly } = useFieldEntry();

    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const stateRoute = ref(null);
    const originalStateRoute = ref(0);

    const projectServiceStore = useProjectServiceStore();
    const { navigateToGISMap } = useMapFunctions();
    const route = useRoute();
    const dotId = route.params.id;
    const tab = ref("crossingDetails");
    const crossingDetailsData = ref(null);
    const ownerOperatorDetails = ref(null);
    const railroadList = ref([]);
    const saveEnabled = ref(false);
    const railroadNames = ref([]);
    const intiliazeWatch = ref(0);
    const railroadMap = ref({});
    const owner = ref({
      pndtOperatorName: null,
      pndtOwnerName: null,
    });
    const bannerMessage = ref("");
    let resetPUC = ref(false);
    const crossingDetails = [
      { label: "Effective Date:", key: "effectiveDate" },
      { label: "County:", key: "county" },
      { label: "Municipality:", key: "municipality" },
      { label: "State Route:", key: "stateRoute" },
      { label: "Street or Road Name:", key: "streetOrRoadName" },
      { label: "Crossing Status:", key: "crossingStatus" },
      { label: "Segment:", key: "segment" },
      { label: "Offset:", key: "offset" },
    ];
    const isUserDOTAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const DOTaccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_CRTUPD_ACCESS
      );
      isUserDOTAccess.value.read = readResponse || false;
      isUserDOTAccess.value.update = updateResponse || false;
    };
    onMounted(async () => {
      await getDotDetails();
      await getOwnerOperatorDetails();
      await DOTaccessCheck();
      if (isUserDOTAccess.value.read) {
        retrieveActiveRailroadList();
      }
    });
    async function getDotDetails() {
      axios
        .get(`${CONFIG_ENDPOINT.DOT}${dotId}`)
        .then((response) => {
          crossingDetailsData.value = response?.data;
          originalStateRoute.value = crossingDetailsData.value.stateRoute;

          if (hasNumber(originalStateRoute.value)) {
            assignStateRoute();
          } else {
            stateRoute.value = "";
          }
        })
        .catch((err) => {
          crossingDetailsData.value = null;
        });
    }

    function hasNumber(str) {
      return /\d/.test(str);
    }

    function assignStateRoute() {
      if (parseInt(originalStateRoute.value).toString().length == 1) {
        stateRoute.value =
          "000" + parseInt(originalStateRoute.value).toString();
      } else if (parseInt(originalStateRoute.value).toString().length == 2) {
        stateRoute.value = "00" + parseInt(originalStateRoute.value).toString();
      } else if (parseInt(originalStateRoute.value).toString().length == 3) {
        stateRoute.value = "0" + parseInt(originalStateRoute.value).toString();
      } else {
        stateRoute.value = originalStateRoute.value;
      }
    }
    const originalOwnerDetails = ref(null);
    async function getOwnerOperatorDetails() {
      axios
        .get(CONFIG_ENDPOINT.DOT_OWNER_OPERATOR + dotId)
        .then((response) => {
          ownerOperatorDetails.value = response?.data;
          owner.value = ownerOperatorDetails.value;

          ownerOperatorDetails.value.pndtOperatorDate = getDate(
            ownerOperatorDetails.value.pndtOperatorDate
          );
          ownerOperatorDetails.value.fraOwnerDate = getDate(
            ownerOperatorDetails.value.fraOwnerDate
          );
          ownerOperatorDetails.value.fraOperatorDate = getDate(
            ownerOperatorDetails.value.fraOperatorDate
          );
          ownerOperatorDetails.value.pndtOwnerDate = getDate(
            ownerOperatorDetails.value.pndtOwnerDate
          );
          originalOwnerDetails.value = JSON.parse(
            JSON.stringify(ownerOperatorDetails.value)
          );
        })
        .catch((err) => {
          ownerOperatorDetails.value = null;
        });
    }

    async function retrieveActiveRailroadList() {
      let response = projectServiceStore.activeRailroadList;
      if (!response) {
        await projectServiceStore.retrieveActiveRailroadList();
        response = projectServiceStore.activeRailroadList;
      }

      railroadList.value = response?.data;
      for (let index in railroadList.value) {
        railroadNames.value.push(railroadList.value[index].name);
        railroadMap.value[railroadList.value[index].name] =
          railroadList.value[index].railroadCompanyId;
      }
    }
    async function postOwnerOperatorDetails() {
      axios
        .post(CONFIG_ENDPOINT.DOT_OWNER_OPERATOR + dotId, {
          pndtOwner: railroadMap.value[owner.value.pndtOwnerName] || null,
          pndtOperator: railroadMap.value[owner.value.pndtOperatorName] || null,
          stateRoute: stateRoute.value || "",
        })
        .then((response) => {
          getOwnerOperatorDetails();
          getDotDetails();
          showBannerMessage("Saved Successfully.");
          saveEnabled.value = false;
        })
        .catch((err) => {
          showBannerMessage(err.response.data.businessMessage);
        });
    }

    watch(
      () => [stateRoute, owner],
      () => {
        console.log(
          Number(crossingDetailsData.value?.["stateRoute"]),
          Number(stateRoute.value)
        );
        if (
          Number(crossingDetailsData.value?.["stateRoute"]) !=
            Number(stateRoute.value) ||
          owner.value?.pndtOwnerName !=
            originalOwnerDetails.value?.pndtOwnerName ||
          owner.value?.pndtOperatorName !=
            originalOwnerDetails.value?.pndtOperatorName
        ) {
          saveEnabled.value = true;
        } else {
          saveEnabled.value = false;
        }
      },
      { deep: true }
    );
    const updatedSaveEnabled = computed({
      get: () => {
        console.log("saveEnabled computed", saveEnabled.value);
        return saveEnabled.value;
      },
    });
    const showBanner = ref(false);
    const isSuccess = ref(true);

    const showBannerMessage = (message) => {
      if (message == "Saved Successfully.") {
        isSuccess.value = true;
      } else {
        isSuccess.value = false;
      }
      bannerMessage.value = message;
      showBanner.value = true;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    const hideBannerMessage = () => {
      showBanner.value = false;
      resetPUC.value = false;
    };

    async function cancelSave() {
      intiliazeWatch.value = 0;
      await getOwnerOperatorDetails();
      saveEnabled.value = false;
    }
    return {
      tab,
      crossingDetailsData,
      crossingDetails,
      dotId,
      showBannerMessage,
      showBanner,
      hideBannerMessage,
      resetPUC,
      navigateToGISMap,
      postOwnerOperatorDetails,
      ownerOperatorDetails,
      retrieveActiveRailroadList,
      railroadList,
      railroadNames,
      saveEnabled,
      intiliazeWatch,
      owner,
      cancelSave,
      isUserDOTAccess,
      isSuccess,
      bannerMessage,
      railroadMap,
      stateRoute,
      keydownAllowNumberOnly,
      originalStateRoute,
      updatedSaveEnabled,
    };
  },
};
