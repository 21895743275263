<template>
  <v-app>
    <v-main class="external-v-main">
      <GcmsMainPage
        external
        :mpmsNumber="mpmsNumber"
        :docType="docType"
        :railRoadID="railRoadID"
        :banner="banner"
        :headerMessage="headerMessage"
      />
    </v-main>
  </v-app>
</template>
<script>
import GcmsMainPage from "@/views/GcmsMain.vue";
export default {
  name: "ExternalLanding",
  components: { GcmsMainPage },
  setup() {
    const headerMessage = "External Header";
    const urlParams = new URLSearchParams(window.location.search);
    const mpmsNumber = urlParams.get("mpmsNumber");
    const docType = urlParams.get("docType");
    const railRoadID = urlParams.get("railRoadID");
    const banner = urlParams.get("banner");  
    return {
      mpmsNumber,
      docType,
      railRoadID,
      banner,
      headerMessage,
    };
  },
};
</script>