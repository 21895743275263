export const CONFIG_ENDPOINT = {  SLASH: "/",
  PARTI: "/gcms-gradecrossing-service/api/inventory-record/part-one/",
  PARTII: "/gcms-gradecrossing-service/api/inventory-record/part-two/",
  PARTIII: "/gcms-gradecrossing-service/api/inventory-record/part-three/",
  PARTIV: "/gcms-gradecrossing-service/api/inventory-record/part-four/",
  PARTV: "/gcms-gradecrossing-service/api/inventory-record/part-five/",
  PENNDOT:
    "/gcms-gradecrossing-service/api/grade-crossing-record/pennDot-crossing/",
  DOT: "/gcms-gradecrossing-service/api/grade-crossing-record/crossing-details/",
  GCMS_GRADE_CROSSING_PROJECTS:
    "/gcms-gradecrossing-service/api/grade-crossing-record/gradeprojects/12222",
  PUCDOCUMENTS: "/gcms-gradecrossing-service/api/puc-documents/",
  PUCLINKS: "/gcms-gradecrossing-service/api/puc-documents",
  INSPECTIONS: "/gcms-gradecrossing-service/api/inspections/",
  COMPLETED_CROSSINGS: "/gcms-gradecrossing-service/api/inspections/completed",
  COMPLETED_CROSSINGS_LOOKUP:
    "/gcms-gradecrossing-service/api/inspections/inspections-lookup-data",
  CROSSINGS_HISTORY:
    "/gcms-gradecrossing-service/api/grade-crossing-record/crossing-history/",
  CROSSINGS_HISTORY_LOOKUP:
    "/gcms-gradecrossing-service/api/grade-crossing-record/crossing-history-lookup-data/",

  SAFETY_MILESTONES_SCOPING:
    "/gcms-project-service/api/safety-milestones/scoping-milestones/",
  SAFETY_MILESTONES_SCOPING_POST:
    "/gcms-project-service/api/safety-milestones/",
  SAFETY_MILESTONES_AUTHORIZATION:
    "/gcms-project-service/api/safety-milestones/authorization-milestones/",
  SAFETY_MILESTONES_COORDINATION:
    "/gcms-project-service/api/safety-milestones/coordination-milestones/",
  SAFETY_MILESTONES_CONTRACT_DEVELOPMENT:
    "/gcms-project-service/api/safety-milestones/contract-development-milestones/",
  SAFETY_MILESTONES_CONSTRUCTION:
    "/gcms-project-service/api/safety-milestones/construction-milestones/",
  COSTANDFUNDING_ESTIMATED_PROJECT_FUNDING_INFO:
    "/gcms-funding-service/api/funding/estimated-project-funding-info/",
  COSTANDFUNDING_ESTIMATED_ESTIMATED_RAILROAD_COST:
    "/gcms-funding-service/api/funding/railroad-estimated-cost/",
  COSTANDFUNDING_ESTIMATED_PROGRAM_FUNDING_INFO:
    "/gcms-funding-service/api/funding/program-funding-info/",
  COSTANDFUNDING_ESTIMATED_SCOPING_RAILROAD_COST:
    "/gcms-funding-service/api/funding/scoping-railroad-cost/",
  COSTANDFUNDING_ESTIMATED_RAIL_ROADS:
    "/gcms-funding-service/api/funding/scoping-railroad-cost/rail-roads",
  COSTANDFUNDING_ESTIMATED_WBS_INFO:
    "/gcms-funding-service/api/funding/wbs-info/",
  COMPLETED_CROSSINGS_INSPECTIONS: "/GCMS/completed-crossings-inspections",
  Dashboard: "/GCMS",
  GCMS: "/GCMS/",
  GCMS_VIEW_ALL_PROJECTS: "/GCMS/ViewAllProjects",
  GCMS_VIEW_ALL_TASKS: "/GCMS/ViewAllTasks",
  GCMS_MAIN_ADMIN_MENU: "/GCMS/flaggerActivityAdmin",
  GCMS_MAIN_DISTRICT_OFFICE_LIST: "/GCMS/districtOfficeList",

  GCMS_MAIN_MPMS_PROJECT: "/GCMS/mpmsProjects",
  GCMS_MAIN_GRADE_INVENTORY: "/GCMS/gradeinventory",
  GCMS_MAIN_FLAGGER_ACTIVITY:
    "/gcms-gradecrossing-service/api/flagger-activity",
  GCMS_MAIN_FLAGGER_ACTIVITY_List:
    "/gcms-project-service/api/flagger-needs/flagger-activities",
  GCMS_MAIN_PROJECT: "/gcms-project-service/api/project/",
  GCMS_MAIN_USER_PROJECT: "/gcms-project-service/api/project/userProjects/",
  GCMS_MAIN_ALL_MPMS_PROJECTS:
    "/gcms-project-service/api/create-project/all-mpms-projects",
  GCMS_CREATE_PROJECT:
    "/gcms-project-service/api/create-project/create-project/",
  GCMS_CREATE_OVERNIGHT_PROJECT:
    "/gcms-admin-service/api/mpms-overnight-import/",
  GCMS_MAIN_ACTIVE_PROJECTS:
    "/gcms-project-service/api/project/active-projects",
  GCMS_MAIN_ACTIVE_PROJECTS_LOOKUP_DATA:
    "/gcms-project-service/api/project/projects-lookup-data",
  GCMS_MAIN_VIEW_ALL_TASKS_PROJECTS:
    "/gcms-taskmanager-service/api/task/viewalltasks",

  GCMS_MAIN_VIEW_ALL_TASKS_LOOKUP_DATA:
    "/gcms-taskmanager-service/api/task/viewalltasklookup",
  GCMS_MAIN_UPCOMING_PROJECTS_LOOKUP_DATA:
    "/gcms-taskmanager-service/api/task/upcoming-tasks-lookup-data",
  GCMS_MAIN_UPCOMING_PROJECTS:
    "/gcms-taskmanager-service/api/task/upcoming-tasks",
  GCMS_MAIN_CREATE_PROJECT_LOOKUP_DATA:
    "/gcms-project-service/api/create-project/projects-lookup-data",
  GCMS_MAIN_RECENT_ACCESSED_PROJECTS:
    "/gcms-project-service/api/project/recent-accessed-projects",
  PROJECT_HISTORY: "/project-history",
  PROJECT_HISTORY_LOOKUP_DATA:
    "/gcms-project-service/api/project-history-lookup-data/",
  PROJECT_HISTORY_DATA: "/gcms-project-service/api/project-history/",

  GCB_CROSSING: "/gcms-project-service/api/crossing",
  GCB_CROSSING_PUT: "/gcms-project-service/api/crossing?impacted=",
  GCB_GRADE_INVENTORY: "/gcms-gradecrossing-service/api/grade-inventory",
  GCB_GRADE_INVENTORY_LOOKUP:
    "/gcms-gradecrossing-service/api/inventory-lookup-data",
  GCB_PROJECT_BRIDGES: "/gcms-project-service/api/project-bridges",
  DISTRICT_OFFICE_ADMIN: "/gcms-project-service/api/districts",
  EDIT_DISTRICT_OFFICE_ADMIN: "/gcms-project-service/api/districts/",

  LOCATION_INFO_LOCATION: "/gcms-project-service/api/project/location/",
  LOCATION_INFO_BOL_INFO: "/gcms-project-service/api/project/bol-info/",
  LOCATION_INFO_HOP_INFO: "/gcms-project-service/api/project/hop-info/",
  LOCATION_INFO_HOP_INFO_LOOKUP:
    "/gcms-project-service/api/project/hop-info/look-up/",
  LOCATION_INFO_URHOP_INFO: "/gcms-project-service/api/project/urhop-info/",
  LOCATION_INFO_URHOP_INFO_LOOKUP:
  "/gcms-project-service/api/project/urhop-info/look-up/",
  DESIGN_AND_CONSTRUCTION: "/gcms-project-service/api/project/design/",

  MANAGE_PROJECT_FLAGGER_NEEDS: "/gcms-project-service/api/flagger-needs",

  PROJECT_INFO_COMMENTS: "/gcms-project-service/api/project/comments/",
  PROJECT_INFO_INFO: "/gcms-project-service/api/project/info/",
  PROJECT_INFO_HIERARCHY: "/gcms-project-service/api/project/hierarchy/",
  PROJECT_INFO_SECTION130:
    "/gcms-project-service/api/project/section-130-project-type/",
  PROJECT_INFO_PA1: "/gcms-project-service/api/project/pa1/",
  PROJECT_INFO_MUNICIPALITY_LIST:
    "/gcms-project-service/api/project/pa1/municipality-list",
  PROJECT_INFO_COUNTY_LIST: "/gcms-project-service/api/project/pa1/county-list",
  PROJECT_INFO_MUNICIPALITY_COUNTY_LIST:
    "/gcms-project-service/api/project/pa1/county-municipality-list",
  PROJECT_INFO_PHASE_LIST:
    "/gcms-project-service/api/project/pa1/project-phase-list",

  PROJECT_FLAGGER_NEEDS: "/projectFlaggerNeeds",

  ADD_RAILROADS: "/addRailroads",
  SAFETY_MILESTONES: "/safetyMilestones",
  PARTIES_OF_RECORD: "/parties-of-record",
  WORKFLOW_NOTIFICATIONS: "/workflow-notifications",
  PUC_COMMUNICATIONS: "/puc-communications",

  CREATE_PROJECTS_MENU_URL: "mpmsProjects",
  MANAGE_FLAGGER_AND_RAILROADS: "manage-flagger-and-railroads",
  RAILROAD_ESTIMATES: "/railroad-estimates",
  EDIT_RAILROAD_ESTIMATES: "/railroad-estimates/edit",
  MANAGE_RAILROAD_COMPANIES: "manage-railroad-companies",
  ASSIGN_DISTRICTS_URL: "assign-districts",
  ASSIGN_DISTRICTS: "/gcms-admin-service/api/assign-districts",
  ASSIGN_DISTRICTS_LOOKUP_DATA:
    "/gcms-admin-service/api/assign-districts/lookup-data",
  GET_ASSIGN_DISTRICTS_CHECKBOX_LIST:
    "/gcms-admin-service/api/assign-districts/add",
  UPDATE_ASSIGN_DISTRICTS:
    "/gcms-admin-service/api/assign-districts/update-districts",

  MANAGE_FLAGGER_AND_RAILROADS_GET:
    "/gcms-project-service/api/flagger-needs-and-railroad",
  MANAGE_FLAGGER_AND_RAILROADS_GET_LOOKUP:
    "/gcms-project-service/api/flagger-needs-and-railroad/flagger-railroad-lookup-data",
  MANAGE_FLAGGER_AND_RAILROADS_GET_TABLE_LOOKUP:
    "/gcms-project-service/api/flagger-needs-and-railroad/lookup-data",
  GET_RAILROADS: "/gcms-project-service/api/railroads-on-project/",
  GET_CROSSINGS:
    "/gcms-project-service/api/railroads-on-project/railroads-by-crossings/",

  VIEW_ALL_PROJECTS: "/gcms-project-service/api/project/all-projects",

  OTHER: "/other",

  ADVANCED_SEARCH_LOOKUP_DATA:
    "/gcms-project-service/api/advanced-search/lookupData",
  ADVANCED_SEARCH_AUTOCOMPLETE:
    "/gcms-project-service/api/advanced-search/autoComplete",
  ADVANCED_SEARCH_2: "/gcms-project-service/api/advanced-search/all-projects",
  GET_COUNTY_INFO: "/gcms-project-service/api/advanced-search/srdata",
  GET_SEGMENT_BEGIN_INFO:
    "/gcms-project-service/api/advanced-search/segmentbegin/",
  GET_SEGMENT_END_INFO: "/gcms-project-service/api/advanced-search/segmentend/",
  ADVANCED_SEARCH_GET_COUNTY_LIST:
    "/gcms-project-service/api/advanced-search/county-list",
  ADVANCED_SEARCH_GET_MUNICIPALITY_LIST:
    "/gcms-project-service/api/advanced-search/county-municipality",
  ADVANCED_SEARCH: "/GCMS/advancedSearch",
  GET_DOCUMENT_TYPES_UPLOAD_LIST:
    "/gcms-project-service/api/ecs-documents/doctypes",
  GET_SAFETY_MILESTONES_DOCTYPE_UPLOAD_LIST:
    "/gcms-document-service/api/documents/doctypes/SM_UPLD_DT",
  GET_RAILROAD_ESTIMATES_DOCTYPE_LIST:
    "/gcms-document-service/api/documents/doctypes/RR_EST_UPL",
  GET_CONTRACTOR_BID_DOCTYPE_LIST:
    "/gcms-document-service/api/documents/doctypes/CN_BID_UPL",
  GET_RAILROAD_WORK_SUMMARY_DOCTYPE_LIST:
    "/gcms-document-service/api/documents/doctypes/WRK_SUM_UP",
  GET_PUC_COMMUNICATIONS_DOCTYPE_LIST:
    "/gcms-document-service/api/documents/doctypes/PUC_UPL_DT",
  GET_PUC_COMMUNICATIONS: "/gcms-project-service/api/puc-communications/",
  POST_PUC_COMMUNICATIONS: "/gcms-project-service/api/puc-communications/",
  GET_RAILROAD_PLAN_REVIEW_DOCTYPE_LIST:
    "/gcms-document-service/api/documents/doctypes/",
  GET_RAILROAD_RESPONSES_REVIEW_DOCTYPE_LIST:
    "/gcms-document-service/api/documents/doctypes/RR_RESP_DT",
  SAFETY_MILESTONES_DOCTYP: "/SM_VIEW_DT",
  UPLOAD_FILES_SCOPING_FIELDS: "/gcms-project-service/api/ecs-documents/upload",
  UPLOAD_SCOPING_DOCUMENTS: " /gcms-document-service/api/documents/upload",
  PRIORITY_PROJECT: "/gcms-project-service/api/project/priority",
  GET_SCOPINGFIELD_VIEW: "/gcms-project-service/api/document/projectdocs/",
  GET_DOT_DOCS_VIEW:
    "/gcms-document-service/api/documents/grade-crossings-docs/",
  DOWNLOAD_SCOPING_FILE: "/gcms-document-service/api/documents/",
  DOWNLOAD_EXTERNAL_FILE: "/gcms-document-service/external/",
  EXTERNAL_DOC_LIST: "/gcms-document-service/external/documentlist",
  UPDATE_MPMS_INFO: "/gcms-project-service/api/project/info/",
  MPMS_SCOPING_CHECK: "/gcms-project-service/api/project/scopingcomplete/",
  UPLOAD_SCOPING_MILESTONE_RAILROADS:
    "/gcms-project-service/api/railroads-on-project/",
  UPLOAD_SCOPING_MILESTONE_DOT: "/gcms-project-service/api/crossing?mpms=",
  MANAGE_SIGNATURE_TEMPLATE_ROUTE: "/GCMS/maintain-signature-template",
  GET_SIGNATURE_TEMPLATE_ENDPOINT:
    "/gcms-admin-service/api/manage-template-signature",
  ADD_SIGNATURE_TEMPLATE_ENDPOINT: "/gcms-admin-service/api/add-signature",
  EDIT_SIGNATURE_TEMPLATE_ENDPOINT: "/gcms-admin-service/api/update-signature",
  ADMIN_MANAGE_USER: "/gcms-admin-service/api/manageuser",
  GET_SIGNATURE_ADD_EDIT_LOOKUP: "/gcms-admin-service/api/lookupdata",
  PROJECT_INVOLVEMENT_ROUTE: "/project-involvement",
  VIEW_PROJECT_INVOLVEMENT: "/gcms-project-service/api/project-involvement/",
  RAILROAD_FORCE_ACCOUNT_ESTIMATE_LOOKUP:
    "/gcms-funding-service/api/railroad-force-account-estimate/lookupdata/",
  RAILROAD_FORCE_ACCOUNT_ESTIMATE_GET_RAILROAD:
    "/gcms-funding-service/api/railroad-force-account-estimate/railroad-details/",
  GET_RAILROAD_ESTIMATES_LIST:
    "/gcms-funding-service/api/funding/estimated-railroad-list/",
  RAILROAD_FORCE_ACCOUNT_ESTIMATE_EDIT_RAILROAD:
    "/gcms-funding-service/api/railroad-force-account-estimate/",
  GET_RAILROAD_LIST:
    "/gcms-admin-service/api/railroad-admin/railroad-company-details",
  VIEW_PARTIES_OF_RECORD: "/gcms-project-service/api/parties-of-record/",
  GET_PARTIES_OF_RECORD:
    "/gcms-project-service/api/parties-of-record/get-parties/",
  ADD_PARTIES_OF_RECORD:
    "/gcms-project-service/api/parties-of-record/add-party-record/",
  UPDATE_PARTIES_OF_RECORD:
    "/gcms-project-service/api/parties-of-record/update-party-record/",
  DELETE_PARTIES_OF_RECORD:
    "/gcms-project-service/api/parties-of-record/delete-party-record/",

  LOOKUPDATA_PARTIES_OF_RECORD:
    "/gcms-project-service/api/parties-of-record/party-record-lookupdata/",

  GET_STATES: "/gcms-project-service/api/parties-of-record/state-dropdown",

  ADD_NEW_RAILROAD_COMPANY:
    "/gcms-admin-service/api/railroad-admin/add-railroad-company-details",
  EDIT_NEW_RAILROAD_COMPANY:
    "/gcms-admin-service/api/railroad-admin/update-railroad-company-details",
  RAILROAD_COMPANY_LOOKUP_DATA:
    "/gcms-admin-service/api/railroad-admin/lookup-data",

  VIEW_RAILROAD_CONTACTS:
    "/gcms-admin-service/api/railroad-contacts/get-contacts/",
  ADD_RAILROAD_CONTACTS:
    "/gcms-admin-service/api/railroad-contacts/add-contact",
  DELETE_RAILROAD_CONTACTS:
    "/gcms-admin-service/api/railroad-contacts/delete-contact/",
  ADD_RAILROAD_CONTACTS_DIALOG:
    "/gcms-project-service/api/railroads-on-project/add-contacts/",
  DELETE_RAILROAD_CONTACTS_REMOVE:
    "/gcms-project-service/api/railroads-on-project/delete-contact",

  UPDATE_RAILROAD_CONTACTS:
    "/gcms-admin-service/api/railroad-contacts/update-contact",

  ADD_SAP_SUFFIXES: "/gcms-admin-service/api/railroad-admin/add-sap-suffix",
  UPDATE_SAP_SUFFIXES:
    "/gcms-admin-service/api/railroad-admin/update-sap-suffix",
  DELETE_SAP_SUFFIXES:
    "/gcms-admin-service/api/railroad-admin/delete-sap-suffix/",

  GET_WORKFLOW_NOTIFICATIONS:
    "/gcms-project-service/api/workflow-notification/",

  GET_WORKFLOW_SEND_TO_ENGINEERING_AUTHORIZATION_LETTIER:
    "/gcms-project-service/api/document/engineering-authorization-letter/",
  GET_WORKFLOW_SEND_TO_ENGINEERING_AUTHORIZATION_DATA:
    "/gcms-project-service/api/document/engineering-authorization-data/",
  GET_WORKFLOW_SEND_TO_ENGINEERING_AUTHORIZATION_ALL_DATA:
    "/gcms-project-service/api/document/engineering-authorization-datalist",
  RAILROAD_PLAN_REVIEW: "/railroad-plan-review",
  GET_RAILROAD_PLAN_RAILROAD_DROPDOWN_LIST:
    "/gcms-project-service/api/railroads-on-project/",
  GET_PROJECT_PLANS_SEND_LIST: "/gcms-project-service/api/project-plans/",
  SEND_PROJECT_PLAN_RAILROAD:
    "/gcms-project-service/api/project-plans/notification/",
  PROJECT_TEAM: "/project-team",
  GET_PROJECT_TEAM: "/gcms-project-service/api/project-team",
  GET_PROJECT_TEAM_DETAILS: "/gcms-project-service/api/project-team/role/",
  UPDATE_PROJECT_TEAM_DETAILS: "/gcms-project-service/api/project-team/add/",
  PROJECT_MILESTONES: "/project-milestones",
  GET_PROJECT_MILESTONES_LIST: "/gcms-project-service/api/project-milestones/",
  GET_VERSIONING_FILE_LIST:
    "/gcms-document-service/api/documentversion/documentversionlist?mpmsNumber=",
  GET_VERSIONING_FILE_LIST_DOT:
    "/gcms-document-service/api/documentversion/documentversionlist?dotNumber=",
  UPDATE_VERSION: "/gcms-document-service/api/documentversion/versionupdate",
  SEND_NTP: "/gcms-project-service/api/document/notice-to-proceed-letter/",
  SEND_FORCE_ACCOUNT_ESTIMATE:
    "/gcms-funding-service/api/railroad-force-account-estimate/accepted-estimates-pdf",
  ACCEPT_ESTIMATE_POST:
    "/gcms-funding-service/api/railroad-force-account-estimate/accept-estimate/",
  RAILROAD_CERTIFICATION: "/railroad-certification",
  CONDITIONAL_RAILROAD_CERTIFICATIONS: "conditional-railroad-certifications",
  GET_CONDITIONAL_RAILROAD_CERTIFICATIONS:
    "/gcms-project-service/api/railroad-certifications/conditional",
  RAILROAD_WORK_SUMMARY: "/railroad-work-summary",
  RAILROAD_WORK_SUMMARY_GET_RAILROAD:
    "/gcms-project-service/api/railroad-work-summary/project-details/",
  RAILROAD_WORK_SUMMARY_POST:
    "/gcms-project-service/api/railroad-work-summary/",
  RAILROAD_WORK_SUMMARY_CROSSING_DETAILS:
    "/gcms-project-service/api/railroad-work-summary/crossing-details/",
  RAILROAD_CERTIFICATION_GET: "/gcms-project-service/api/project/",
  RAILROAD_CERTIFICATION_POST:
    "/gcms-project-service/api/railroad-certifications/",
  RAILROAD_CERTIFICATE_PUC_API:
    "/gcms-project-service/api/document/puc-documents/",
  RAILROAD_CERTIFICATE_GENERATE:
    "/gcms-project-service/api/document/railroad-cert/generate-railroad-certification/",
  RAILROAD_CERTIFICATE_PREVIEW:
    "/gcms-project-service/api/document/railroad-cert/preview-railroad-certification/",
  NO_RAILROAD_INVOLVEMENT_GRADE_CROSSING_STATUS_CHANGE:
    "/gcms-project-service/api/crossing/no-railroad-involvement-workflow/",
  ONLINE_HELP_POC: "/gcms-project-service/api/projecthelpurl",
  CONTRACTOR_BID: "/contractor-bid",
  CONTRACTOR_BID_GET:
    "/gcms-project-service/api/contractor-bid/get-contractor-bid-details/",
  CONTRACTOR_BID_POST:
    "/gcms-project-service/api/contractor-bid/add-awarded-contractor/",
  CONTRACTOR_BID_PUT:
    "/gcms-project-service/api/contractor-bid/update-awarded-contractor/",
  PROJECT_DOCUMENTS_URL: "/project-document",
  GET_PROJECT_DOCUMENTS_LOOKUP_LIST:
    "/gcms-project-service/api/document/filtered-projectdocs-lookup-data",
  GET_PROJECT_DOCUMENTS_DOCTYPE_LIST:
    "/gcms-document-service/api/documents/doctypes/PD_VIEW_DT",
  UPLOAD_PROJECT_DOCUMENTS_DOCTYPE_LIST:
    "/gcms-document-service/api/documents/doctypes/PD_UPLD_DT",
  CHANGE_STATUS_GET_LOOKUP:
    "/gcms-project-service/api/project/modify-project-status?",
  UPDATE_PROJECT_STATUS:
    "/gcms-project-service/api/project/modify-project-status/",
  RE_OPEN_PROJECT: "/gcms-project-service/api/project/reopen-project/",
  FOLLOW_UNFOLLOW_MPMS_PROJECT:
    "/gcms-project-service/api/project/follow-unfollow/",
  HOLD_UNHOLD_MPMS_PROJECT:
    "/gcms-project-service/api/project/project-on-hold/",
  GCMS_MANAGEUSER_ROLE: "/gcms-manageuser-service/api/gcms-access/useraccess?",
  GCMS_MANAGEUSER_TIMEOUT: "/gcms-manageuser-service/api/timeout",
  GET_INSURANCE_RAILROAD_SPEC:
    "/gcms-project-service/api/railroad-certifications/insurance-specifications/",
  GET_DOCUMENTS_FILTERED_API:
    "/gcms-project-service/api/document/filtered-projectdocs/",
  GET_D42790_GET_API:
    "/gcms-project-service/api/railroad-certifications/D4279A-panel/",
  TOGGLE_PROJECT_PENDING_NEW_CROSSING:
    "/gcms-project-service/api/crossing/project-pending-new-crossing/",
  DOT_PROJECT_LIST:
    "/gcms-gradecrossing-service/api/grade-crossing-record/crossing-projects/",
  DOT_PROJECT_LIST_LOOKUP:
    "/gcms-gradecrossing-service/api/grade-crossing-record/crossing-projects-lookupdata/",
  UPLOAD_GC_DOCUMENTS_DOCTYPE_LIST:
    "/gcms-document-service/api/documents/doctypes/GC_UPLD_DT",
  DOT_OWNER_OPERATOR:
    "/gcms-gradecrossing-service/api/grade-crossing-record/penndot-crossing-owner-operator/",
  ACTIVE_RAILROAD_LIST: "/gcms-gradecrossing-service/api/active-railroads",
  CHECK_EXTERNAL_BANNER: "/gcms-document-service/external/banner-content",
  GET_USER_PERMISSIONS: "/gcms-manageuser-service/api/gcms-access/allaccess",
};
