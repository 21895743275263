<template>
  <v-card-text>
    <ProjectTeam
      @BannerUpdate="$emit('BannerUpdate', $event)"
      @refreshMpmsData="$emit('refreshMpmsData')"
    ></ProjectTeam>
  </v-card-text>
</template>
  <script>
import ProjectTeam from "@/modules/manageproject/components/ProjectTeam.vue";

export default {
  name: "ViewProjectTeam-page",
  components: {
    ProjectTeam: ProjectTeam,
  },
};
</script>