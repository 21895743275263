<template>
  <v-dialog
    v-model="dialog"
    persistent
    @afterLeave="afterModalLeave"
    max-width="950px"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card v-if="formData">
        <v-card-text style="max-height: 500px; overflow-y: scroll">
          <v-form v-model="isFormValid" class="district-office-form">
            <v-grid>
              <v-row class="dialogRow" cols="12">
                <v-col class="dialogLabeltext" cols="2">
                  <label for="displayDistrict">District: </label>
                </v-col>
                <v-col class="dialogLabeltext" cols="4">
                  <label for="displayDistrict" class="normalText">{{
                    formData.displayDistrict
                  }}</label>
                </v-col>
              </v-row>

              <v-row class="dialogRow" cols="12">
                <v-col class="dialogLabeltext" cols="2">
                  <label for="address1">District Address 1: </label>
                  <label for="address1" class="asterisk">*</label>
                </v-col>
                <v-col class="dialogLabeltext" cols="8">
                  <v-text-field
                    id="address1"
                    v-model="formData.address1"
                    :rules="[requiredRule]"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="dialogRow" cols="12">
                <v-col class="dialogLabeltext" cols="2">
                  <label for="address2">District Address 2: </label>
                </v-col>
                <v-col class="dialogLabeltext" cols="8">
                  <v-text-field
                    id="address2"
                    v-model="formData.address2"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="dialogRow" cols="12">
                <v-col class="dialogLabeltext" cols="2">
                  <label for="city">City: </label>
                  <label for="city" class="asterisk">*</label>
                </v-col>
                <v-col class="dialogLabeltext" cols="4">
                  <v-text-field
                    v-model="formData.city"
                    :rules="[requiredRule]"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col class="dialogLabeltext" cols="1">
                  <label for="State">State: </label>
                </v-col>
                <v-col class="dialogLabeltext" cols="3">
                  <label for="pa" class="normalText">PA</label>
                </v-col>
              </v-row>

              <v-col class="fillerSpace" cols="2"></v-col>
              <v-row class="dialogRow" cols="12">
                <v-col class="dialogLabeltext" cols="2">
                  <label for="zip">ZIP: </label>
                  <label for="zip" class="asterisk">*</label>
                </v-col>
                <v-col class="dialogLabeltext" cols="4">
                  <ZIPInput id="zip" v-model="formData.zipcode"> </ZIPInput>
                </v-col>
              </v-row>

              <v-row class="dialogRow" cols="12">
                <v-col class="dialogLabeltext" cols="2">
                  <label for="notificationEmail">Notification Email: </label>
                  <label for="notificationEmail" class="asterisk">*</label>
                </v-col>
                <v-col class="dialogLabeltext" cols="4">
                  <EmailInput
                    id="notificationEmail"
                    v-model="formData.notificationEmail"
                    :isRequired="true"
                  ></EmailInput>
                </v-col>
                <v-col class="fillerSpace" cols="1"></v-col>

                <v-col class="dialogLabeltext" cols="1">
                  <label for="phone">Phone: </label>
                  <label for="phone" class="asterisk">*</label>
                </v-col>
                <v-col class="dialogLabeltext" cols="2">
                  <PhoneInput
                    id="phone"
                    v-model="formData.phone"
                    :isRequired="true"
                  >
                  </PhoneInput>
                </v-col>
              </v-row>
              <br />
              <br />

              <v-row class="subheaderRow" cols="12">
                <v-col class="dialogLabeltext" cols="12">
                  <div class="env-header">
                    <h2>Contract Management Contacts</h2>
                  </div>
                </v-col>
              </v-row>

              <v-row class="dialogRow" cols="12">
                <v-col class="subheaderText" cols="12">
                  <label for="Identify"
                    >Identify one or more DCM email addresses to receive a
                    notification when Railroad Clearance and associated
                    documents are ready to add to the ECMS Project Development
                    Checklist
                  </label>
                </v-col>
              </v-row>
              <v-row class="dialogRow" cols="12">
                <v-col class="dialogLabeltext" cols="2">
                  <label for="dcmEmail">DCM Email 1: </label>
                  <label for="dcmEmail" class="asterisk">*</label>
                </v-col>
                <v-col
                  class="dialogLabeltext"
                  cols="4"
                  v-if="formData.emails[0]?.dcmEmail"
                >
                  <EmailInput
                    id="dcmEmail"
                    v-model="formData.emails[0].dcmEmail"
                    :isRequired="true"
                  ></EmailInput>
                </v-col>
                <v-col class="dialogLabeltext" cols="4" v-else>
                  <EmailInput
                    id="dcmEmail"
                    v-model="formData.dcmEmail1"
                    :isRequired="true"
                  ></EmailInput>
                </v-col>
              </v-row>
              <v-row class="dialogRow" cols="12">
                <v-col class="dialogLabeltext" cols="2">
                  <label for="dcmEmail2">DCM Email 2: </label>
                </v-col>
                <v-col
                  class="dialogLabeltext"
                  cols="4"
                  v-if="formData.emails[1]?.dcmEmail"
                >
                  <EmailInput
                    id="dcmEmail2"
                    v-model="formData.emails[1].dcmEmail"
                  ></EmailInput>
                </v-col>
                <v-col class="dialogLabeltext" cols="4" v-else>
                  <EmailInput
                    id="dcmEmail2"
                    v-model="formData.dcmEmail2"
                  ></EmailInput>
                </v-col>
              </v-row>
              <v-row class="dialogRow" cols="12">
                <v-col class="dialogLabeltext" cols="2">
                  <label for="dcmEmail3">DCM Email 3: </label>
                </v-col>
                <v-col
                  class="dialogLabeltext"
                  cols="4"
                  v-if="formData.emails[2]?.dcmEmail"
                >
                  <EmailInput
                    id="dcmEmail3"
                    v-model="formData.emails[2].dcmEmail"
                  ></EmailInput>
                </v-col>
                <v-col class="dialogLabeltext" cols="4" v-else>
                  <EmailInput v-model="formData.dcmEmail3"></EmailInput>
                </v-col>
              </v-row>
            </v-grid>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :buttonText="'Save'"
              :disabled="
                !isFormValid ||
                !isAddressFormValid ||
                !isEmailFormValid ||
                !isPhoneFormValid
              "
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import districtOfficeDialog from "@/modules/gcmsmain/admin/submenu/scripts/DistrictOfficeDialog.js";

export default districtOfficeDialog;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
