import useDateField from "@/composables/dateField.js";import useFieldEntry from "@/composables/fieldEntry";
import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import {
  AUTO_COMPLETE_NO_DATA,
  DROPDOWN_NO_DATA,
} from "@/constants/displayTexts";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import { useProjectServiceStore } from "@/stores/projectService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";

import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { MAX_DATE } from "../../../constants/dates";

export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    FeatureTable,
    VueAutoComplete,
    DatePicker,
    OnlineHelpPOC,
  },
  name: "AdvancedSearch",
  setup() {
    const userRoleBasedAccessService = userRoleBasedAccessStore();

    const { getIdentifierIcon } = useIconsFunctions();
    let panel = ref([0]);
    const projectServiceStore = useProjectServiceStore();
    const loading = ref(true);
    const searchedProjects = ref([]);
    const lookupData = ref({});
    const { allowAlphanumericOnly } = useFieldEntry();
    let municipalityList = ref([]);
    let countyList = ref([]);
    const sec = ref(null);
    const enableSearch = ref(true);
    const searchClicked = ref(false);
    const totalProjects = ref(null);
    let isDateValid1 = ref(false);
    let isDateValid2 = ref(false);
    let maxDate1 = ref(new Date(MAX_DATE).toLocaleDateString("fr-ca"));
    let maxDate2 = ref(new Date(MAX_DATE).toLocaleDateString("fr-ca"));
    let minDate1 = ref();
    let minDate2 = ref();
    const autoCompleteLookup = ref({
      roads: [],
      dotNumbers: [],
      pucDocketNumbers: [],
      titles: [],
      railroads: [],
    });
    const advancedSearch = ref({
      districts: [],
      sr: null,
      road: null,
      counties: [],
      sec: null,
      municipality: [],
      segment1: null,
      segment2: null,
      dotNumber: null,
      pucDocketNumber: null,
      title: null,
      letDate1: null,
      letDate2: null,
      user: null,
      statuses: [],
      railroad: null,
      safetyProject: null,
      constructionProject: null,
      agreementNumber: null,
    });

    const headers = ref([
      {
        title: "Identifier",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "projectIdentifier",
        columnType: "icon",
        icon: "projectIdentifierIcon",
        sortable: false,
      },
      {
        title: "MPMS#",
        filter: null,
        filterType: "textbox",
        filterWith: "startWith",
        sortType: "number",
        key: "mpmsNumber",
        isLink: true,
      },
      {
        title: "SR",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        key: "stateRoute",
      },
      {
        title: "Section",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "srSection",
      },
      {
        title: "Project Title",
        filter: null,
        filterType: "textbox",
        filterWith: "includes",
        sortType: "text",
        key: "projectTitle",
      },
      {
        title: "Project Status",
        filter: null,
        filterType: "textbox",
        filterWith: "includes",
        sortType: "text",
        key: "status",
      },
      {
        title: "District",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        key: "district",
      },
      {
        title: "County",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "county",
      },
      {
        title: "Municipality",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "municipality",
      },
      {
        title: "Let Date",
        filter: [],
        filterType: "multi-select",
        filterSort: "date",
        filterWith: "yearMonth",
        sortType: "date",
        key: "letDate",
        initialSort: true,
        sortOrder: "desc",
      },
      {
        title: "RR Project Identifier",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "rrProjectIdentifier",
      },
    ]);

    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "letDate",
      order: "asc",
    });
    const { getFormattedDateStringNoTime } = useDateField();
    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };

    const queryRoad = ref(null);
    const queryTitle = ref(null);
    const queryDOT = ref(null);
    const queryPUC = ref(null);
    const queryRailroad = ref(null);
    const isAccess = ref({
      read: false,
      update: false,
    });

    onMounted(async () => {
      getLookupData();
      await retrieveFullCountyList();
      await accessCheck();
    });

    const accessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.ADVANCED_SEARCH_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.ADVANCED_SEARCH_PAGE_READ_ACCESS
      );
      isAccess.value.read = readResponse;
      isAccess.value.update = updateResponse;
    };
    const getLookupData = () => {
      axios
        .get(CONFIG_ENDPOINT.ADVANCED_SEARCH_LOOKUP_DATA)
        .then((response) => {
          lookupData.value = response.data;
        })
        .catch((err) => {});
    };

    const getProperty = (value) => {
      if (!value) {
        return undefined;
      } else {
        return value;
      }
    };

    const createJSON = () => {
      let tempMunicipalities = Array.from(advancedSearch.value.municipality);
      for (let i = 0; i < tempMunicipalities.length; i++) {
        tempMunicipalities[i] = tempMunicipalities[i].split(": ").pop();
      }
      let json = {
        district:
          advancedSearch.value.districts.length == 0
            ? undefined
            : advancedSearch.value.districts,
        county:
          advancedSearch.value.counties.length == 0
            ? undefined
            : advancedSearch.value.counties,
        municipality:
          advancedSearch.value.municipality.length == 0
            ? undefined
            : tempMunicipalities,
        stateRoute: !advancedSearch.value.sr
          ? undefined
          : advancedSearch.value.sr.split("-")[0],
        srSection: getProperty(advancedSearch.value.sec),
        segmentBegin: getProperty(advancedSearch.value.segment1),
        segmentEnd: getProperty(advancedSearch.value.segment2),
        roadName: getProperty(advancedSearch.value.road),

        letDate1: getProperty(advancedSearch.value.letDate1),
        letDate2: getProperty(advancedSearch.value.letDate2),

        projectTitle: getProperty(advancedSearch.value.title),
        dotNumber: getProperty(advancedSearch.value.dotNumber),
        pucDocketNumber: getProperty(advancedSearch.value.pucDocketNumber),
        safetyProject: !advancedSearch.value.safetyProject ? undefined : "Y",
        highwayBridgeProject: !advancedSearch.value.constructionProject
          ? undefined
          : "Y",

        railroadName: getProperty(advancedSearch.value.railroad),
        status:
          advancedSearch.value.statuses.length == 0
            ? undefined
            : advancedSearch.value.statuses,
      };

      return json;
    };

    const search = () => {
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      let json = createJSON();
      loading.value = true;

      searchClicked.value = true;
      axios
        .post(CONFIG_ENDPOINT.ADVANCED_SEARCH_2 + filterText, json)
        .then((response) => {
          setSearchData(response, json);
        })
        .catch((err) => {
          loading.value = false;
          searchedProjects.value = [];

          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
        });
    };
    const setSearchData = (response, json) => {
      loading.value = false;
      searchedProjects.value = response.data.data;
      totalProjects.value = response.data.totalElements;
      searchedProjects.value.forEach((item) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.letDate = getDate(item.letDate);
      });
      paginationData.value.total = response.data.totalElements;
      headers.value.forEach((header, index) => {
        headers.value[index] = {
          ...header,
          sortable: !checkFieldDisabled(header.key),
        };
      });
      currentFilters.value = json;
    };
    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + totalProjects.value;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      axios
        .post(
          CONFIG_ENDPOINT.ADVANCED_SEARCH_2 + filterText,
          currentFilters.value
        )
        .then((response) => {
          setExportAdvancedSearch(response);
        })
        .catch((err) => {});
    };
    const setExportAdvancedSearch = (response) => {
      loading.value = false;
      let exportDataList = response.data.data;
      exportDataList.forEach((item) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.letDate = getDate(item.letDate);
      });
      ExportToCSV(headers.value, exportDataList, "Advanced Search");
    };

    const checkFieldDisabled = (key) => {
      return key === "projectIdentifier" || key === "rrProjectIdentifier";
    };

    const autoComplete = (table) => {
      if (table == "street" && queryRoad.value) {
        axios
          .post(CONFIG_ENDPOINT.ADVANCED_SEARCH_AUTOCOMPLETE, {
            street: queryRoad.value,
          })
          .then((response) => {
            autoCompleteLookup.value.roads = response.data.street;
          })
          .catch((err) => {});
      } else if (table == "title" && queryTitle.value) {
        axios
          .post(CONFIG_ENDPOINT.ADVANCED_SEARCH_AUTOCOMPLETE, {
            projectTitle: queryTitle.value,
          })
          .then((response) => {
            autoCompleteLookup.value.titles = response.data.projectTitle;
          })
          .catch((err) => {});
      } else if (table == "dotnumber" && queryDOT.value) {
        axios
          .post(CONFIG_ENDPOINT.ADVANCED_SEARCH_AUTOCOMPLETE, {
            dotnumber: queryDOT.value,
          })
          .then((response) => {
            autoCompleteLookup.value.dotNumbers = response.data.dotnumber;
          })
          .catch((err) => {});
      } else if (table == "pucDocketNumber" && queryPUC.value) {
        axios
          .post(CONFIG_ENDPOINT.ADVANCED_SEARCH_AUTOCOMPLETE, {
            pucDocketNumber: queryPUC.value,
          })
          .then((response) => {
            autoCompleteLookup.value.pucDocketNumbers =
              response.data.pucDocketNumber;
          })
          .catch((err) => {});
      } else if (table == "railroadName" && queryRailroad.value) {
        axios
          .post(CONFIG_ENDPOINT.ADVANCED_SEARCH_AUTOCOMPLETE, {
            railroadName: queryRailroad.value,
          })
          .then((response) => {
            autoCompleteLookup.value.railroads = response.data.railroadName;
          })
          .catch((err) => {});
      }
    };

    async function retrieveFullCountyList() {
      let response = projectServiceStore.countyMunicipalityMap;
      if (!response) {
        await projectServiceStore.retrieveMunicipalityCountyList();
        response = projectServiceStore.countyMunicipalityMap;
      }
      countyList.value = response?.data;
      sortList(countyList);
    }
    const retrieveCountyList = (districts) => {
      if (districts.length == 0) {
        retrieveFullCountyList();
      }
      axios
        .post(CONFIG_ENDPOINT.ADVANCED_SEARCH_GET_COUNTY_LIST, districts)
        .then((response) => {
          countyList.value = response.data;
          sortList(countyList);
        })
        .catch((err) => {});
    };

    const retrieveMunicipalities = (counties) => {
      axios
        .post(CONFIG_ENDPOINT.ADVANCED_SEARCH_GET_MUNICIPALITY_LIST, counties)
        .then((response) => {
          municipalityList.value = [];

          for (let index in response.data) {
            municipalityList.value.push(...response.data[index].municipalities);
          }
          sortList(municipalityList);
        })
        .catch((err) => {});
    };

    function sortList(list) {
      list.value?.sort((a, b) => {
        if (
          a?.municipalityName?.toLowerCase() <
          b?.municipalityName?.toLowerCase()
        )
          return -1;
        if (
          a?.municipalityName?.toLowerCase() >
          b?.municipalityName?.toLowerCase()
        )
          return 1;
        return 0;
      });
    }

    const removeCounty = (index) => {
      advancedSearch.value.counties.splice(index, 1);
    };

    const removeMunicipality = (index) => {
      advancedSearch.value.municipality.splice(index, 1);
    };

    const getCountyData = (counties) => {
      axios
        .post(CONFIG_ENDPOINT.GET_COUNTY_INFO, counties)
        .then((response) => {
          lookupData.value.stateRoutes = response.data;
          lookupData.value.stateRoutes.sort();
        })
        .catch((err) => {
          lookupData.value.stateRoutes = [];
        });
    };

    const getSegment1Data = (segment) => {
      axios
        .get(CONFIG_ENDPOINT.GET_SEGMENT_BEGIN_INFO + segment)
        .then((response) => {
          lookupData.value.segment1 = response.data;
        })
        .catch((err) => {});
    };
    const getSegment2Data = (segment) => {
      axios
        .get(CONFIG_ENDPOINT.GET_SEGMENT_END_INFO + segment)
        .then((response) => {
          lookupData.value.segment2 = response.data;
        })
        .catch((err) => {});
    };

    watch(
      () => [advancedSearch.value.counties],
      () => {
        if (advancedSearch.value.counties.length != 0) {
          retrieveMunicipalities(advancedSearch.value.counties);
          getCountyData(advancedSearch.value.counties);
        } else {
          lookupData.value.stateRoutes = [];
          advancedSearch.value.sr = null;
        }
      },
      { deep: true }
    );

    watch(
      () => [advancedSearch.value.districts],
      () => {
        if (advancedSearch.value.districts.length != 0) {
          retrieveCountyList(advancedSearch.value.districts);
        } else {
          retrieveFullCountyList();
        }
      },
      { deep: true }
    );

    watch(
      () => [advancedSearch.value.sr],
      () => {
        getSegment1Data(advancedSearch.value.sr);
      },
      { deep: true }
    );

    watch(
      () => [advancedSearch.value.segment1],
      () => {
        getSegment2Data(advancedSearch.value.segment1);
      },
      { deep: true }
    );

    watch(
      () => [advancedSearch.value],
      () => {
        if (advancedSearch.value) {
          enableSearch.value = false;
        }
      },
      { deep: true }
    );

    watch(
      () => [queryRoad.value],
      () => {
        if (queryRoad.value == "") {
          autoCompleteLookup.value.roads = [];
        }
      },
      { deep: true }
    );

    watch(
      () => [queryPUC.value],
      () => {
        if (queryPUC.value == "") {
          autoCompleteLookup.value.pucDocketNumbers = [];
        }
      },
      { deep: true }
    );

    watch(
      () => [queryDOT.value],
      () => {
        if (queryDOT.value == "") {
          autoCompleteLookup.value.dotNumbers = [];
        }
      },
      { deep: true }
    );

    watch(
      () => [queryTitle.value],
      () => {
        if (queryTitle.value == "") {
          autoCompleteLookup.value.titles = [];
        }
      },
      { deep: true }
    );

    watch(
      () => [queryRailroad.value],
      () => {
        if (queryRailroad.value == "") {
          autoCompleteLookup.value.railroads = [];
        }
      },
      { deep: true }
    );

    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      search();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      search();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      search();
    };

    const clear = () => {
      advancedSearch.value = {
        districts: [],
        sr: null,
        road: null,
        counties: [],
        sec: null,
        municipality: [],
        segment1: null,
        segment2: null,
        dotNumber: null,
        pucDocketNumber: null,
        title: null,
        letDate1: null,
        letDate2: null,
        user: null,
        statuses: [],
        railroad: null,
        safetyProject: null,
        constructionProject: null,
        agreementNumber: null,
      };
      isDateValid1.value = true;
      isDateValid2.value = true;
      maxDate1.value = new Date(MAX_DATE).toLocaleDateString("fr-ca");
      maxDate2.value = new Date(MAX_DATE).toLocaleDateString("fr-ca");
      minDate1.value = null;
      minDate2.value = null;
      setTimeout(() => {
        getLookupData();
        retrieveFullCountyList();
      }, 2000);
    };
    const dateChange1 = (date) => {
      minDate2.value = date;
      advancedSearch.value.letDate1 = minDate2.value;

      if (advancedSearch.value.letDate1 == "") {
        advancedSearch.value.letDate1 = null;
      }
      maxDate2.value = new Date(MAX_DATE).toLocaleDateString("fr-ca");
    };
    const dateChange2 = (date) => {
      maxDate1.value = date;
      let today = new Date(maxDate1.value);
      let numberOfDaysToSubtract = 1000;
      let tenYearsMinus = today.setFullYear(
        today.getFullYear() - numberOfDaysToSubtract
      );
      minDate1.value = new Date(tenYearsMinus).toLocaleDateString("fr-ca");

      advancedSearch.value.letDate2 = maxDate1.value;
      if (maxDate1.value == "") {
        advancedSearch.value.letDate2 = null;
      }
    };
    const checkDateValidation = (value) => {
      isDateValid1.value = value;
    };
    const checkDateValidation2 = (value) => {
      isDateValid2.value = value;
    };
    const fromMinDate = computed({
      get: () => {
        return minDate1.value;
      },
    });
    const fromMaxDate = computed({
      get: () => {
        return maxDate1.value;
      },
    });
    const toMaxDate = computed({
      get: () => {
        return maxDate2.value;
      },
    });
    const toMinDate = computed({
      get: () => {
        return minDate2.value;
      },
    });
    return {
      loading,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      paginationData,
      sortData,
      removeCounty,
      removeMunicipality,
      getProperty,
      createJSON,
      getSegment1Data,
      getSegment2Data,
      enableSearch,
      clear,
      searchedProjects,
      headers,
      panel,
      getLookupData,
      lookupData,
      allowAlphanumericOnly,
      advancedSearch,
      retrieveCountyList,
      municipalityList,
      countyList,
      sortList,
      sec,
      autoCompleteLookup,
      search,
      autoComplete,
      queryTitle,
      queryDOT,
      queryPUC,
      queryRoad,
      queryRailroad,
      getCountyData,
      searchClicked,
      totalProjects,
      dateChange1,
      dateChange2,
      fromMinDate,
      fromMaxDate,
      toMaxDate,
      toMinDate,
      checkDateValidation,
      checkDateValidation2,
      isDateValid1,
      isDateValid2,
      DROPDOWN_NO_DATA,
      AUTO_COMPLETE_NO_DATA,
      exportButtonClicked,
      isAccess,
    };
  },
};
