import moment from "moment";import { computed, onMounted, ref } from "vue";
export default {
  name: "MonthYearPicker",
  props: ["maxDate", "minDate", "value", "rules", "disabled", "invalidMsg"],
  watch: {
    value(value) {
      if (this.isValidLocalDate({ value: value })) {
        this.dateValue = value;
      }
    },
  },
  setup(props, context) {
    onMounted(() => {
      context.emit("dateValidation", isValidLocalDate(props));
    });
    let maxYear = ref(new Date());
    let minYear = ref(new Date() - 10);
    let dateValue = ref(new Date());
    let invalidDate = ref(false);
    let invalidMsg = ref("Invalid Date");
    const maxDate = computed({
      get: () => {
        let maxValue = props?.maxDate || new Date().toLocaleDateString("fr-ca");

        const returnValue = maxValue.split("-");

        return returnValue[0] + "-" + returnValue[1];
      },
    });
    const disabled = computed({
      get: () => {
        if (props?.disabled) {
          invalidDate.value = false;
        }
        return props?.disabled;
      },
    });
    const minDate = computed({
      get: () => {
        let today = new Date();
        let numberOfDaysToSubtract = 10;
        let tenYearsMinus = today.setFullYear(
          today.getFullYear() - numberOfDaysToSubtract
        );

        let maxValue =
          props?.minDate || new Date(tenYearsMinus).toLocaleDateString("fr-ca");

        const returnValue = maxValue.split("-");

        return returnValue[0] + "-" + returnValue[1];
      },
    });
    const value = computed({
      get: () => {
        if (props?.value) {
          dateValue.value = props?.value;
        }
        return props?.value;
      },
    });
    const rules = computed({
      get: () => {
        return props?.rules;
      },
    });
    const getInvalidMsg = computed({
      get: () => {
        let currentValue =
          dateValue.value?.target?.value ||
          dateValue.value?.target?.value === ""
            ? dateValue.value?.target?.value
            : dateValue.value;
        if (!currentValue && props?.rules?.length) {
          return props?.rules?.includes("requiredRule") ? "Required" : "";
        }
        if (currentValue?.length != 7) {
          return "Invalid Date";
        }
        if (!moment(currentValue, "YYYY-MM", true).isValid()) {
          return "Invalid Date";
        } else if (
          moment(currentValue, "YYYY-MM", true) >
          moment(new Date(), "YYYY-MM", true)
        ) {
          return props?.invalidMsg + " cannot be in the future";
        }
        return "Invalid Date";
      },
    });
    const updateDate = () => {
      context.emit("dateValidation", isValidLocalDate(dateValue));

      if (isValidLocalDate(dateValue)) {
        invalidDate.value = false;
        context.emit("input", dateValue.value);
      } else {
        invalidDate.value = true;
      }
    };
    const isValidLocalDate = (date) => {
      if (props?.disabled) {
        return true;
      }
      if (!date.value) {
        return props?.rules?.includes("requiredRule") ? false : true;
      }
      if (date.value?.length != 7) {
        return false;
      }
      return (
        moment(date.value, "YYYY-MM", true).isValid() &&
        moment(dateValue.value, "YYYY-MM", true) <
          moment(new Date(), "YYYY-MM", true)
      );
    };
    return {
      invalidMsg,
      maxYear,
      minYear,
      dateValue,
      maxDate,
      minDate,
      value,
      rules,
      updateDate,
      isValidLocalDate,
      invalidDate,
      disabled,
      getInvalidMsg,
    };
  },
};
