<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    id="dialog"
    v-model="dialog"
    max-width="1050px"
    max-height="4000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card v-if="formData">
        <v-card-text style="max-height: 500px; overflow-y: scroll">
          <v-form v-model="isFormValid" class="district-office-form">
            <v-grid>
              <br />

              <v-row class="dialogRowParty" cols="12">
                <v-col class="dialogLabeltextParty" cols="2">
                  <label for="firstName">First Name: </label>
                  <label for="firstName" class="asterisk">*</label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="4">
                  <v-text-field
                    id="firstName"
                    v-model="formData.firstName"
                    :rules="[requiredRule]"
                    @keydown="allowAlphaOnly($event)"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1"></v-col>

                <v-col class="dialogLabeltextParty" cols="2">
                  <label for="middleName">Middle Name: </label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="3">
                  <v-text-field
                    id="middleName"
                    v-model="formData.middleName"
                    @keydown="allowAlphaOnly($event)"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="dialogRowParty" cols="12">
                <v-col class="dialogLabeltextParty" cols="2">
                  <label for="lastName">Last Name: </label>
                  <label for="lastName" class="asterisk">*</label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="4">
                  <v-text-field
                    id="lastName"
                    v-model="formData.lastName"
                    :rules="[requiredRule]"
                    @keydown="allowAlphaOnly($event)"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1"></v-col>

                <v-col class="dialogLabeltextParty" cols="2">
                  <label for="suffix">Suffix: </label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="1">
                  <v-text-field
                    id="suffix"
                    v-model="formData.suffix"
                    type="text"
                    min="0"
                    maxlength="5"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="dialogRowParty" cols="12">
                <v-col class="dialogLabeltextParty" cols="2">
                  <label for="primaryPhone">Primary Phone: </label>
                  <label for="primaryPhone" class="asterisk">*</label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="4">
                  <PhoneInput
                    id="primaryPhone"
                    v-model="formData.primaryPhone"
                    :isRequired="true"
                  >
                  </PhoneInput>
                </v-col>
                <v-col cols="1"></v-col>

                <v-col class="dialogLabeltextParty" cols="2">
                  <label for="primaryPhoneExt">EXT: </label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="1">
                  <v-text-field
                    id="primaryPhoneExt"
                    v-model="formData.primaryPhoneExt"
                    @keydown="isNumber($event)"
                    type="text"
                    min="0"
                    maxlength="4"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="dialogRowParty" cols="12">
                <v-col class="dialogLabeltextParty" cols="2">
                  <label for="secondaryPhone">Secondary Phone: </label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="4">
                  <PhoneInput
                    id="secondaryPhone"
                    v-model="formData.secondaryPhone"
                  ></PhoneInput>
                </v-col>
                <v-col cols="1"></v-col>

                <v-col class="dialogLabeltextParty" cols="2">
                  <label for="secondaryPhoneExt">EXT: </label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="1">
                  <v-text-field
                    id="secondaryPhoneExt"
                    v-model="formData.secondaryPhoneExt"
                    type="text"
                    @keydown="isNumber($event)"
                    min="0"
                    maxlength="4"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="dialogRowParty" cols="12">
                <v-col class="dialogLabeltextParty" cols="2">
                  <label for="email">Email: </label>
                  <label for="email" class="asterisk">*</label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="4">
                  <EmailInput
                    v-model="formData.email"
                    :isRequired="true"
                  ></EmailInput>
                </v-col>
              </v-row>

              <br />
            </v-grid>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :buttonText="'Save'"
              :disabled="isSaveDisabled"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
  <script>
import AddEditContactsModal from "@/modules/gcmsmain/admin/submenu/scripts/AddEditContactsModal.js";
export default AddEditContactsModal;
</script>
  <style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
  