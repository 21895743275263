<template>
  <v-card>
    <v-card-text>
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel class="mt-20">
          <v-expansion-panel-title class="pannelColor">
            <div class="panel-header">
              Documents By PUC Docket #
            </div></v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <div class="p-64 mpmsEnvForm w-100">
              <v-form v-model="isFormValid">
                <div class="docketRow">
                  <v-text-field
                    v-model="docketValue"
                    @keydown.enter="submitForm(docketValue)"
                    @keyup="removeError($event)"
                    maxlength="14"
                    minlength="8"
                    class="small-text-field1"
                    :disabled="!isUserGradeCrossingAccess.update"
                  ></v-text-field>
                  <PrimaryButton
                    style="padding-left: 15px !important"
                    :buttonText="'Add PUC Docket #'"
                    @click="submitForm(docketValue)"
                    :disabled="
                      !docketValue || docketValue?.length === 0 || !isFormValid ||
                    !isUserGradeCrossingAccess.update"
                  ></PrimaryButton>
                </div>
                <div class="project-invalid-puc error" v-show="showInvalidPUC">
                  PUC# {{ lastEnteredPUCValue }} already exists for this DOT#
                </div>
                <div
                  class="project-invalid-puc error"
                  v-show="showInvalidPUCFormat"
                >
                  {{ pucFormatErrorMsg }}
                </div>
              </v-form>
              <div class="pucTable w-100">
                <VueTable
                  class="elevation-1 w-60"
                  :columnData="headers"
                  :tableData="docketList"
                  @linkClicked="linkClicked($event)"
                >
                </VueTable>
              </div>
            </div>
            <br><br>

            <DialogComponent ref="dialogComponent"></DialogComponent>
          </v-expansion-panel-text>
        </v-expansion-panel> </v-expansion-panels></v-card-text
  ></v-card>
</template>
<script>
import PucDocuments from "@/modules/dot-details/scripts/PucDocuments.js";
export default PucDocuments;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
