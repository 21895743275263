<template>
  <v-card class="p-64 v-card-text">
    <OnlineHelpPOC :helpKey="'TMPSIGNHLP'"></OnlineHelpPOC>
    <div class="allProjectsHeading">Manage Template Signatures</div>

    <v-card class="p-64">
      <FeatureTable
        :headers="headers"
        :items="signatureDataList"
        :showExportButton="false"
        :showClearFilterButton="true"
        :tableHeader="''"
        :navigateToMpmsDetails="false"
        :clickable="false"
        :navigationKey="'mpmsNumber'"
        :itemsPerPage="paginationData?.items"
        :linkedPagination="true"
        :totalItems="paginationData?.total"
        :lookupData="lookupData"
        :oldMultipleFilter="multipleFilter"
        :hidePagination="true"
        :noDataAvailableText="noDataAvailableText"
        :hideFilter="true"
        @updatePaginationPerPage="updatePaginationPerPage"
        @pageChange="pageChange"
        @sortUpdate="sortUpdate"
        @textFilterChange="textFilterChange"
        @multipleFiltersChange="multipleFiltersChange"
        @clearAllFilter="clearAllFilter"
        @editButtonClicked="editButtonClicked"
      >
      </FeatureTable>
      <div class="d-flex justify-content-start m-20">
        <PrimaryButton
          @buttonClicked="addButtonClicked"
          :buttonText="'Add'"
          :disabled="!isSignatureTemplateUpdateAccess.update"
        ></PrimaryButton>
      </div>
    </v-card>
  </v-card>
  <DialogComponent ref="maintainSignatureDialog"></DialogComponent>
</template>

<script>
import MaintainSignatureTemplate from "@/modules/gcmsmain/admin/submenu/scripts/MaintainSignatureTemplate.js";
export default MaintainSignatureTemplate;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
