<template>
  <div>
    <v-row>
      <v-col class="ml-20">
        <router-link :to="`/GCMS/${id}`" @click="removeBanner">
          &lt; Return to Project Information Page
        </router-link>
      </v-col>
      <v-col class="page-title">
        <h1>Railroad Certifications</h1>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </div>
  <div>
    <div class="env-header mb-20">
      <h2 class="locationHeaderText">Certification Details</h2>
    </div>
  </div>
  <div class="m-20">
    <v-row align="center">
      <v-col cols="6">
        <b>ECMS Contract # </b>: {{ projectDetails?.ecmsNumber }}
      </v-col>
      <v-col cols="6">
        <b>Companion MPMS Project(s)</b>:
        <span>
          <span
            v-for="(project, index) in companionMpmsProjects"
            :key="project.mpmsNumber"
          >
            <span v-if="project.isLink">
              <router-link :to="`/GCMS/${project.mpmsNumber}`">{{
                project.mpmsNumber
              }}</router-link>
            </span>
            <span v-else>
              {{ project.mpmsNumber }}
            </span>
            <span v-if="index < companionMpmsProjects.length - 1">, </span>
          </span>
        </span>
      </v-col>
      <v-col cols="6">
        <b>Federal Project Status</b>:
        {{ projectDetails?.certFederalProjectStatus }}
      </v-col>
      <v-col cols="6">
        <PrimaryButton
          @buttonClicked="openViewFundingDialog"
          :buttonText="'View Funding'"
        ></PrimaryButton>
      </v-col>
      <v-col cols="12">
        <b>PUC Involvement</b>:
        {{ projectDetails?.pucReviewApprovalRequired ? "Yes" : "No" }}
      </v-col>
      <v-col cols="12"> <b>Let Date:</b> {{ projectDetails?.letDate }} </v-col>
      <v-col cols="12">
        <b>Notice to Proceed:</b> {{ projectDetails?.noticeToProceedDate }}
      </v-col>
    </v-row>
    <v-form>
      <div v-if="projectDetails" class="mt-20 mb-20 d-flex">
        <div
          class="w-10 d-flex align-items-center"
          style="padding: 0 !important; height: 40px"
        >
          <b><span>Is Conditional:</span></b>
        </div>
        <div style="padding: 0 !important" class="w-90">
          <div
            class="d-flex align-items-center"
            v-for="(condition, index) of projectDetails?.isConditional"
            :key="condition"
          >
            <div class="w-6" style="padding: 0 !important" cols="1">
              <v-checkbox
                v-model="projectDetails.isConditional[index].selected"
                :disabled="!conditionalAccess.update"
              >
              </v-checkbox>
            </div>
            <div class="w-15" style="padding: 0 !important" cols="1">
              {{ condition?.subTitle }}
            </div>
            <div style="padding: 0 !important" class="w-80">
              {{ condition?.description }}
            </div>
          </div>
        </div>
      </div>
      <v-row class="mt-20">
        <v-col cols="2">
          <div class="mpms-env-label">
            <b :class="{ required: selectedConditionsLengthCheck }">
              <span>Reason for Conditional:</span>
            </b>
          </div>
        </v-col>
        <v-col cols="8" v-if="projectDetails">
          <v-container class="white-textarea comment-box m-0 p-0">
            <v-textarea
              :rules="selectedConditionsLengthCheck ? [requiredRule] : []"
              :counter="1000"
              :maxlength="1000"
              :persistent-counter="true"
              v-model="projectDetails.reasonForConditional"
              :disabled="!conditionalAccess.update"
              variant="solo"
            ></v-textarea>
            <div class="w-100 text-left">
              <span>
                {{ 1000 - (projectDetails?.reasonForConditional?.length || 0) }}
                characters remaining.
              </span>
            </div>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9"></v-col>
        <v-col cols="3">
          <div class="w-100 d-flex align-items-center" v-if="projectDetails">
            <div>
              <v-checkbox
                v-model="projectDetails.readyForDivisionChefReview"
                :disabled="
                  !railroadCertificateAccess.update ||
                  projectDetails?.fedStatusDisabled
                "
                style="width: 10%"
              >
              </v-checkbox>
            </div>
            <span>Ready for Division Chief Review.</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9"></v-col>
        <v-col cols="3">
          <div>
            <PrimaryButton
              @buttonClicked="generateRailroadCertificate(false)"
              :buttonText="'Preview Railroad Certification'"
              :disabled="
                !previewRailroadAccess || projectDetails?.fedStatusDisabled
              "
            ></PrimaryButton>
          </div>
        </v-col>
        <v-col cols="9">
          <div class="w-100 d-flex justify-content-end">
            <PrimaryButton
              @buttonClicked="saveClicked()"
              :disabled="
                !railroadCertificateAccess.update ||
                !valueChanged ||
                (selectedConditionsLengthCheck &&
                  !projectDetails.reasonForConditional)
              "
              :buttonText="'Save'"
            ></PrimaryButton>
            <SecondaryButton
              style="margin-left: 10px"
              @buttonClicked="cancelClicked()"
              :disabled="!valueChanged || !railroadCertificateAccess.update"
              :buttonText="'Cancel'"
            ></SecondaryButton>
          </div>
        </v-col>
        <v-col cols="3">
          <div>
            <PrimaryButton
              @buttonClicked="generateRailroadCertificate(true)"
              :disabled="isGenerateRailroadCertificateDisabled"
              :buttonText="'Generate Railroad Certification'"
            ></PrimaryButton>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <div>
      <div class="env-header mb-20 mt-20">
        <h2 class="locationHeaderText">Railroad Certification Documents</h2>
      </div>
    </div>
    <v-grid>
      <v-row justify="center">
        <v-col cols="11">
          <DocumentsList
            :doctype="'/RC_VIEW_DT'"
            :id="id"
            :updateDocumentList="updateDocumentList"
            @updateDocumentListFlag="updateDocumentListFlag"
          ></DocumentsList>
        </v-col>
      </v-row>
    </v-grid>
    <div>
      <div class="env-header mb-20 mt-20">
        <h2 class="locationHeaderText">Status of Project Deliverables</h2>
      </div>
    </div>
    <v-row>
      <v-col class="page-title text-left">
        <div class="d-flex justify-content-end mb-20">
          <span class="expand-toggle" @click="expandToggle('expand')">
            Expand All
          </span>
          <span class="ml-10">|</span>
          <span
            class="expand-toggle ml-10 mr-10"
            @click="expandToggle('collapse')"
            >Collapse All</span
          >
        </div>
      </v-col>
    </v-row>
    <v-expansion-panels v-model="panel" multiple class="expansionPanelRC">
      <v-expansion-panel v-for="(panel, index) of panelList" :key="index">
        <v-expansion-panel-title class="pannelColor">
          <div class="panel-header">
            {{ panel?.title }}
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="w-100 d-flex justify-content-end align-items-center">
            <a
              v-if="panel?.linkText2"
              class="color-blue underline cursor-pointer text-right"
              @click="panel?.routeLink2 ? $router.push(panel?.routeLink2) : ''"
              style="margin-right: 10px"
              ><i
                class="fa fa-arrow-circle-right"
                style="
                  color: rgb(42, 87, 141);
                  font-size: 18px;
                  margin: 0px !important;
                  margin-right: 7px !important;
                "
              ></i
              >{{ panel?.linkText2 }}</a
            >
            <a
              v-if="panel?.linkText"
              class="color-blue underline cursor-pointer text-right"
              @click="panel?.routeLink ? $router.push(panel?.routeLink) : ''"
              ><i
                class="fa fa-arrow-circle-right"
                style="
                  color: rgb(42, 87, 141);
                  font-size: 18px;
                  margin: 0px !important;
                  margin-right: 7px !important;
                "
              ></i
              >{{ panel?.linkText }}</a
            >
          </div>
          <div
            class="messageRow mt-1 ml-20"
            v-if="
              !projectDetails?.pucReviewApprovalRequired && panel?.showWarning
            "
          >
            <span>
              <b>PUC Review/Approval not required</b>
            </span>
          </div>
          <v-table class="design-table">
            <thead>
              <tr>
                <th
                  :style="{ width: 100 / panel?.header?.length + '%' }"
                  :scope="column?.title"
                  v-for="column of panel?.header"
                  :key="column"
                >
                  <b> {{ column?.title }}</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                @click="panel?.rowClick ? rowClickNavigation(index, row) : ''"
                style="width: 100%"
                v-for="row of panel?.list"
                :key="row"
              >
                <td
                  :style="{ width: 100 / panel?.header?.length + '%' }"
                  v-for="column of panel?.header"
                  :key="column"
                >
                  <span v-if="!column?.isView && !column?.isLink">{{
                    row?.[column?.key]
                  }}</span>
                  <span v-if="column?.isLink">
                    <a class="color-blue underline">{{ row?.[column?.key] }}</a>
                  </span>
                  <span
                    class="d-flex align-items-center"
                    v-if="column?.isView && !row?.hideViewButton"
                    @click="download(row?.escDocumentId || row?.documentId)"
                  >
                    <i
                      class="fa fa-arrow-circle-right"
                      style="
                        color: rgb(42, 87, 141);
                        font-size: 18px;
                        margin: 8px;
                      "
                    ></i>
                    <b><span class="milestoneView">View</span></b>
                  </span>
                </td>
              </tr>
              <tr style="width: 100%" v-if="!panel?.list?.length">
                <td :colspan="panel?.header?.length" class="text-center">
                  No Data Available
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <DialogComponent ref="viewFundingDialogRef"></DialogComponent>
  <LoadingOverlay :isLoading="loading"></LoadingOverlay>
</template>
<script>
import RailroadCertification from "@/modules/manageproject/scripts/RailroadCertification.js";
export default RailroadCertification;
</script>
<style>
@import "@/assets/css/style.scss";
.expansionPanelRC {
  z-index: 0;
}
</style>