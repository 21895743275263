<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="800px"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text>
          <v-form v-model="isFormValid">
            <v-row>
              <v-col class="dialogLabeltext" cols="16">
                <label for="activity">Activity Name: </label>
                <label for="activity" class="asterisk">*</label>

                <v-text-field
                  id="activity"
                  v-model="formData.activity"
                  :rules="[requiredRule]"
                  maxlength="50"
                  outlined
                >
                </v-text-field>
              </v-col>

              <v-col class="dialogLabeltext flaggerStatus" cols="12">
                <label for="status">Status:</label>
                <label for="status" class="asterisk flagger">*</label>
                <v-col class="flaggerRadio">
                  <v-radio-group
                    id="status"
                    inline
                    v-model="formData.status"
                    :rules="[requiredRule]"
                  >
                    <v-radio id="Active" label="Active" value="Active" />
                    <v-radio id="Inactive" label="Inactive" value="Inactive" />
                  </v-radio-group>
                </v-col>
              </v-col>
            </v-row>
            <v-row class="warningRow" v-if="showWarning">
              <label for="activity-name" class="warningText"
                >Activity name "{{ tempName }}"" already exists.</label
              >
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :disabled="!formData.activity || !formData.status"
              :buttonText="'Save'"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import FlaggerActivityDialog from "@/modules/gcmsmain/admin/submenu/scripts/flaggerActivityDialog.js";
export default FlaggerActivityDialog;
</script>
<style>
@import "@/modules/cost-and-funding/assets/ScopingRailRoadCostsDialog.scss";
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
@import "@/modules/gcmsmain/admin/submenu/assets/flaggerActivityAdmin.scss";
</style>
