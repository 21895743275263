<template>
  <v-card-text>
    <projectFlaggerNeeds
      @refreshMpmsData="$emit('refreshMpmsData')"
    ></projectFlaggerNeeds>
  </v-card-text>
</template>
<script>
import projectFlaggerNeeds from "@/modules/manageproject/components/projectFlaggerNeeds.vue";
export default {
  name: "ViewProjectFlaggerNeeds-page",
  components: {
    projectFlaggerNeeds: projectFlaggerNeeds,
  },
};
</script>
