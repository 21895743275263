import {
  agreementLengthRule,
  arrayRequiredRule,
  railroadEstimateRule,
  requiredRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { PennDotImageDataURL } from "@/constants/ImageDataUrls";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent from "@/modules/manageproject/components/railroadEstimatesDocumentDialog.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import CurrencyInput from "@/modules/shared/components/CurrencyInput.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { useDocumentStore } from "@/stores/document";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import { useUserStore } from "@/stores/userStore";

import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";

export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    BannerMessage,
    CurrencyInput,
    DialogComponent,
    DocumentsList,
  },
  name: "EditRailroadEstimates",
  props: ["dotId"],
  setup(props) {
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    const projectDetails = ref({});
    const userStore = useUserStore();
    const user = computed(() => userStore.loggedInUser);
    const documentStore = useDocumentStore();
    const route = useRoute();
    const id = route.params.id;
    const cid = route.params.cid;
    let eid = route.params.eid;
    const banner = ref(null);
    let isFormValid = ref(false);
    const showSaveBannerMessage = ref(false);
    const showSubmitBannerMessage = ref(false);
    const showTotalErrorBannerMessage = ref(false);
    const showAgreementErrorBannerMessage = ref(false);
    const showAcceptErrorMessage = ref(false);
    const showAcceptErrorAgreementMessage = ref(false);

    const showAcceptBannerMessage = ref(false);
    const projectInvolvementData = ref(null);
    const railroadDetails = ref(null);
    const lookupData = ref({});
    const railroadList = ref([]);
    const billingType = ref(null);
    const agreementType = ref(null);
    const agreement = ref(null);
    const projectType = ref(null);
    const selectedRailroad = ref(null);
    const railroadMap = ref({});
    const selectedRailroadDetails = ref({});
    const estimatedCosts = ref({});
    const estimatedCosts2 = ref({});
    const totalMap = ref({});
    const totalEstimatedRailroadCosts = ref(null);
    const lessCredits = ref(null);
    const pennDOTShare = ref(null);
    const railroadShare = ref(null);
    const billingTypeMap = ref({});
    const agreementTypeMap = ref({});
    const formStatus = ref("Draft");
    const statusMap = ref({});
    const staticRailroad = ref(false);
    const loading = ref(false);
    const watcherCount = ref(0);
    const originalObject = ref({
      railroadDetails: null,
    });

    const railroadEstimatesDocumentsDialogRef = ref(null);
    const showSave = ref(false);

    const isUserEstimateAccess = ref({
      read: false,
      update: false,
      accept: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const acceptEstimateAccessCheck = async () => {
      let acceptResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.ACCEPT_ESTIMATE_BUTTON_PAGE_ACCESS
      );
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROADESTIMATES_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROADESTIMATES_PAGE_CRTUPD_ACCESS
      );

      isUserEstimateAccess.value.accept = acceptResponse || false;
      isUserEstimateAccess.value.read = readResponse || false;
      isUserEstimateAccess.value.update = updateResponse || false;


      loading.value = false;
    };

    onMounted(async () => {
      await acceptEstimateAccessCheck();
      fetchData();
      fetchEstimateData();
      getProjectData();
    });
    const valueChanged = ref(false);
    const originalValue = ref();
    const isInitialised = ref(false);

    watch(
      () => [
        selectedRailroad.value,
        billingType.value,
        agreement.value,
        agreementType.value,
        formStatus.value,
        estimatedCosts.value,
      ],
      () => {
        if (cid) {
          isInitialised.value = true;
        }

        if (
          isInitialised.value && cid
            ? selectedRailroad.value != originalValue.value?.name ||
              billingType.value != originalValue.value?.billingType ||
              agreement.value != originalValue.value?.agreement ||
              agreementType.value != originalValue.value?.agreementType ||
              formStatus.value != originalValue.value?.status
            : selectedRailroad.value ||
              billingType.value ||
              agreement.value ||
              agreementType.value ||
              formStatus.value
        ) {
          valueChanged.value = true;
         
        } else {
          valueChanged.value = false;
         
          updateWatcher();
        }
      },
      { deep: true }
    );

    const updateWatcher = () => {
      if (watcherCount.value > 1) {
        valueChanged.value = true;
      }
    };

    watch(
      () => [selectedRailroad.value],
      () => {
        if (selectedRailroad.value && isUserEstimateAccess.value.read) {
          fetchRailroad();
        }
      },
      { deep: true }
    );

    watch(
      () => [estimatedCosts.value],
      () => {
        if (watcherCount.value == 0) {
          watcherCount.value += 1;
        } else {
          watcherCount.value += 1;
          valueChanged.value = true;
        }
        if (!billingType.value) {
          billingType.value = "Cost estimate for Actual";
        }

        for (let index in estimatedCosts.value) {
          watchHelperFunction(index);
        }

        calculateLessCredits();
        calculateTotals();
      },
      { deep: true }
    );

    const watchHelperFunction = (index) => {
      let tempTotal = 0;
      if (Object.keys(estimatedCosts.value[index].valuePair) != 0) {
        for (let childIndex in estimatedCosts.value[index].valuePair) {
          let tempName =
            estimatedCosts.value[index].valuePair[childIndex].displayName;
          if (
            isFinite(tempName.split(" ")[0][0]) &&
            estimatedCosts.value[index].valuePair[childIndex].value
          ) {
            tempTotal += Number(
              estimatedCosts.value[index].valuePair[childIndex].value
            );
          }
          if (tempName == "Railroad Share") {
            railroadShare.value = Number(
              estimatedCosts.value[index].valuePair[childIndex].value
            );
          }
        }
      }
      if (Object.keys(estimatedCosts.value[index].valuePair) == 0) {
        tempTotal += Number(estimatedCosts.value[index].value);
      }
      totalMap.value[estimatedCosts.value[index].displayName] =
        formatter.format(tempTotal);
    };

    const createLookupMap = () => {
      for (let railroad in lookupData.value.railroads) {
        let temp = lookupData.value.railroads[railroad].railroadCompanyName;
        railroadList.value.push(temp);
        railroadMap.value[temp] =
          lookupData.value.railroads[railroad].railroadCompanyId;
      }
      if (railroadList.value.length == 1) {
        selectedRailroad.value = railroadList.value[0];
        staticRailroad.value = true;
      }

      for (let index in lookupData.value.billingTypes) {
        billingTypeMap.value[lookupData.value.billingTypes[index].billingType] =
          lookupData.value.billingTypes[index].gpcCode;
      }

      createLookupMapHelperFunction();
    };

    const createLookupMapHelperFunction = () => {
      for (let index in lookupData.value.agreementTypes) {
        agreementTypeMap.value[
          lookupData.value.agreementTypes[index].agreementType
        ] = lookupData.value.agreementTypes[index].gpcCode;
      }

      for (let index in lookupData.value.projectTypes) {
        if (lookupData.value.projectTypes[index].value) {
          projectType.value = lookupData.value.projectTypes[index].projectType;
        }
      }

      for (let index in lookupData.value.statusTypes) {
        statusMap.value[lookupData.value.statusTypes[index].statusType] =
          lookupData.value.statusTypes[index].gpcCode;
      }
    };

    function fetchData() {
      axios
        .get(CONFIG_ENDPOINT.RAILROAD_FORCE_ACCOUNT_ESTIMATE_LOOKUP + id)
        .then((response) => {
          lookupData.value = response.data;

          createLookupMap();

          if (eid == 0) {
            estimatedCosts.value = response.data.estimatedCosts.valuePair;
            createTotalMap();
          }
        })
        .catch((err) => {
          console.log(err);
        });

      if (cid) {
        fetchDataEdit();
      }
    }

    function fetchDataEdit() {
      axios
        .get(
          CONFIG_ENDPOINT.RAILROAD_FORCE_ACCOUNT_ESTIMATE_EDIT_RAILROAD +
            id +
            "/" +
            cid +
            "/" +
            eid
        )
        .then((response) => {
          selectedRailroadDetails.value = response.data.railroadDetails;
          selectedRailroad.value = selectedRailroadDetails.value.name;
          billingType.value = selectedRailroadDetails.value.billingType;
          agreement.value = selectedRailroadDetails.value.agreement;
          agreementType.value = selectedRailroadDetails.value.agreementType;
          formStatus.value = selectedRailroadDetails.value.status;
          estimatedCosts.value =
            response.data.railroadDetails.estimatedCosts.valuePair;
          originalValue.value = JSON.parse(
            JSON.stringify(response?.data?.railroadDetails)
          );
          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);

          createTotalMap();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        next();
      }
    });
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    function fetchRailroad() {
      let railroadId = railroadMap.value[selectedRailroad.value];

      axios
        .get(
          CONFIG_ENDPOINT.RAILROAD_FORCE_ACCOUNT_ESTIMATE_GET_RAILROAD +
            railroadId
        )
        .then((response) => {
          selectedRailroadDetails.value = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    const createTotalMap = () => {
      for (let index in estimatedCosts.value) {
        if (Object.keys(estimatedCosts.value[index].valuePair) != 0) {
          addChildToTotalMap(index);
        } else {
          totalMap.value[estimatedCosts.value[index].displayName] =
            formatter.format(estimatedCosts.value[index].value);
        }
      }

      calculateLessCredits();
      calculateTotals();
    };

    const addChildToTotalMap = (index) => {
      for (let childIndex in estimatedCosts.value[index].valuePair) {
        let tempName =
          estimatedCosts.value[index].valuePair[childIndex].displayName;
        if (tempName == "Total Estimated Railroad Costs") {
          totalEstimatedRailroadCosts.value = 0;
        } else if (tempName.split(" ")[0] == "Total") {
          totalMap.value[estimatedCosts.value[index].displayName] =
            formatter.format(
              estimatedCosts.value[index].valuePair[childIndex].value
            );
        } else if (tempName == "PennDOT Share") {
          pennDOTShare.value = estimatedCosts.value[index].valuePair[childIndex]
            .value
            ? null
            : 0;
        } else if (tempName == "Railroad Share") {
          railroadShare.value =
            estimatedCosts.value[index].valuePair[childIndex].value;
        }
      }
    };

    const calculateTotals = () => {
      let tempTotal = 0;
      Object.keys(totalMap.value).forEach((key) => {
        if (!key.includes("TOTAL RAILROAD REIMBURSEMENT")) {
          tempTotal += Number(totalMap.value[key].replace(/[^0-9.]+/g, ""));
        }
      });

      let tempFullValue = tempTotal - lessCredits.value;
      let tempPennDOTShare = tempFullValue - railroadShare.value;

      pennDOTShare.value = formatter.format(tempPennDOTShare);
      totalEstimatedRailroadCosts.value = formatter.format(tempFullValue);
    };

    const calculateLessCredits = () => {
      let tempTotal = 0;
      for (let index in estimatedCosts.value) {
        if (Object.keys(estimatedCosts.value[index].valuePair) != 0) {
          for (let childIndex in estimatedCosts.value[index].valuePair) {
            if (
              estimatedCosts.value[index].valuePair[childIndex].valuePair != 0
            ) {
              for (let grandChildIndex in estimatedCosts.value[index].valuePair[
                childIndex
              ].valuePair) {
                tempTotal += Number(
                  estimatedCosts.value[index].valuePair[childIndex].valuePair[
                    grandChildIndex
                  ].value
                );
              }
            }
          }
        }
      }
      lessCredits.value = tempTotal;
    };

    const cancelSave = () => {
      isInitialised.value = false;
      valueChanged.value = false;
      fetchData();
      window.scrollTo(0, 0);
    };

    const hideBanner = () => {
      showSaveBannerMessage.value = false;
      showSubmitBannerMessage.value = false;
      showTotalErrorBannerMessage.value = false;
      showAgreementErrorBannerMessage.value = false;
      showAcceptBannerMessage.value = false;
      showAcceptErrorMessage.value = false;
      showAcceptErrorAgreementMessage.value = false;
    };

    const setPennDotShare = (index) => {
      if (
        estimatedCosts.value[index].displayName.split(" ")[3] == "REIMBURSEMENT"
      ) {
        estimatedCosts.value[index].value = pennDOTShare.value;
      }
    };

    const insertTotalsForPost = () => {
      for (let index in estimatedCosts.value) {
        if (Object.keys(estimatedCosts.value[index].valuePair) != 0) {
          insertTotalsForPostHelperFunction(index);
        } else {
          setPennDotShare(index);
        }
      }

      if (!selectedRailroadDetails?.value?.estimatedCosts) {
        let tempValuePair = {
          valuePair: null,
        };
        selectedRailroadDetails.value.estimatedCosts = tempValuePair;
      }

      selectedRailroadDetails.value.billingType =
        billingTypeMap.value[billingType.value];
      selectedRailroadDetails.value.agreementType =
        agreementTypeMap.value[agreementType.value];
      selectedRailroadDetails.value.agreement = agreement.value;

      selectedRailroadDetails.value.estimatedCosts.valuePair =
        estimatedCosts.value;
      originalObject.value.railroadDetails = selectedRailroadDetails.value;

      if (formStatus.value == "Accepted") {
        originalObject.value.railroadDetails.status = "RG_DRAFT";
      } else {
        originalObject.value.railroadDetails.status =
          statusMap.value[formStatus.value];
      }
    };

    const insertTotalsForPostHelperFunction = (index) => {
      for (let childIndex in estimatedCosts.value[index].valuePair) {
        let tempName =
          estimatedCosts.value[index].valuePair[childIndex].displayName;
        if (tempName == "Total Estimated Railroad Costs") {
          estimatedCosts.value[index].valuePair[childIndex].value =
            totalEstimatedRailroadCosts.value;
        } else if (tempName == "PennDOT Share") {
          estimatedCosts.value[index].valuePair[childIndex].value =
            pennDOTShare.value;
        } else if (tempName.split(" ")[0] == "Total") {
          estimatedCosts.value[index].valuePair[childIndex].value =
            totalMap.value[estimatedCosts.value[index].displayName];
        } else if (estimatedCosts.value[index].valuePair[childIndex].value) {
          estimatedCosts.value[index].valuePair[childIndex].value =
            estimatedCosts.value[index].valuePair[childIndex].value.toString();
        }
      }
    };

    const postAccept = () => {
      axios
        .post(
          CONFIG_ENDPOINT.ACCEPT_ESTIMATE_POST +
            id +
            "/" +
            originalObject.value.railroadDetails.railroadCompanyId +
            "/" +
            eid,
          originalObject.value
        )
        .then((response) => {
          formStatus.value = "Accepted";
          showAcceptBannerMessage.value = true;
          fetchEstimateData();
          scroll(0, 0);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const postObject = (status) => {
      if (status == "Accept") {
        postAccept();
      } else {
        axios
          .post(
            CONFIG_ENDPOINT.RAILROAD_FORCE_ACCOUNT_ESTIMATE_EDIT_RAILROAD +
              id +
              "/" +
              originalObject.value.railroadDetails.railroadCompanyId + 
              "/" +
              eid,
            originalObject.value
          )
          .then((response) => {
            hideBanner();
            if (eid == 0) {
              eid = response.data.railroadEstimateId;
             
            }
            if (status == "Submit") {
              formStatus.value = "Submitted";
              showAcceptBannerMessage.value = true;
            } else if (status == "Save") {
              formStatus.value = "Draft";
              showSaveBannerMessage.value = true;
            } else if (status == "Accept") {
              formStatus.value = "Accepted";
              showAcceptBannerMessage.value = true;
              fetchEstimateData();
            }
            window.scrollTo(0, 0);
            isInitialised.value = false;
            valueChanged.value = false;
          })
          .catch((err) => {
            console.log(err);
            if (err) {
              hideBanner();
              showAgreementErrorBannerMessage.value = true;
              scroll(0, 0);
            }
          });
      }
    };

    const saveClicked = () => {
      insertTotalsForPost();
      postObject("Save");
    };

    const submitClicked = () => {
      if (
        totalEstimatedRailroadCosts.value == "$0.00" ||
        totalEstimatedRailroadCosts.value == 0
      ) {
        hideBanner();
        showTotalErrorBannerMessage.value = true;
        scroll(0, 0);
      } else {
        formStatus.value = "Submitted";
        insertTotalsForPost();
        postObject("Submit");
        isInitialised.value = false;
        valueChanged.value = false;
      }
    };

    function getProjectData() {
      axios
        .get(
          CONFIG_ENDPOINT.GCMS_MAIN_PROJECT + id + "?userID=" + user.value.sub
        )
        .then((response) => {
          projectDetails.value = response.data;
        })
        .catch(() => {
          projectDetails.value = null;
        });
    }

    const acceptClicked = () => {
      if (
        totalEstimatedRailroadCosts.value == "$0.00" ||
        totalEstimatedRailroadCosts.value == 0
      ) {
        hideBanner();
        showAcceptErrorMessage.value = true;
        scroll(0, 0);
      } else if (!agreement.value) {
        hideBanner();
        showAcceptErrorAgreementMessage.value = true;
        scroll(0, 0);
      } else {
        hideBanner();
        insertTotalsForPost();
        originalObject.value.railroadDetails.status = "RG_ACCEPT";
        postObject("Accept");
        GeneratePDF();
      }
    };

    const GenerateHTML = async () => {



      estimatedCosts2.value = JSON.parse(JSON.stringify(estimatedCosts.value));

      console.log(estimatedCosts2.value);


      for (let childIndex in estimatedCosts2.value) {

        if (Object.keys(estimatedCosts2.value[childIndex].valuePair).length !== 0) {

          if (!estimatedCosts2.value[childIndex].displayName.includes("TOTAL PROJECT COSTS")){
            estimatedCosts2.value[childIndex].valuePair['Less Credits'].valuePair['1. Salvage'].value = formatter.format(estimatedCosts2.value[childIndex]?.valuePair['Less Credits']?.valuePair['1. Salvage'].value);
            estimatedCosts2.value[childIndex].valuePair['Less Credits'].valuePair['2. Miscellaneous'].value = formatter.format(estimatedCosts2.value[childIndex]?.valuePair['Less Credits']?.valuePair['2. Miscellaneous'].value);
          } else{
            estimatedCosts2.value[childIndex].valuePair['Railroad Share'].value = formatter.format(estimatedCosts2.value[childIndex].valuePair['Railroad Share'].value);

          }

        

        }
     
       if (!estimatedCosts2.value[childIndex].value){
        console.log(
          'should be in here'
        )
        estimatedCosts2.value[childIndex].value = "$0.00"
       }
       else if(estimatedCosts2.value[childIndex].displayName != "K. TOTAL RAILROAD REIMBURSEMENT") {
        estimatedCosts2.value[childIndex].value = formatter.format(estimatedCosts2.value[childIndex].value)
       }
      }

      console.log(estimatedCosts2.value);

      return;
    }


    const GeneratePDF = async () => {

      await GenerateHTML();

      
   
      let payload = {
        mpmsNumber: id,
        railroadCompanyCode: selectedRailroadDetails.value.railroadCompanyCode,
        railroadCompanyId: selectedRailroadDetails.value.railroadCompanyId,
        fileData: null,
        metaData: {
          docType: "Force Account Estimate",
          filename: null,
          description: null,
          mpmsNumber: id,
          railRoadName: selectedRailroad.value,
        },
        userid: userData.value?.userID,
        password: userData.value?.userID,
        htmlContent: document.getElementById("pdf").outerHTML,
      };

      postPdf(payload);
    };

    const postPdf = (payload) => {
      axios
        .post(
          CONFIG_ENDPOINT.SEND_FORCE_ACCOUNT_ESTIMATE,
          payload,

          {
            headers: {
              userid: userData.value?.userID,
              password: userData.value?.userID,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          fetchEstimateData();
        })
        .catch((err) => {
          loading.value = false;
        });
    };

    const detailMaxLength = computed({
      get: () => {
        return 500;
      },
    });

    /* Estimat Documents */
    const filesList = ref([]);
    const otherButtonClicked = () => {
      openDialog(false);
    };
    const updateButtonClicked = (event) => {
      openDialog(true, event);
    };
    const formData = ref([]);
    const updateDocumentList = ref(false);
    const openDialog = (isUpdate, event) => {
      formData.value = [];
      railroadEstimatesDocumentsDialogRef.value
        .open(
          isUpdate
            ? "Update Railroad Estimate Document"
            : "View/Upload Railroad Estimate Documents",
          id,
          selectedRailroad.value,
          isUpdate,
          event
        )
        .then((resolve) => {
          if (resolve) {
            // refresh data of required
            fetchEstimateData();
          }
        });
    };
    async function fetchEstimateData() {
      updateDocumentList.value = true;
    }
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };

    return {
      id,
      banner,
      arrayRequiredRule,
      requiredRule,
      saveClicked,
      detailMaxLength,
      isFormValid,
      hideBanner,
      showSaveBannerMessage,
      cancelSave,
      route,
      projectInvolvementData,
      railroadDetails,
      lookupData,
      railroadList,
      billingType,
      projectType,
      selectedRailroad,
      fetchRailroad,
      railroadMap,
      selectedRailroadDetails,
      estimatedCosts,
      totalMap,
      totalEstimatedRailroadCosts,
      calculateTotals,
      formatter,
      lessCredits,
      pennDOTShare,
      calculateLessCredits,
      railroadShare,
      addChildToTotalMap,
      watchHelperFunction,
      insertTotalsForPost,
      billingTypeMap,
      agreementTypeMap,
      showTotalErrorBannerMessage,
      showSubmitBannerMessage,
      submitClicked,
      postObject,
      insertTotalsForPostHelperFunction,
      formStatus,
      setPennDotShare,
      agreement,
      documentStore,
      agreementType,
      statusMap,
      showAgreementErrorBannerMessage,
      agreementLengthRule,
      otherButtonClicked,
      railroadEstimatesDocumentsDialogRef,
      filesList,
      fetchData,
      updateDocumentListFlag,
      updateDocumentList,
      staticRailroad,
      acceptClicked,
      showAcceptBannerMessage,
      showAcceptErrorMessage,
      showAcceptErrorAgreementMessage,
      loading,
      getProjectData,
      projectDetails,
      showSave,
      valueChanged,
      watcherCount,
      PennDotImageDataURL,
      railroadEstimateRule,
      updateButtonClicked,
      isUserEstimateAccess,
      estimatedCosts2,
    };
  },
};
