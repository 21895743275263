import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";
import { CONFIG_ENDPOINT } from "../constants/Endpoints";
export const useProjectTeamStore = defineStore("projectTeam", () => {
  const LookupData = ref({
    projectManager: [],
    projectDesigner: [],
    pucApplicant: [],
    gradeCrossingCoordinator: [],
    districtAgreementCoordinator: [],
    centralOfficeCoordinator: [],
    constructionCoordinator: [],
    closeoutCoordinator: [],
  });

  const getLookup = async (project , mpms) => {
    try {
      const response = await axios.get(
        CONFIG_ENDPOINT.GET_PROJECT_TEAM + "/" + project + "/" + mpms
      );
      LookupData.value[project] = response.data;
      return LookupData.value[project];
    } catch (error) {
      LookupData.value[project] = [];
      return LookupData.value[project];
    }
  };
  return {
    LookupData,
    getLookup,
  };
});
