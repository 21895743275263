<template>
  <v-dialog persistent v-model="dialog" max-width="900px" max-height="3000px">
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          Move from {{ status }} to {{ nextStatus }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 450px; overflow-y: scroll">
          <div class="info-message">
            <span>
              In order to complete {{ status }} status, the following conditions
              must be met:
            </span>
          </div>
          <v-list>
            <v-list-item
              class="text-left"
              v-for="info in lookupData"
              :key="info.actionRule"
            >
              <i class="headerIcon fa fa-arrow-circle-right"></i>
              <span v-if="!info.actionLink" class="underlined-text">
                {{ info.actionRule }}
              </span>
              <router-link
                v-else
                :to="info.actionLink"
                @click.prevent="navigateAndClose(info.actionLink)"
                class="underlined-text link-text"
              >
                {{ info.actionRule }}
              </router-link>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import StatusChangeInfoChangeDialog from "@/modules/project/scripts/StatusChangeInfoChangeDialog.js";
export default StatusChangeInfoChangeDialog;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
.district-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.district-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.info-message {
  display: flex;
  align-items: right;
  font-size: 16px;
  margin-bottom: 10px;
  color: #07136c;
}
.info-icon {
  color: #003e7e;
  font-size: 18px;
  margin-right: 5px;
}
.info-text {
  color: #003e7e;
}
.underlined-text {
  text-decoration: underline;
  color: #003e7e;
  cursor: default;
}
.link-text {
  cursor: pointer;
}
</style>