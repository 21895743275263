<template>
  <v-card-text>
    <ProjectHistory
      @refreshMpmsData="$emit('refreshMpmsData')"
    ></ProjectHistory>
  </v-card-text>
</template>
  <script>
import ProjectHistory from "@/modules/manageproject/components/ProjectHistory.vue";

export default {
  name: "ViewProjectHistory-page",
  components: {
    ProjectHistory: ProjectHistory,
  },
};
</script>
  