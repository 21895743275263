<template>
  <v-card-text>
    <projectWorkflowNotifications
      @BannerUpdate="$emit('BannerUpdate', $event)"
      @refreshMpmsData="$emit('refreshMpmsData')"
    ></projectWorkflowNotifications>
  </v-card-text>
</template>
  <script>
import projectWorkflowNotifications from "@/modules/manageproject/components/projectWorkflowNotifications.vue";

export default {
  name: "ViewProjectWorkflowNotifications-page",
  components: {
    projectWorkflowNotifications: projectWorkflowNotifications,
  },
};
</script>