<template>
  <v-card-text>
    <PartiesOfRecord
      @refreshMpmsData="$emit('refreshMpmsData')"
    ></PartiesOfRecord>
  </v-card-text>
</template>
  <script>
import PartiesOfRecord from "@/modules/manageproject/components/PartiesOfRecord.vue";

export default {
  name: "ViewPartiesOfRecord-page",
  components: {
    PartiesOfRecord: PartiesOfRecord,
  },
};
</script>