<template>
  <v-card-text>
    <RailroadPlanReview
      @BannerUpdate="$emit('BannerUpdate', $event)"
      @refreshMpmsData="$emit('refreshMpmsData')"
    ></RailroadPlanReview>
  </v-card-text>
</template>
  <script>
import RailroadPlanReview from "@/modules/manageproject/components/RailroadPlanReview.vue";

export default {
  name: "ViewRailroadPlanReview-page",
  components: {
    RailroadPlanReview: RailroadPlanReview,
  },
};
</script>