import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import {
  DROPDOWN_NO_DATA,
  TABLE_NO_DATA_AVAILABLE,
} from "@/constants/displayTexts";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import IconButton from "@/modules/shared/components/IconButton.vue";
import ProjectIdentifierLegend from "@/modules/shared/components/ProjectIdentifierLegend.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";
import axios from "axios";
import moment from "moment";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { MAX_DATE } from "../../../constants/dates";
export default {
  name: "ExpandedTable",
  components: {
    VueTable,
    IconButton,
    SecondaryButton,
    DatePicker,
    ProjectIdentifierLegend,
  },

  props: {
    pageNumber: { default: 1, type: Number },
    headers: { type: Array, default: () => [] },
    detailsHeader: { type: Array, default: () => [] },
    detailTableDataList: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    showClearFilterButton: { type: Boolean, default: false },
    showExportButton: { type: Boolean, default: false },
    showExportDetailsButton: { type: Boolean, default: false },
    tableHeader: { type: String, default: "" },
    tableDescription: { type: String, default: "" },
    routerText: { type: String, default: "" },
    hideFilter: { type: Boolean, default: false },
    hidePagination: { type: Boolean, default: false },
    linkedPagination: { type: Boolean, default: false },
    itemsPerPage: { type: Number, default: 10 },
    totalItems: { type: Number, default: 10 },
    clickable: { type: Boolean, default: false },
    isGradeInventory: { type: Boolean, default: false },
    isCreateProject: { type: Boolean, default: false },
    isGradeCrossing: { type: Boolean, default: false },
    navigateToMpmsDetails: { type: Boolean, default: false },
    navigationKey: { type: String, default: "" },
    exportFilName: { type: String, default: "" },
    detailExportFilName: { type: String, default: "" },
    lookupData: { default: null },
    oldMultipleFilter: { default: null },
    expanded: { type: Array, default: () => [] },
    subHeaders: { type: Array, default: () => [] },
    subTableKey: { type: String, default: "" },
    selectedRailroad: { type: String, default: "" },
    expandKey: { type: String, default: "mpmsNumber" },
    subTableNoDataFound: { type: String, default: TABLE_NO_DATA_AVAILABLE },
    showItalics: { type: Boolean, default: false },
    resetActiveKey: { type: Boolean, default: false },
    showLegends: { type: Boolean, default: false },
    passBlankAsNull: { type: Boolean, default: false },
    isFixedHeaderWithScroll: { type: Boolean, default: false },
    reduceSubTableWidth: { type: Boolean, default: false },
  },
  watch: {
    sortBy(value) {
      this.updateSort(value);
    },
    totalItems(value) {
      this.updateTotalItems(value);
    },
    items(list) {
      this.updateLoading(false);
      this.updateItems(list);
    },
    pageNumber(newPage) {
      this.pageChangeLinked(newPage);
    },
    itemsPerPage() {
      this.itemPerPageChange();
    },
    expanded() {
      this.updateExpandedList();
    },
  },
  emits: [
    "updateResetActiveKeyFlag",
    "linkClicked",
    "pageChange",
    "updatePaginationPerPage",
    "sortUpdate",
    "multipleFiltersChange",
    "textFilterChange",
    "clearAllFilter",
    "clickHeaderButton",
    "dateChange",
  ],
  setup(props, { emit }) {
    const router = useRouter();
    const expanded = ref([]);
    let subHeaders = ref(props?.subHeaders);
    let subTableKey = ref(props?.subTableKey);
    let subTableNoDataFound = ref(props?.subTableNoDataFound);
    let items = ref(props?.items || []);
    let itemsPerPage = ref(props.itemsPerPage);
    let totalItems = ref(props.totalItems || 0);
    let pageNumber = ref(1);
    let jumpPage = ref(1);
    let sortBy = ref([]);
    let page = ref(1);
    let multiSearch = ref("");
    let search = ref("");
    let filteredHeaders = ref([]);
    let headerprops = ref({
      "sort-icon": "mdi-unfold-more-horizontal",
    });
    let selectedItem = ref(false);
    let viewAllProjectRoute = ref(CONFIG_ENDPOINT.GCMS_VIEW_ALL_PROJECTS);
    let linkedPagination = ref(props?.linkedPagination || false);
    let exportFilName = ref(props?.exportFilName);
    let detailExportFilName = ref(props?.detailExportFilName);
    let loading = ref(true);
    let lookupData = ref(props?.lookupData);
    let multipleFilterValue = ref(null);
    let expandKey = ref(props.expandKey);
    let showItalics = ref(props?.showItalics);
    let passBlankAsNull = ref(props?.passBlankAsNull);
    let isFixedHeaderWithScroll = ref(props?.isFixedHeaderWithScroll);
    let reduceSubTableWidth = ref(props?.reduceSubTableWidth);

    let filteredData = computed(() => {
      let filteredArray = items.value;
      return filteredArray;
    });
    let focusTextKey = ref(null);
    let appliedMultipleFilters = ref(false);
    let showLegends = ref(props?.showLegends);

    const filteredDataLength = computed(() => filteredData.value?.length);
    const getPaginationLength = computed(() =>
      Math.ceil(filteredDataLength.value / itemsPerPage.value)
    );
    watch(
      () => [props],
      () => {
        if (props.resetActiveKey) {
          focusTextKey.value = null;
          emit("updateResetActiveKeyFlag", false);
        }
        if (props?.showLegends) {
          showLegends.value = props?.showLegends;
        }
      },
      { deep: true }
    );

    const createRouter = (item) => {
      selectedItem.value = item;

      if (props?.navigateToMpmsDetails) {
        window.scrollTo(0, 0);
        router.push({
          path: `/GCMS/${selectedItem.value[props?.navigationKey]}`,
        });
      }
      if (props?.isGradeInventory) {
        window.scrollTo(0, 0);
        router.push({
          path: `/GCMS/DOT/${selectedItem.value.dotnumber}`,
        });
      }
      if (props?.isGradeCrossing) {
        window.scrollTo(0, 0);
        router.push({
          path: `/GCMS/${selectedItem.value.mpms}`,
        });
      }
      if (props?.isCreateProject) {
        axios
          .get(
            CONFIG_ENDPOINT.PROJECT_INFO_INFO +
              selectedItem.value.mpmsNumber +
              "?createGradeCrossing=true"
          )
          .then((response) => {
            if (response?.data?.mpmsNumber) {
              window.scrollTo(0, 0);
              router.push({
                path: `/GCMS/createProject/${selectedItem.value.mpmsNumber}`,
              });
            }
          });
      }
    };
    const exportDetailsList = (isDetails) => {
      if (isDetails) {
        exportList(props?.detailsHeader, props?.detailTableDataList, true);
      } else {
        exportList(props?.headers, filteredData.value, false);
      }
    };
    const exportList = (headers, dataList, isDetail) => {
      const col = [];
      const column = [];
      const filterColumn = [];
      headers?.forEach((header) => {
        if (header.key != "data-table-expand") {
          column.push(header.key);
          col.push(getExportHeaderName(header.key, headers));
          if (header.filterType === "multi-select" && header.filter?.length) {
            filterColumn.push({
              label: header?.title,
              value: header.filter.join(", "),
            });
          } else if (header.filterType === "textbox" && header?.filter) {
            filterColumn.push({ label: header?.title, value: header.filter });
          }
        }
      });
      let csvContent = ["Selected Railroad: " + props?.selectedRailroad] + "\n";
      if (filterColumn.length) {
        let filterValue = "";
        filterColumn.forEach((filter) => {
          filterValue += filter.label + ": " + filter.value + "; ";
        });
        csvContent +=
          '"' + filterValue?.toString().replace(/"/g, '""') + '"' + "\n" + "\n";
      } else {
        csvContent += "\n";
      }
      csvContent += col.join(",") + "\n";

      downloadFile(column, csvContent, dataList, isDetail);
    };
    const downloadFile = (column, csvContent, dataList, isDetail) => {
      dataList?.forEach((data) => {
        let array = [];
        column?.forEach((key) => {
          if (key != "data-table-expand") {
            let value = data[key];
            value = value?.toString();
            if (key?.toLowerCase()?.includes("district")) {
              value = '=""' + value + '""';
            }
            array.push(`"${value || ""}"`);
          }
        });
        csvContent += array.join(",") + "\n";
      });
      const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          isDetail ? props?.detailExportFilName : props?.exportFilName + ".csv"
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        loading.value = false;
      }
    };

    const getExportHeaderName = (header, headersList) => {
      let heading = "";
      headersList?.forEach((tableHeader) => {
        if (tableHeader.key === header) {
          heading = tableHeader.title;
        }
      });
      return heading;
    };

    const linkClicked = (key, value) => {
      updateLoading(true);
      emit("linkClicked", { key: key, value: value });
    };

    const pageChange = (event) => {
      jumpPage.value = event;
    };

    const itemPerPageChange = () => {
      jumpPage.value = 1;
      pageNumber.value = 1;
    };

    const getPaginationLengthLinked = computed(() => {
      return Math.ceil(totalItems.value / itemsPerPage.value);
    });
    const updateItems = (list) => {
      items.value = list;
    };

    const pageChangeLinked = (event) => {
      jumpPage.value = event;
      updateLoading(true);
      emit("pageChange", event);
    };
    const updatePaginationPerPage = (event) => {
      if (event) {
        itemsPerPage.value = event;
        updateLoading(true);
        emit("updatePaginationPerPage", event);
      }
    };

    const jumpToPageLinkedPagination = (event) => {
      let pageValue = Number(event);
      if (
        pageValue &&
        pageValue <= Math.ceil(totalItems.value / itemsPerPage.value)
      ) {
        pageNumber.value = pageValue;
        updateLoading(true);
      }
    };
    const updateSort = (event) => {
      updateSort.value = event;
      updateLoading(true);
      emit("sortUpdate", event);
    };
    const updateTotalItems = (value) => {
      totalItems.value = value;
    };
    const selectItem = (item) => {
      if (props.clickable.value) {
        selectedItem.value = item;
      }
    };
    const getItems = (key, sortType) => {
      let unique = [...new Set(props?.lookupData?.[key])];
      if (key === "letDate" || key?.toLowerCase()?.includes("date")) {
        return getDateBasedFilterItems(key);
      }

      if (key?.toLowerCase()?.includes("district")) {
        unique = sortFilterItems(unique, "district");
      } else {
        unique = sortFilterItems(unique, sortType);
      }
      let hasBlankFilter = false;
      let blankIndex = -1;
      unique = unique.map((item, index) => {
        if (passBlankAsNull && item === "null") {
          hasBlankFilter = true;
          blankIndex = index;
        }
        return {
          label:
            passBlankAsNull && item === "null" ? "(Blank)" : item || "(Blank)",
          value: item,
        };
      });
      if (hasBlankFilter && blankIndex >= 0) {
        unique.splice(blankIndex, 1);
        unique.unshift({
          label: "(Blank)",
          value: "null",
        });
      }
      return unique;
    };
    const getDateBasedFilterItems = (key) => {
      let data = props?.lookupData?.[key]
        ?.sort((a, b) => {
          if (new Date(a).getTime() < new Date(b).getTime()) return -1;
          if (new Date(a).getTime() > new Date(b).getTime()) return 1;
          return 0;
        })
        ?.map((item) => formatToYearMonth(item))
        .filter((value, index, self) => self.indexOf(value) === index);
      data = data?.map((item) => {
        let updatedDate = null;
        if (item) {
          updatedDate = item?.replace(
            item.split("-")[1],
            getMonthNumber(item.split("-")[1])
          );
          updatedDate =
            updatedDate?.split("-")[1] + "" + updatedDate?.split("-")[0];
        }
        return {
          label:
            item && new Date(item) != "Invalid Date"
              ? formatToYearMonth(item)
              : "(Blank)",
          value: updatedDate,
        };
      });
      return data;
    };
    const formatToYearMonth = (dateString) => {
      if (dateString) {
        let date = new Date(dateString);
        let month = date.toLocaleString("default", { month: "long" });
        let year = date.getFullYear();
        return `${year}-${month}`;
      }
      return dateString;
    };
    const getProjectIdentifierList = (key, sortType) => {
      let identifierList = [];
      items.value?.forEach((item) => {
        item[key]?.forEach((identifier) => {
          identifierList.push(identifier);
        });
      });
      let unique = [...new Set(identifierList?.map((item) => item))];
      unique = sortFilterItems(unique, sortType);
      unique = unique.map((item) => {
        return { label: item || "(Blank)", value: item };
      });
      return unique;
    };
    const sortFilterItems = (list, type) => {
      if (type == "number") {
        return sortNumber(list);
      } else if (type == "date") {
        return sortDate(list);
      } else if (type === "district") {
        return sortDistrict(list);
      } else {
        return sort(list);
      }
    };

    const sort = (list) => {
      return list?.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    };

    const sortDate = (list) => {
      return list?.sort((a, b) => {
        if (new Date(a).getTime() < new Date(b).getTime()) return -1;
        if (new Date(a).getTime() > new Date(b).getTime()) return 1;
        return 0;
      });
    };

    const sortNumber = (list) => {
      return list?.sort((a, b) => {
        if (Number(a) < Number(b)) return -1;
        if (Number(a) > Number(b)) return 1;
        return 0;
      });
    };

    const resetOldFilter = (key, index, isClear) => {
      if (!appliedMultipleFilters.value && !isClear) {
        let oldFilter = props?.oldMultipleFilter?.length
          ? props?.oldMultipleFilter?.filter((header) => {
              return header?.key === key;
            })?.[0]?.value
          : [];
        props.headers[index].filter = oldFilter || [];
      }
      if (isClear) {
        props.headers[index].filter = [];
        filterValues([], key);
        updateMultipleFilter(key);
      }
    };

    const updateMultipleFilter = (key) => {
      let sortedMultipleFilter =
        multipleFilterValue.value && key === multipleFilterValue?.value?.key
          ? multipleFilterValue.value
          : null;
      let oldFilter = props?.oldMultipleFilter?.length
        ? props?.oldMultipleFilter?.filter((header) => {
            return header?.key === key;
          })?.[0]?.value
        : null;
      if (
        key === multipleFilterValue?.value?.key &&
        !arraysEqual(oldFilter, sortedMultipleFilter?.value)
      ) {
        updateLoading(true);
        appliedMultipleFilters.value = true;
        emit("multipleFiltersChange", multipleFilterValue.value);
      }
    };
    const arraysEqual = (a, b) => {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
      a = a.sort((a, b) => a.localeCompare(b));
      b = b.sort((a, b) => a.localeCompare(b));

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    };

    const sortDistrict = (list) => {
      return list?.sort((first, second) => {
        let a = first?.split("-")?.[0];
        let b = second?.split("-")?.[0];
        if (Number(a) < Number(b)) return -1;
        if (Number(a) > Number(b)) return 1;
        return 0;
      });
    };

    const filterValues = (event, key, filterType) => {
      focusTextKey.value = key;
      multipleFilterValue.value = {
        value: event,
        key: key,
      };
      appliedMultipleFilters.value = false;
      if (filterType && filterType === "single-auto-complete") {
        updateMultipleFilter(key);
      }
    };
    const getMonthNumber = (month) => {
      let selectedMonth = month.toLowerCase();
      let monthNumber = "";
      switch (selectedMonth) {
        case "january":
          monthNumber = "01";
          break;
        case "february":
          monthNumber = "02";
          break;
        case "march":
          monthNumber = "03";
          break;
        case "april":
          monthNumber = "04";
          break;
        case "may":
          monthNumber = "05";
          break;
        case "june":
          monthNumber = "06";
          break;
        case "july":
          monthNumber = "07";
          break;
        case "august":
          monthNumber = "08";
          break;
        case "september":
          monthNumber = "09";
          break;
        case "october":
          monthNumber = "10";
          break;
        case "november":
          monthNumber = "11";
          break;
        case "december":
          monthNumber = "12";
          break;
        default:
          monthNumber = "0";
      }
      return monthNumber;
    };

    const textFieldFilter = (event, key, filterType, filterWith) => {
      focusTextKey.value = key;
      updateLoading(true);
      emit("textFilterChange", { value: event.target.value, key: key });
    };

    const updateLoading = (loader) => {
      loading.value = loader;
    };
    const clearAllFilters = () => {
      filteredHeaders.value = [];
      props.headers?.map(
        (header) =>
          (header.filter =
            header?.filterType === "textbox" ||
            header?.filterType === "single-auto-complete"
              ? null
              : [])
      );
      emit("clearAllFilter", null);
    };
    const checkIfFilterExists = () => {
      return props.headers?.filter((header) =>
        header?.filterType === "textbox" ||
        header?.filterType === "single-auto-complete"
          ? header?.filter
          : header?.filter?.length
      );
    };
    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    }
    const expandedRow = ref(null);

    const expandRow = (item) => {
      if (expanded.value.includes(item)) {
        const index = expanded.value.indexOf(item);
        if (index > -1) {
          // only splice expanded.value when item is found
          expanded.value.splice(index, 1); // 2nd parameter means remove one item only
        }
      } else {
        expanded.value.push(item);
      }
    };
    const updateExpandedList = (expanded) => {
      if (expanded) {
        expanded.value = expanded;
      }
    };
    const dotLinkClicked = (event) => {
      if (event.key === "dotnumber") {
        window.scrollTo(0, 0);
        const cleanedDotNumber = event.value
          .replace(/^(DOT# |Nearby DOT# )/, "")
          .trim();
        router.push({ path: "/GCMS/DOT/" + cleanedDotNumber });
      } else if (event.key === "location") {
        window.open(
          "https://www.google.com/maps/place/" + event.value,
          "_blank"
        );
      }
    };

    const expandToggle = (type) => {
      if (type === "expand") {
        expanded.value = [];
        filteredData.value.forEach((item) => {
          if (!item?.disableRow) {
            return expanded.value.push(item[props?.expandKey]);
          }
        });
      } else {
        expanded.value = [];
      }
    };
    const checkFieldDisabled = (key, type, sort) => {
      if (!filteredData.value.length || type === "textbox") {
        return false;
      } else if (
        type === "multi-select" ||
        type === "auto-complete" ||
        type === "single-auto-complete"
      ) {
        return !getItems(key, sort)?.length;
      }
    };
    const getPagesList = () => {
      return Array.from(
        { length: getPaginationLengthLinked.value },
        (_, i) => i + 1
      );
    };
    const getDropdownItemsMaxLength = (key, sortType) => {
      let list = getItems(key, sortType)?.map((item) => item?.label?.length);
      return Math.max(...list) * 12 <= 280
        ? "280px"
        : Math.max(...list) * 12 + "px";
    };

    const checkIfOldFilterIsPresent = (key) => {
      let values =
        props?.oldMultipleFilter?.filter((header) => {
          return header?.key === key;
        })?.[0]?.value || [];
      return values.length;
    };
    const resetFocusTextKey = () => {
      focusTextKey.value = null;
    };

    const clickHeaderButton = (key) => {
      emit("clickHeaderButton", key);
    };

    const dateChange = (dateChange, header, item) => {
      emit("dateChange", {
        updatedValue: dateChange,
        header: header,
        item: item,
      });
    };
    let maxDate = ref(new Date(MAX_DATE).toLocaleDateString("fr-ca"));
    let minDate = ref(new Date().toLocaleDateString("fr-ca"));
    const isDateValid = (date) => {
      if (!date) {
        return true;
      }
      if (date?.length != 10) {
        return false;
      }
      return moment(date, "YYYY-MM-DD", true).isValid();
    };
    return {
      isDateValid,
      dateChange,
      getPagesList,
      getDropdownItemsMaxLength,
      checkFieldDisabled,
      expandToggle,
      dotLinkClicked,
      subHeaders,
      subTableKey,
      subTableNoDataFound,
      updateExpandedList,
      expandedRow,
      checkIfFilterExists,
      updateLoading,
      loading,
      sortBy,
      page,
      multiSearch,
      search,
      filteredHeaders,
      lookupData,
      headerprops,
      selectedItem,
      filteredData,
      selectItem,
      updateSort,
      linkClicked,
      createRouter,
      exportList,
      viewAllProjectRoute,
      useRouter,
      router,
      pageNumber,
      getPaginationLength,
      itemsPerPage,
      jumpPage,
      pageChange,
      itemPerPageChange,
      updatePaginationPerPage,
      jumpToPageLinkedPagination,
      pageChangeLinked,
      getPaginationLengthLinked,
      linkedPagination,
      updateItems,
      exportFilName,
      detailExportFilName,
      updateTotalItems,
      getItems,
      filterValues,
      textFieldFilter,
      clearAllFilters,
      getProjectIdentifierList,
      debounce: createDebounce(),
      expanded,
      expandRow,
      updateMultipleFilter,
      DROPDOWN_NO_DATA,
      TABLE_NO_DATA_AVAILABLE,
      focusTextKey,
      resetOldFilter,
      appliedMultipleFilters,
      checkIfOldFilterIsPresent,
      resetFocusTextKey,
      clickHeaderButton,
      maxDate,
      minDate,
      expandKey,
      showItalics,
      showLegends,
      isFixedHeaderWithScroll,
      exportDetailsList,
    };
  },
};
