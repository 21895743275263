import useDateField from "@/composables/dateField.js";import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent from "@/modules/manageproject/components/PucCommunicationsUpdateDocument.vue";
import UploadScopingFieldViewDocument from "@/modules/scoping-field-view/components/UploadScopingFieldViewDocument.vue";

import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useDocumentStore } from "@/stores/document";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
export default {
  components: {
    UploadScopingFieldViewDocument: UploadScopingFieldViewDocument,
    DocumentsList,
    LoadingOverlay,
    DatePicker,
    PrimaryButton,
    SecondaryButton,
    BannerMessage,
    DialogComponent,
  },
  name: "PucCommunications",
  props: ["dotId"],
  emits: [
    "previousValue",
    "updatedValues",
    "otherButtonClicked",
    "refreshMpmsData",
  ],
  setup(props, { emit }) {
    const documentStore = useDocumentStore();
    const railroadEstimatesDocumentsDialogRef = ref(null);

    const route = useRoute();
    const id = route.params.id;
    const loading = ref(false);
    const pucCommunications = ref([]);
    const responseObject = ref([]);
    const showSave = ref(false);
    const showBanner = ref(false);
    const bannerMessage = ref("Saved Successfully.");
    const dateValidationStates = ref([]);
    let UpdateModalOpened = ref(false);

    let isFormValid = ref(false);
    // Role-based access control
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const isUserAccess = ref({
      read: false,
      update: false,
    });
    const accessCheck = async () => {
      isUserAccess.value.read =
        await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.HWYBRDG_PUC_COMMUNICATIONS_PAGE_READ_ACCESS
        );
      isUserAccess.value.update =
        await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.HWYBRDG_PUC_COMMUNICATIONS_PAGE_CRTUPD_ACCESS
        );
    };
    onMounted(async () => {
      await accessCheck();
      fetchEstimateData();
      fetchData();
    });
    watch(
      () => [responseObject.value],
      () => {
        if (isUserAccess.value.update) {
          showSave.value = true;
        }
      },
      { deep: true }
    );
    function postData() {
      axios
        .post(
          CONFIG_ENDPOINT.POST_PUC_COMMUNICATIONS + id,
          responseObject.value
        )
        .then((response) => {
          showSave.value = false;
          showBanner.value = true;
          window.scrollTo(0, 0);
          responseObject.value = [];
          valueChanged.value = false;
          emit("refreshMpmsData", true);
          fetchEstimateData();
          fetchData();
        })
        .catch((err) => {
          console.log(err);
          valueChanged.value = false;
        });
    }
    function fetchData() {
      valueChanged.value = false;
      axios
        .get(CONFIG_ENDPOINT.GET_PUC_COMMUNICATIONS + id)
        .then((response) => {
          pucCommunications.value = response.data;
          dateValidationStates.value = response.data.map(() => true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // Estimate Documents
    const filesList = ref([]);
    const updateDocumentList = ref(false);
    async function fetchEstimateData() {
      updateDocumentList.value = true;
    }
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };
    const uploadFiles = async (files) => {
      if (!isUserAccess.value.update) return; // Prevent upload on read access
      loading.value = true;
      let docType = "PUC_UPL_DT";
      let endpoint = CONFIG_ENDPOINT.UPLOAD_SCOPING_DOCUMENTS;
      document.getElementById("list-section").scrollIntoView();
      await documentStore.uploadDocuments(
        id,
        files,
        docType,
        endpoint,
        true,
        true,
        false
      );
      loading.value = false;
      fetchEstimateData();
    };
    const isDateValidated = ref(false);
    const checkDateValidation = (value, index) => {
      dateValidationStates.value[index] = value;
    };
    const valueChanged = ref(false);
    const dateChange = (event, index, item) => {
      if (!isUserAccess.value.update) return;
      valueChanged.value = true;
      if (event == "") {
        pucCommunications.value[index].pucCommDate = null;
      } else if (event) {
        pucCommunications.value[index].pucCommDate = event;
      }
      let object = {
        pucCommName: item.pucCommName,
        pucCommDate: event,
      };
      let newValue = true;
      for (let index in responseObject.value) {
        if (responseObject.value[index].pucCommName == item.pucCommName) {
          responseObject.value[index].pucCommDate = event;
          newValue = false;
        }
      }
      if (newValue) {
        responseObject.value.push(object);
      }
    };
    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const hideBannerMessage = () => {
      showBanner.value = false;
    };
    onBeforeRouteLeave((to, from, next) => {
      if (valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        next();
      }
    });
    window.onbeforeunload = function () {
      if (valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    const checkFormValidation = computed(() => {
      return (
        dateValidationStates.value.every((state) => state) &&
        showSave.value &&
        isUserAccess.value.update
      );
    });

    const updateButtonClicked = (event) => {
      console.log("im here");
      //if (!isUserAccess.value.update) return; // Prevent update if no access
      openDialog(event);
    };
    const openDialog = (event) => {
      UpdateModalOpened.value = true;
      railroadEstimatesDocumentsDialogRef.value
        .open("Update Documents", id, event)
        .then((resolve) => {
          if (resolve) {
            fetchEstimateData();
            UpdateModalOpened.value = false;
          }
        })
        .catch(() => {
          UpdateModalOpened.value = false;
        });
    };

    return {
      documentStore,
      filesList,
      updateDocumentListFlag,
      uploadFiles,
      id,
      fetchEstimateData,
      fetchData,
      pucCommunications,
      responseObject,
      postData,
      showSave,
      checkDateValidation,
      dateChange,
      isDateValidated,
      getDate,
      updateDocumentList,
      showBanner,
      hideBannerMessage,
      bannerMessage,
      isFormValid,
      checkFormValidation,
      isUserAccess,
      updateButtonClicked,
      railroadEstimatesDocumentsDialogRef,
      UpdateModalOpened,
    };
  },
};
