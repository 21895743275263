<template>
  <div>
    <VueTable
      class="costAndFundingTable"
      :tableData="programFundingInfo"
      :columnData="columnData"
      :tableHeader="'Department Approved Funding'"
    ></VueTable>
  </div>
</template>
<script>
import ProgramFundingInfo from "@/modules/cost-and-funding/scripts/ProgramFundingInfo.js";
export default ProgramFundingInfo;
</script>
<style>
@import "@/assets/css/style.scss";
</style>
