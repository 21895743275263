import auth from "@/auth";
import { ROUTE } from "@/constants/Routes.js";
import VIEWTASKS from "@/modules/Viewalltasks/components/ViewAllTasks.vue";
import AdvancedSearch from "@/modules/advance-search/components/AdvancedSearch.vue";
import DOTDETAILS from "@/modules/dot-details/components/DotDetails.vue";
import ADDUPDATERAILROADCOMPANY from "@/modules/gcmsmain/admin/submenu/views/AddUpdateRailroadCompany.vue";
import MANAGERAILROADCOMPANIES from "@/modules/gcmsmain/admin/submenu/views/ManageRailroadCompanies.vue";
import DISTRICTOFFICELIST from "@/modules/gcmsmain/admin/submenu/views/ViewDistrictOfficeList.vue";
import MAINTAINSIGNATURETEMPLATE from "@/modules/gcmsmain/admin/submenu/views/ViewMaintainSignatureTemplate.vue";
import FLAGGERACTIVITYADMIN from "@/modules/gcmsmain/admin/submenu/views/viewFlaggerActivityAdmin.vue";
import CreateTabsView from "@/modules/gcmsmain/createproject/submenu/components/createProjectTabs.vue";
import MPMSPROJECTS from "@/modules/gcmsmain/createproject/submenu/views/viewMpmsProjects.vue";
import COMPLETEDCROSSINGSINSPECTIONS from "@/modules/gcmsmain/districtfunctions/views/viewCompletedCrossingsInspections.vue";
import INVENTORY from "@/modules/grade-crossing-and-bridges/views/GradeInventory.vue";
import ADDRAILROADS from "@/modules/manageproject/views/viewAddRailroads.vue";
import CONDITIONALRAILROADCERTIFICATIONS from "@/modules/manageproject/views/viewConditionalRailroadCertifications.vue";
import CONTRACTORBID from "@/modules/manageproject/views/viewContractorBid.vue";
import EDITRAILROADESTIMATES from "@/modules/manageproject/views/viewEditRailroadEstimates.vue";
import MANAGEFLAGGERANDRAILROADS from "@/modules/manageproject/views/viewManageFlaggerAndRailroads.vue";
import PARTIESOFRECORD from "@/modules/manageproject/views/viewPartiesOfRecord.vue";
import PROJECTDOCUMENTS from "@/modules/manageproject/views/viewProjectDocuments.vue";
import PROJECTFLAGGERNEEDS from "@/modules/manageproject/views/viewProjectFlaggerNeeds.vue";
import PROJECTHISTORY from "@/modules/manageproject/views/viewProjectHistory.vue";
import PROJECTINVOLVEMENT from "@/modules/manageproject/views/viewProjectInvolvement.vue";
import PROJECTTEAM from "@/modules/manageproject/views/viewProjectTeam.vue";
import PROJECTWORKFLOWNOTIFICATIONS from "@/modules/manageproject/views/viewProjectWorkflowNotifications.vue";
import PUCCOMMUNICATIONS from "@/modules/manageproject/views/viewPucCommunications.vue";
import RAILROADCERTIFICATION from "@/modules/manageproject/views/viewRailroadCertification.vue";
import RAILROADESTIMATES from "@/modules/manageproject/views/viewRailroadEstimates.vue";
import RAILROADPLANREVIEW from "@/modules/manageproject/views/viewRailroadPlanReview.vue";
import RAILROADWORKSUMMARY from "@/modules/manageproject/views/viewRailroadWorkSummary.vue";
import SAFETYMILESTONES from "@/modules/manageproject/views/viewSafetyMilestones.vue";
import MPMS from "@/modules/project/views/Mpms.vue";
import TabsView from "@/modules/projectinformation/components/TabsView.vue";
import SCOPINGFIELDVIEW from "@/modules/scoping-field-view/views/ScopingFieldView.vue";
import ConfirmationPage from "@/modules/shared/components/ConfirmationPage.vue";
import VIEWPROJECTS from "@/modules/viewallprojects/views/ViewProjects.vue";
import { useUserStore } from "@/stores/userStore";
import CallbackPage from "@/views/CallbackPage.vue";
import DashboardView from "@/views/Dashboard.vue";
import EXTERNALLANDING from "@/views/ExternalLanding.vue";
import GCMS from "@/views/GcmsMain.vue";
import LogoutPage from "@/views/LogoutPage.vue";
import { createRouter, createWebHistory } from "vue-router";
import AssignDistricts from "../modules/gcmsmain/admin/submenu/views/AssignDistricts.vue";
import NotFound from "@/modules/errorpage/Pagenotfound.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import UnAuthorizedPage from "@/modules/errorpage/UnAuthorizedPage.vue";

const EXTERNAL_ROUTES = [
  "external",
  "external/index.html",
  "external?mpmsNumber=:mpmsNumber&docType=:docType&railRoadID=:railRoadID",
];
const routes = [
  {
    path: "/callback",
    name: "Callback",
    component: CallbackPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/logout",
    name: "LogoutPage",
    component: LogoutPage,
  },
  {
    path: "/logout",
    name: "LogoutPage",
    component: LogoutPage,
  },
  {
    path: "/external?mpmsNumber=:mpmsNumber&docType=:docType&railRoadID=:railRoadID",
    name: "EXTERNALLANDING",
    component: EXTERNALLANDING,
    props: { external: true },
  },

  {
    path: "/logoutConfirm",
    name: "ConfirmationPage",
    component: ConfirmationPage,
  },
  { path: "/:catchAll(.*)", component: NotFound }, // Catch-all route for 404
  { path: "/unauthorized", component: UnAuthorizedPage }, // Catch-all route for 403
  {
    path: ROUTE.GCMS_HOME.path,
    name: "GCMS",
    redirect: () => {
      return "/callback";
    },

    component: GCMS,
    children: [
      {
        path: "/dashboard",
        name: "DashboardView",
        component: DashboardView,
        meta: { requiresAuth: true },
      },
      {
        path: ROUTE.GCMS_DETAILS.path,
        name: "GCMS_DETAILS",
        component: MPMS,
        children: [
          { path: "/GCMS/:id", name: "Tabs", component: TabsView },
          {
            path: "/GCMS/:id/projectFlaggerNeeds",
            name: "PROJECTFLAGGERNEEDS",
            component: PROJECTFLAGGERNEEDS,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.PROJECT_FLAGGER_NEEDS_PAGE_READ_ACCESS},
          },
          {
            path: "/GCMS/:id/addRailroads",
            name: "ADDRAILROADS",
            component: ADDRAILROADS,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.ADD_RAILROAD_PROJECT_PAGE_CRTUPD_ACCESS },
          },
          {
            path: "/GCMS/:id/safetyMilestones",
            name: "SAFETYMILESTONES",
            component: SAFETYMILESTONES,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.SAFETY_MILESTONES_PAGE_READ_ACCESS},
          },
          {
            path: "/GCMS/:id/Scoping",
            name: "SCOPINGFIELDVIEW",
            component: SCOPINGFIELDVIEW,
            meta: { requiresAuth: true },
          },
          {
            path: "/GCMS/:id/project-involvement",
            name: "PROJECTINVOLVEMENT",
            component: PROJECTINVOLVEMENT,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.PROJECT_INVOLVEMENT_PAGE_READ_ACCESS},
          },
        
          {
            path: "/GCMS/:id/railroad-Estimates",
            name: "RAILROADESTIMATES",
            component: RAILROADESTIMATES,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.RAILROADESTIMATES_PAGE_READ_ACCESS},
          },
          {
            path: "/GCMS/:id/railroad-Estimates/edit/:cid/:eid",
            name: "EDITRAILROADESTIMATES",
            component: EDITRAILROADESTIMATES,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.RAILROADESTIMATES_PAGE_READ_ACCESS},
            props: true,
          },
          {
            path: "/GCMS/:id/parties-of-record",
            name: "PARTIESOFRECORD",
            component: PARTIESOFRECORD,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.PARTIES_RECORD_PAGE_READ_ACCESS },
            props: true,
          },
          {
            path: "/GCMS/:id/workflow-notifications",
            name: "PROJECTWORKFLOWNOTIFICATIONS",
            component: PROJECTWORKFLOWNOTIFICATIONS,
            meta: { requiresAuth: true ,access: RBAC_CONTROLLER.WORKFLOW_NOTIFICATIONS_PAGE_READ_ACCESS},
            props: true,
          },
          {
            path: "/GCMS/:id/puc-communications",
            name: "PUCCOMMUNICATIONS",
            component: PUCCOMMUNICATIONS,
            meta: { requiresAuth: true ,access: RBAC_CONTROLLER.HWYBRDG_PUC_COMMUNICATIONS_PAGE_READ_ACCESS},
            props: true,
          },
          {
            path: "/GCMS/:id/railroad-plan-review",
            name: "RAILROADPLANREVIEW",
            component: RAILROADPLANREVIEW,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.RAILROAD_PLAN_REVIEW_PAGE_READ_ACCESS},
            props: true,
          },
          {
            path: "/GCMS/:id/project-team",
            name: "PROJECTTEAM",
            component: PROJECTTEAM,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.PENNDOT_PROJECTTEAM_PAGE_READ_ACCESS},
            props: true,
          },
          {
            path: "/GCMS/:id/railroad-certification",
            name: "RAILROADCERTIFICATION",
            component: RAILROADCERTIFICATION,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.RAILROAD_CERTIFICATIONS_PAGE_READ_ACCESS},
            props: true,
          },
          {
            path: "/GCMS/:id/railroad-work-summary",
            name: "RAILROADWORKSUMMARY",
            component: RAILROADWORKSUMMARY,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.RAILROAD_WORK_SUMMARY_READ_ACCESS },
            props: true,
          },
          {
            path: "/GCMS/:id/project-history",
            name: "PROJECTHISTORY",
            component: PROJECTHISTORY,
            meta: { requiresAuth: true ,access: RBAC_CONTROLLER.PROJECT_HISTORY_PAGE_READ_ACCESS },
            props: true,
          },

          {
            path: "/GCMS/:id/contractor-bid",
            name: "CONTRACTORBID",
            component: CONTRACTORBID,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.SAFETY_PROJECTS_CONTRACTOR_BID_PAGE_READ_ACCESS},
            props: true,
          },
          {
            path: "/GCMS/:id/project-document",
            name: "PROJECTDOCUMENTS",
            component: PROJECTDOCUMENTS,
            meta: { requiresAuth: true , access: RBAC_CONTROLLER.PROJECT_DOCUMENTS_PAGE_READ_ACCESS},
            props: true,
          },
        ],
      },
      {
        path: "/GCMS/createProject/:id",
        name: "CreateTabs",
        component: CreateTabsView,
        meta: { requiresAuth: true , access: RBAC_CONTROLLER.CREATE_PROJECT_ACCESS},
      },
      {
        path: ROUTE.GRADEINVENTORY.path,
        name: "gradecrossing",
        component: INVENTORY,
        meta: { requiresAuth: true , access: RBAC_CONTROLLER.GRADECROSSINGINVENTORYLIST_READ_ACCESS},
      },
      {
        path: ROUTE.DOT_DETAILS.path,
        name: "gradecrossing-details",
        component: DOTDETAILS,
        meta: { requiresAuth: true ,access: RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS},
      },
      {
        path: ROUTE.VIEW_PROJECTS.path,
        name: "projects",
        component: VIEWPROJECTS,
        meta: { requiresAuth: true },
      },
      {
        path: ROUTE.VIEW_TASKS.path,
        name: "all-tasks",
        component: VIEWTASKS,
        meta: { requiresAuth: true },
      },
      {
        path: ROUTE.FLAGGER_ACTIVITY_ADMIN.path,
        name: "flaggeractivityadmin",
        component: FLAGGERACTIVITYADMIN,
        meta: { requiresAuth: true , access: RBAC_CONTROLLER.ADDEDIT_FLAGGERACTIVITIES_CRTUPD_ACCESS},
      },
      {
        path: ROUTE.DISTRICT_OFFICE_LIST.path,
        name: "districtOfficeList",
        component: DISTRICTOFFICELIST,
        meta: { requiresAuth: true , access: RBAC_CONTROLLER.DISTRICT_OFFICE_READ_ACCESS},
      },
      {
        path: ROUTE.MAINTAIN_SIGNATURE_TEMPLATE_LIST.path,
        name: "maintainSignatureTemplate",
        component: MAINTAINSIGNATURETEMPLATE,
        meta: { requiresAuth: true  , access: RBAC_CONTROLLER.TEMPLATE_SIGNATURE_PAGE_READ_ACCESS},
      },
      {
        path: ROUTE.MANAGE_RAILROAD_COMPANIES.path,
        name: "maintainRailroadCompanies",
        component: MANAGERAILROADCOMPANIES,
        meta: { requiresAuth: true ,access: RBAC_CONTROLLER.MANAGE_RAILROAD_COMPANIES_READ_ACCESS},
      },
      {
        path: ROUTE.ADD_MANAGE_RAILROAD_COMPANIES.path,
        name: "addRailroadCompanies",
        component: ADDUPDATERAILROADCOMPANY,
        meta: { requiresAuth: true , access: RBAC_CONTROLLER.MANAGE_RAILROAD_COMPANIES_CRTUPD_ACCESS},
        beforeEnter: (to, from, next) => {
          next("/GCMS/manage-railroad-companies/add/" + "new");
        },
      },
      {
        path: ROUTE.EDIT_MANAGE_RAILROAD_COMPANIES.path,
        name: "editRailroadCompanies",
        component: ADDUPDATERAILROADCOMPANY,
        meta: { requiresAuth: true , access: RBAC_CONTROLLER.MANAGE_RAILROAD_COMPANIES_CRTUPD_ACCESS},
      },
      {
        path: ROUTE.COMPLETED_CROSSINGS_INSPECTIONS.path,
        name: "completedCrossingsInspections",
        component: COMPLETEDCROSSINGSINSPECTIONS,
        meta: { requiresAuth: true , access: RBAC_CONTROLLER.DISTRICT_GRADE_CROSSING_INSPECTIONS_READ_ACCESS},
      },
      {
        path: ROUTE.MPMS_PROJECTS.path,
        name: "mpmsprojects",
        component: MPMSPROJECTS,
        meta: { requiresAuth: true },
      },
      {
        path: ROUTE.PROJECT_FLAGGER_NEEDS.path,
        name: "projectflaggerneeds",
        component: PROJECTFLAGGERNEEDS,
        meta: { requiresAuth: true , access: RBAC_CONTROLLER.PROJECT_FLAGGER_NEEDS_PAGE_READ_ACCESS},
      },
      {
        path: ROUTE.ADVANCED_SEARCH.path,
        name: "advancedSearch",
        component: AdvancedSearch,
        meta: { requiresAuth: true },
      },
      {
        path: "/GCMS/manage-flagger-and-railroads",
        name: "MANAGEFLAGGERANDRAILROADS",
        component: MANAGEFLAGGERANDRAILROADS,
        meta: { requiresAuth: true ,access: RBAC_CONTROLLER.MANAGE_FLAGGER_RESOURCES_READ_ACCESS},
      },
      {
        path: "/GCMS/conditional-railroad-certifications",
        name: "CONDITIONALRAILROADCERTIFICATIONS",
        component: CONDITIONALRAILROADCERTIFICATIONS,
        meta: { requiresAuth: true , access: RBAC_CONTROLLER.VIEW_CONDITIONAL_CERTIFICATIONS_LIST_READ_ACCESS},
      },
      {
        path: ROUTE.ASSIGN_DISTRICTS.path,
        name: "ASSIGNDISTRICTS",
        component: AssignDistricts,
        meta: {
          requiresAuth: true,
          access: RBAC_CONTROLLER.ASSIGN_DISTRICTS_READ_ACCESS,
        },
      },
    ],

    meta: { requiresAuth: true },
  },
];
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

/**
 * Global navigation guard which checks if a authority is required to access the
 * route and if the logged in user has one of the required authorities.  If no
 * authorities are required or user has one of the required authorities, call next(),
 * otherwise call next(false).
 */

const checkOnlineAuthentication = async (next, to) => {
  if (!to.name == "LogoutPage") {
    const user = await auth.getUser();
    if (user) {
      if (!user.expired) {
        next();
      } else {
        await auth.login();
      }
    } else {
      await auth.login();
    }
  } else {
    next();
  }
};

const redirectToLogin = async (next, to) => {
  const userStore = useUserStore();
  const userRoleBasedAccessService = userRoleBasedAccessStore();
  if (userStore.isAuthenticated) {
    if (to.meta.access) {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        to.meta.access
      );
      if (readResponse) {
        next(); // User has the required access, proceed
      } else {
        next({ path: "/unauthorized" }); // Redirect to unauthorized page
      }
    } else {
      next();
    }
  } else {
    await checkOnlineAuthentication(next, to);
  }
};

router.beforeEach(async (to, from, next) => {

  if (to.meta.requiresAuth) {
    await redirectToLogin(next, to);

  } else {
    next();
  }
  router.afterEach((to, from, failure) => {
    if (failure) {
      console.log(to, from, failure);
    }
  });
});

export default router;
