<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="900px"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 450px; overflow-y: scroll">
          <v-row style="margin: 0">
            <v-col cols="12" class="text-left">
              <label for="username"
                ><b>Username: </b>{{ event?.userName }}</label
              >
            </v-col>
          </v-row>
          <v-row style="margin: 0">
            <v-col cols="2" class="text-left">
              <label for="district"><b>Districts:</b></label>
            </v-col>
            <v-col cols="6" class="district-container">
              <v-card>
                <div class="d-flex flex-column">
                  <div v-for="district of event?.lookup" :key="district">
                    <div
                      class="d-flex align-items-center justify-content-start"
                      style="margin: 0; margin-left: 30px"
                    >
                      <div class="w-5">
                        <v-checkbox-btn v-model="district.checked" class="pe-2">
                        </v-checkbox-btn>
                      </div>
                      <div class="text-left ml-20">
                        {{ district.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :buttonText="'Save'"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import AssignDistrictsDialog from "@/modules/gcmsmain/admin/submenu/scripts/AssignDistrictsDialog.js";

export default AssignDistrictsDialog;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
.district-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.district-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
