<template>
  <v-text-field
    v-if="isRequired"
    v-model="computedValue"
    type="text"
    min="0"
    maxlength="14"
    onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
    :validate-on="'input'"
    :rules="getPhoneFieldRule ? [requiredRule, phoneRule] : []"
    @update:modelValue="
      fieldValueChange();
      debounce(() => {
        setFieldRule();
      });
    "
    @paste="handlePaste"
  >
  </v-text-field>
  <v-text-field
    v-if="!isRequired"
    v-model="computedValue"
    type="text"
    min="0"
    maxlength="14"
    onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
    :validate-on="'input'"
    :rules="getPhoneFieldRule ? [phoneRule] : []"
    @update:modelValue="
      fieldValueChange();
      debounce(() => {
        setFieldRule();
      });
    "
    @paste="handlePaste"

  >
  </v-text-field>
</template>
  <script>
import PhoneInput from "@/modules/shared/scripts/PhoneInput.js";
export default PhoneInput;
</script>
  
