import useDateField from "@/composables/dateField.js";import DocumentsVersionDialogComponent from "@/modules/shared/components/DocumentsVersionDialog.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import { useDocumentStore } from "@/stores/document";
import { onMounted, ref, watch } from "vue";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  name: "DocumentsList",
  components: {
    FeatureTable,
    LoadingOverlay,
    DocumentsVersionDialogComponent,
  },
  props: {
    id: { type: Number, default: null },
    item: Object,
    heading: { type: String, default: "" },
    doctype: { type: String, default: "" },
    updateDocumentList: { type: Boolean, default: false },
    isUpdateActionButton: { type: Boolean, default: false },
    isRailroadWorkSummary: { type: Boolean, default: false },
    railroadCompanyId: { type: String, default: "" },
  },
  emits: ["updateDocumentListFlag", "filesLength"],
  setup(props, { emit }) {
    const priorityProjectDialogRef = ref(null);
    const documentStore = useDocumentStore();
    const filesList = ref([]);
    const loading = ref(false);
    const heading = ref(props?.heading);
    const headers = ref([
      {
        title: "File Name",
        icon: "fileName",
        key: "fileName",
        sortable: false,
        columnType: "icon",
        justifyContent: "start",
        isLink: true,
        isDownload: true,
        width: "20% !important",
      },
      {
        title: "Version",
        key: "version",
        sortable: false,
        width: "3% !important",
        isLink: true,
      },
      {
        title: "Document Type",
        key: "documentType",
        sortType: "text",
        width: "15% !important",
        sortable: true,
      },
      {
        title: "Date Uploaded",
        key: "documentUploadDate",
        sortType: "date",
        width: "4% !important",
        sortable: true,
      },
      {
        title: "File Size (MB)",
        key: "fileSize",
        sortable: false,
        width: "3% !important",
      },
      { title: "Railroad", key: "railRoadName", sortable: false, width: "10%" },
      {
        title: "DOT#",
        key: "dotNumber",
        sortable: false,
        width: "4% !important",
      },
      { title: "Notes", key: "notes", sortable: false, width: "20%" },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };

    watch(
      () => [props.updateDocumentList],
      () => {
        if (props.updateDocumentList) {
          fetchData();
          emit("updateDocumentListFlag", false);
        }
      },
      { deep: true }
    );

    onMounted(() => {
      headers.value.push({
        title: "Action",
        key: "action",
        sortable: false,
        columnType: "action",
        width: "3% !important",
        isOtherButton: true,
        otherButtonText: "Update",
        hideOtherButtonKey: "hideOtherButtonKey",
      });

      fetchData();
    });

    const sortUpdate = (event) => {
      sortData.value = {
        key: event[0].key,
        order: event[0].order,
      };
      fetchData();
    };
    const sortData = ref({
      key: "documentUploadDate",
      order: "asc",
    });
    async function fetchData() {
      let doctype = props?.doctype;
      let response = null;
      if (props?.isRailroadWorkSummary) {
        response = await documentStore.getRailroadWorkSummaryDocuments(
          props.id,
          doctype,
          sortData.value,
          props.railroadCompanyId
        );
      } else {
        response = await documentStore.getDocuments(
          props.id,
          doctype,
          sortData.value
        );
      }
      if (response) {
        let data = response.data;
        console.log("filesLength", response.data);
        data.forEach((item) => {
          item.documentUploadDate = getDate(item.documentUploadDate);
          item.hideOtherButtonKey = item?.source?.toLowerCase() === "system";
          item.fileName =
            "<i class='tableIcons fa fa-download' aria-hidden='true' style='color:#003e7e;'></i><span>" +
            item.fileName +
            "</span>";
          item.dotNumber = item?.dotNumber
            ? item?.dotNumber
            : getDotNumbers(item?.dotNumbers);
          item.hideLink = item?.version <= 1;
        });
        filesList.value = data;
        console.log("filesLength", filesList.value);
        emit("filesLength", filesList.value.length);
      } else {
        filesList.value = [];
        emit("filesLength", filesList.value.length);
        paginationData.value = {
          page: 1,
          items: paginationData.value.items,
          total: 0,
        };
      }
    }
    const getDotNumbers = (dotNumbers) => {
      return dotNumbers?.map((dotNum) => dotNum?.dotNumber)?.join(", ");
    };
    const downloadFile = (fileDetails) => {
      let docId = fileDetails.value?.escDocumentId;
      let endpoint = CONFIG_ENDPOINT.DOWNLOAD_SCOPING_FILE;
      console.log("linkClicked downloadFile endpoint", docId, endpoint);
      documentStore.downloadFile(docId, props.id, endpoint);
    };

    const linkClicked = (event) => {
      if (event.key === "fileName") {
        console.log("linkClicked downloadFile", event);
        downloadFile(event);
      } else if (event.key === "version") {
        console.log("linkClicked", event);
        openPriorityProject(event);
      }
    };
    const openPriorityProject = (event) => {
      priorityProjectDialogRef.value
        .open(
          "View Project Versioning",
          {},
          props?.id,
          event.value.parentDocumentId
        )
        .then(() => {});
    };

    const deleteButtonClicked = (event) => {
      console.log(event);
    };
    const otherButtonClicked = (event) => {
      console.log("in other button clicked!");
      emit("otherButtonClicked", event);
    };
    return {
      deleteButtonClicked,
      downloadFile,
      linkClicked,
      sortUpdate,
      headers,
      filesList,
      loading,
      fetchData,
      paginationData,
      heading,
      otherButtonClicked,
      DocumentsVersionDialogComponent,
      priorityProjectDialogRef,
    };
  },
};
