import { vfs } from "@/assets/vfs_fonts.js";import useDateField from "@/composables/dateField.js";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { PennDotImageDataURL } from "@/constants/ImageDataUrls";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "projectWorkflowNotifications",
  components: { VueTable, LoadingOverlay, BannerMessage },
  emits: [],
  setup(props, { emit }) {
    const route = useRoute();
    const id = route.params.id;
    let loading = ref(false);
    const { getFormattedDateStringNoTime } = useDateField();
    let notificationList = ref([]);
    let sendAllList = ref([]);
    let filteredList = ref([]);
    const SuccessBannerData = ref(null);
    const ErrorBannerData = ref(null);
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const isUserAccess = ref({
      read: false,
      update: false,
    });
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    const headers = ref([
      {
        name: "Railroads",
        key: "railRoad",
        columnWidth: "30",
        sortable: false,
        disabled: !isUserAccess.value.read,
      },
      {
        name: "Engineering Authorization",
        key: "sendEnable",
        columnWidth: "40",
        sortable: false,
        isAction: true,
        isEngineeringAuthorization: true,
        disabled: !isUserAccess.value.update,
      },
      {
        name: "Notice To Proceed",
        key: "ntpSendEnable",
        columnWidth: "30",
        sortable: false,
        isAction: true,
        isNTP: true,
        disabled: !isUserAccess.value.update,
      },
    ]);
    const accessCheck = async () => {
      const readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.WORKFLOW_NOTIFICATIONS_PAGE_READ_ACCESS
      );
      const updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.WORKFLOW_NOTIFICATIONS_PAGE_CRTUPD_ACCESS
      );
      isUserAccess.value.read = readResponse || false;

      isUserAccess.value.update = updateResponse || false;

      headers.value.forEach((header) => {
        if (header.key === "sendEnable" || header.key === "ntpSendEnable") {
          header.disabled = !isUserAccess.value.update;
        }

        if (header.key === "railRoad") {
          header.disabled = !isUserAccess.value.read;
        }
      });
      headers.value = [
        {
          name: "Railroads",
          key: "railRoad",
          columnWidth: "30",
          sortable: false,
          disabled: !isUserAccess.value.read,
        },
        {
          name: "Engineering Authorization",
          key: "sendEnable",
          columnWidth: "40",
          sortable: false,
          isAction: true,
          isEngineeringAuthorization: true,
          disabled: !isUserAccess.value.update,
        },
        {
          name: "Notice To Proceed",
          key: "ntpSendEnable",
          columnWidth: "30",
          sortable: false,
          isAction: true,
          isNTP: true,
          disabled: !isUserAccess.value.update,
        },
      ];
    };
    onMounted(async () => {
      await accessCheck();
      if (isUserAccess.value.read) {
        await fetchData();
      } else {
        notificationList.value = [];
      }
    });
    const linkClicked = (event) => {
      links.value = [];
      getPucDocs(event.value);
    };
    async function fetchData() {
      loading.value = true;
      axios
        .get(CONFIG_ENDPOINT.GET_WORKFLOW_NOTIFICATIONS + id)
        .then((response) => {
          notificationList.value = response.data;
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
        });
    }
    const sendEngineeringAuth = (item, value) => {
      if (item === "Send" && isUserAccess.value.update) {
        axios
          .get(
            CONFIG_ENDPOINT.GET_WORKFLOW_SEND_TO_ENGINEERING_AUTHORIZATION_DATA +
              id +
              "/" +
              value.item.railroadCompanyId
          )
          .then((responseData) => {
            GeneratePDF(responseData?.data, false);
          })
          .catch((err) => {
            BannerUpdate({
              bannerType: "error",
              bannerMessage:
                err?.response?.data?.businessMessage ||
                err?.message ||
                "Something Went Wrong",
              showBanner: true,
            });
          });
      }
    };
    const sendAllEngineeringAuth = (item, index) => {
      if (item === "SendAll" && isUserAccess.value.update) {
        sendAllList.value = [];
        filteredList.value = notificationList.value.filter(
          (notification) => !notification?.notificationDate
        );
        let payload = filteredList.value.map((data) => {
          return {
            mpmsNumber: id,
            railroadCompanyId: data.railroadCompanyId,
            fileData: "",
            metaData: {
              docType: "Engineering Authorization",
              filename: "Engineering Authorization " + data.railRoad + ".pdf",
              description: "Engineering Authorization",
              mpmsNumber: id,
              railRoadName: data.railRoad,
              railroadCompanyId: data.railroadCompanyId,
            },
          };
        });
        axios
          .post(
            CONFIG_ENDPOINT.GET_WORKFLOW_SEND_TO_ENGINEERING_AUTHORIZATION_ALL_DATA,
            payload,
            {
              headers: {
                userId: userData.value?.userID,
                password: userData.value?.userID,
              },
            }
          )
          .then((response) => {
            let data = response.data;
            let fileList = [];
            data.forEach(async (railroad, index) => {
              fileList.push(await GeneratePDF(railroad, true, index));
            });
          })
          .catch((error) => {
            BannerUpdate({
              bannerType: "error",
              bannerMessage:
                err?.response?.data?.businessMessage ||
                err?.message ||
                "Something Went Wrong",
              showBanner: true,
            });
          });
      }
    };
    const sendNTP = (item, value) => {
      if (item === "Send" && isUserAccess.value.update) {
        loading.value = true;
        let payload = {
          mpmsNumber: id,
          railroadCompanyCode: value.item.railroadCompanyCode,
          railroadCompanyId: value.item.railroadCompanyId,
          metaData: {
            docType: "NTP for Construction to Railroad",
            description: "NTP for Construction to Railroad",
            mpmsNumber: id,
            railRoadName: value.item.railRoad,
            railroadCompanyId: value.item.railroadCompanyId,
          },
        };
        axios
          .post(CONFIG_ENDPOINT.SEND_NTP + id, [payload], {
            headers: {
              userId: userData.value?.userID,
              password: userData.value?.userID,
            },
          })
          .then((response) => {
            if (!response.response?.data?.errorCode) {
              fetchData();
              BannerUpdate({
                bannerType: "success",
                bannerMessage:
                  "NTP for Construction to Railroad Sent Successfully",
                showBanner: true,
              });
            }
            loading.value = false;
          })
          .catch((err) => {
            loading.value = false;
            BannerUpdate({
              bannerType: "error",
              bannerMessage:
                err?.response?.data?.businessMessage ||
                err?.message ||
                "Something Went Wrong",
              showBanner: true,
            });
            loading.value = false;
          });
      }
    };
    const sendAllNTP = (item, index) => {
      if (item === "SendAll" && isUserAccess.value.update) {
        loading.value = true;
        sendAllList.value = [];
        filteredList.value = notificationList.value.filter(
          (notification) => !notification?.ntpNotificationDate
        );
        let fileList = [];
        filteredList.value.forEach((railroad, index) => {
          fileList.push({
            mpmsNumber: id,
            railroadCompanyCode: railroad.railroadCompanyCode,
            railroadCompanyId: railroad.railroadCompanyId,
            metaData: {
              docType: "NTP for Construction to Railroad",
              description: "NTP for Construction to Railroad",
              mpmsNumber: id,
              railRoadName: railroad.railRoad,
              railroadCompanyId: railroad.railroadCompanyId,
            },
          });
        });
        axios
          .post(CONFIG_ENDPOINT.SEND_NTP + id, fileList, {
            headers: {
              userId: userData.value?.userID,
              password: userData.value?.userID,
            },
          })
          .then(() => {
            loading.value = false;
            fetchData();
            BannerUpdate({
              bannerType: "success",
              bannerMessage:
                "NTP for Construction to Railroad Sent Successfully",
              showBanner: true,
            });
          })
          .catch((err) => {
            loading.value = false;
            BannerUpdate({
              bannerType: "error",
              bannerMessage:
                err?.response?.data?.businessMessage ||
                err?.message ||
                "Something Went Wrong",
              showBanner: true,
            });
            loading.value = false;
          });
      }
    };
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const postPdf = (payload) => {
      axios
        .post(
          CONFIG_ENDPOINT.GET_WORKFLOW_SEND_TO_ENGINEERING_AUTHORIZATION_LETTIER +
            id,
          payload,
          {
            headers: {
              userid: userData.value?.userID,
              password: userData.value?.userID,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (!response.response?.data?.errorCode) {
            fetchData();
            BannerUpdate({
              bannerType: "success",
              bannerMessage:
                "Engineering Authorization Notification Sent Successfully",
              showBanner: true,
            });
          }
          loading.value = false;
        })
        .catch((err) => {
          BannerUpdate({
            bannerType: "error",
            bannerMessage:
              err?.response?.data?.businessMessage ||
              err?.message ||
              "Something Went Wrong",
            showBanner: true,
          });
          loading.value = false;
        });
    };
    const getValueOrEmptySpace = (railroadDetails, key) => {
      return railroadDetails?.[key] || "";
    };
    const GeneratePDF = async (railroadDetails, isSendAll, sendAllIndex) => {
      loading.value = true;
      let pdfData = [
        {
          image: PennDotImageDataURL,
        },
        {
          fontSize: 11,
          text: getDate(getValueOrEmptySpace(railroadDetails, "date")),
          margin: [0, 20, 0, 10],
        },
        {
          fontSize: 11,
          text: getValueOrEmptySpace(railroadDetails, "railRoadCompanyName"),
        },
      ];
      if (
        getValueOrEmptySpace(railroadDetails, "railroadContactFirstName") ||
        getValueOrEmptySpace(railroadDetails, "railroadContactLastName")
      ) {
        pdfData.push({
          fontSize: 11,
          text:
            getValueOrEmptySpace(railroadDetails, "railroadContactFirstName") +
            " " +
            getValueOrEmptySpace(railroadDetails, "railroadContactLastName"),
          margin: [0, 0, 0, 10],
        });
      }

      if (getValueOrEmptySpace(railroadDetails, "railroadAddress1")) {
        pdfData.push({
          fontSize: 11,
          text: getValueOrEmptySpace(railroadDetails, "railroadAddress1"),
          margin: [0, 0, 0, 0],
        });
      }

      if (getValueOrEmptySpace(railroadDetails, "railroadAddress2")) {
        pdfData.push({
          fontSize: 11,
          text: getValueOrEmptySpace(railroadDetails, "railroadAddress2"),
          margin: [0, 0, 0, 0],
        });
      }
      pdfData.push([
        {
          fontSize: 11,
          text:
            getValueOrEmptySpace(railroadDetails, "railroadCity") +
            (getValueOrEmptySpace(railroadDetails, "railroadCity")
              ? ", "
              : "") +
            " " +
            getValueOrEmptySpace(railroadDetails, "railroadState") +
            " " +
            getValueOrEmptySpace(railroadDetails, "railroadZip"),
        },
        {
          margin: [0, 10, 0, 0],
          fontSize: 11,
          columns: [
            { width: "5%", text: "RE:	" },
            {
              text:
                "County: " +
                getValueOrEmptySpace(railroadDetails, "projectCounty"),
            },
          ],
        },
        {
          fontSize: 11,
          columns: [
            { width: "5%", text: "  " },
            {
              text:
                "Municipality: " +
                getValueOrEmptySpace(railroadDetails, "projectMunicipality"),
            },
          ],
        },
        {
          fontSize: 11,
          columns: [
            { width: "5%", text: "  " },
            {
              text:
                "Project Title: " +
                getValueOrEmptySpace(railroadDetails, "projectTitle"),
            },
          ],
        },
        {
          fontSize: 11,
          columns: [
            { width: "5%", text: "  " },
            {
              text:
                "DOT(s)#: " +
                (getValueOrEmptySpace(railroadDetails, "dots")
                  ? getValueOrEmptySpace(railroadDetails, "dots")?.join(", ")
                  : ""),
            },
          ],
        },
        {
          fontSize: 11,
          text:
            "Dear " +
            getValueOrEmptySpace(railroadDetails, "railroadContactFirstName") +
            " " +
            getValueOrEmptySpace(railroadDetails, "railroadContactLastName") +
            (getValueOrEmptySpace(railroadDetails, "railroadContactLastName")
              ? ", "
              : ""),
          margin: [0, 10, 0, 20],
        },
        {
          fontSize: 11,
          alignment: "justify",
          text: "This is your AUTHORIZATION to PROCEED with the preliminary engineering on the subject project. This authorization shall include, but not be limited to, transportation and labor for field engineering, protection, preparation of plans, specifications, estimates and other related preparatory work in advance of physical construction operations.",
          margin: [0, 0, 0, 10],
        },
        {
          fontSize: 11,
          alignment: "justify",
          text: "Physical work on the actual adjustment or relocation of affected facilities may not be started prior to the receipt of Department of Transportation's specific authorization to proceed with physical work.",
          margin: [0, 0, 0, 10],
        },
        {
          fontSize: 11,
          alignment: "justify",
          text: "Costs for work performed and material furnished to accommodate design and construction may be reimbursable to the extent provided by applicable Federal Law, State Law and/or Public Utility Commission order.",
          margin: [0, 0, 0, 10],
        },
        {
          fontSize: 11,
          alignment: "justify",
          text: "Please submit an estimate of all engineering costs as soon as possible. At that time an engineering agreement will be prepared for your signature. Payment for any costs incurred pursuant to this Authorization to Proceed cannot be made until an agreement is executed by your firm and the Department of Transportation. ",
          margin: [0, 0, 0, 10],
        },
        {
          fontSize: 11,
          text:
            "If you have any questions, please contact me at " +
            getValueOrEmptySpace(railroadDetails, "districtAdminPhoneNumber") +
            " or " +
            getValueOrEmptySpace(railroadDetails, "districtAdminEmail"),
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: "40%",
              text: "",
            },
            {
              width: "60%",
              fontSize: 11,
              text: "Sincerely, ",
              margin: [0, 20, 0, 10],
            },
          ],
        },
        {
          columns: [
            {
              width: "40%",
              text: "",
            },
            {
              width: "60%",
              fontSize: 11,
              text:
                getValueOrEmptySpace(
                  railroadDetails,
                  "districtAdminFirstName"
                ) +
                " " +
                getValueOrEmptySpace(railroadDetails, "districtAdminLastName"),
            },
          ],
        },
        {
          columns: [
            {
              width: "40%",
              text: "",
            },
            {
              width: "60%",
              fontSize: 11,
              text: getValueOrEmptySpace(
                railroadDetails,
                "districtAdminDisplayTitle"
              ),
            },
          ],
        },
        {
          columns: [
            {
              width: "40%",
              text: "",
            },
            {
              width: "60%",
              fontSize: 11,
              text:
                "District Grade Crossing Engineer " +
                getValueOrEmptySpace(railroadDetails, "districtNumber"),
            },
          ],
        },
      ]);
      pdfMake.vfs = vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-MediumItalic.ttf",
        },
        FontAwesomeSolid: {
          normal: "fa-solid-900.ttf",
          bold: "fa-solid-900.ttf",
          italics: "fa-solid-900.ttf",
          bolditalics: "fa-solid-900.ttf",
        },
        CustomFontAwesome: {
          normal: "fontawesome-regular-checkboxes.ttf",
          bold: "fontawesome-regular-checkboxes.ttf",
          italics: "fontawesome-regular-checkboxes.ttf",
          bolditalics: "fontawesome-regular-checkboxes.ttf",
        },
        OpenSans: {
          normal: "OpenSans-Regular.ttf",
          bold: "OpenSans-Medium.ttf",
          italics: "OpenSans-Italic.ttf",
          bolditalics: "OpenSans-Italic.ttf",
        },
      };
      let content = {
        content: pdfData,
        styles: {
          header: {
            fontSize: 18,
            bold: true,
          },
          bigger: {
            fontSize: 15,
            italics: true,
          },
          pdfHeader: {
            fontSize: 15,
            decoration: "underline",
            alignment: "center",
            bold: true,
          },
        },
        footer: function () {
          return [
            {
              canvas: [
                {
                  type: "line",
                  x1: 0,
                  y1: 0,
                  x2: 1020,
                  y2: 0,
                  lineWidth: 1,
                },
              ],
            },
            {
              margin: [0, 5, 0, 0],
              alignment: "center",
              fontSize: 8,
              text:
                "Engineering District " +
                getValueOrEmptySpace(railroadDetails, "districtNumber") +
                " | " +
                getValueOrEmptySpace(
                  railroadDetails,
                  "districtOfficeAddress1"
                ) +
                " | " +
                getValueOrEmptySpace(railroadDetails, "districtOfficeCity") +
                ", " +
                getValueOrEmptySpace(railroadDetails, "districtOfficeState") +
                " " +
                getValueOrEmptySpace(railroadDetails, "districtOfficeZipCode") +
                " | " +
                getValueOrEmptySpace(
                  railroadDetails,
                  "districtOfficePhoneNumber"
                ) +
                " | www.penndot.gov",
            },
          ];
        },
        defaultStyle: {
          columnGap: 5,
        },
      };
      const pdfDocGenerator = pdfMake.createPdf(content);
      await pdfDocGenerator.getBase64((data) => {
        if (isSendAll) {
          setSendAllPdf(data, railroadDetails, sendAllIndex);
        } else {
          let payload = [
            {
              mpmsNumber: id,
              railroadCompanyCode: railroadDetails.railRoadCompanyCode,
              railroadCompanyId: railroadDetails.railRoadCompanyId,
              fileData: data,
              metaData: {
                docType: "Engineering Authorization",
                filename:
                  "Engineering Authorization " +
                  railroadDetails.railRoad +
                  ".pdf",
                description: "Engineering Authorization",
                mpmsNumber: id,
                railRoadName: railroadDetails.railRoad,
                railroadCompanyId: railroadDetails.railRoadCompanyId,
              },
            },
          ];
          postPdf(payload);
        }
      });
    };
    const setSendAllPdf = (pdfFile, railroadDetails, index) => {
      sendAllList.value.push({
        mpmsNumber: id,
        railroadCompanyCode: railroadDetails.railRoadCompanyCode,
        railroadCompanyId: railroadDetails.railRoadCompanyId,
        fileData: pdfFile,
        metaData: {
          docType: "Engineering Authorization",
          filename:
            "Engineering Authorization " + railroadDetails.railRoad + ".pdf",
          description: "Engineering Authorization",
          mpmsNumber: id,
          railRoadName: railroadDetails.railRoad,
          railroadCompanyId: railroadDetails.railRoadCompanyId,
        },
      });
      if (index === filteredList.value.length - 1) {
        postPdf(sendAllList.value);
      }
    };
    const BannerUpdate = (event) => {
      if (event?.bannerType === "success") {
        SuccessBannerData.value = event;
        emit("BannerUpdate", SuccessBannerData.value);
      } else if (event?.bannerType === "error") {
        ErrorBannerData.value = event;
        emit("BannerUpdate", ErrorBannerData.value);
      }
      scroll(0, 0);
    };
    const hideSuccessBanner = () => {
      SuccessBannerData.value = null;
    };
    const hideErrorBannerMessage = () => {
      ErrorBannerData.value = null;
    };
    return {
      headers,
      id,
      userData,
      notificationList,
      linkClicked,
      fetchData,
      route,
      sendEngineeringAuth,
      sendAllEngineeringAuth,
      loading,
      SuccessBannerData,
      ErrorBannerData,
      hideSuccessBanner,
      hideErrorBannerMessage,
      sendNTP,
      sendAllNTP,
      isUserAccess,
    };
  },
};
