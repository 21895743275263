export const RBAC_CONTROLLER = {
  ADVANCED_SEARCH_PAGE_READ_ACCESS: "ADVANCED_SEARCH_PAGE_READ_ACCESS",
  RAIL_ROAD_ESTIMATE_READ_ACCESS: "RAIL_ROAD_ESTIMATE_READ_ACCESS",
  RAIL_ROAD_ESTIMATE_CRTUPT_ACCESS: "RAIL_ROAD_ESTIMATE_CRTUPT_ACCESS",
  CREATE_PROJECT_ACCESS: "CREATE_PROJECT_ACCESS",
  MAINTAIN_PROJECT_PRIORITY_CRTUPD_ACCESS:
    "MAINTAIN_PROJECT_PRIORITY_CRTUPD_ACCESS",
  PROJECT_ONHOLD_BUTTON_READ_ACCESS: "PROJECT_ONHOLD_BUTTON_READ_ACCESS",
  PROJECT_ONHOLD_BUTTONCRTUPDT_ACCESS: "PROJECT_ONHOLD_BUTTONCRTUPDT_ACCESS",
  PROJECT_REOPEN_BUTTON_ACCESS: "PROJECT_REOPEN_BUTTONCRTUPDT_ACCESS",
  MANAGE_FLAGGER_RESOURCES_READ_ACCESS: "MANAGE_FLAGGER_RESOURCES_READ_ACCESS",
  DISTRICT_GRADE_CROSSING_INSPECTIONS_READ_ACCESS:
    "DISTRICT_GRADE_CROSSING_INSPECTIONS_READ_ACCESS",
  DISTRICT_GRADE_CROSSING_INSPECTIONS_CRTUPD_ACCESS:
    "DISTRICT_GRADE_CROSSING_INSPECTIONS_CRTUPD_ACCESS",
  MANUALLY_MODIFY_PROJECT_STATUS_CRTUPD_ACCESS:
    "MANUALLY_MODIFY_PROJECT_STATUS_CRTUPD_ACCESS",
  PROJECTCMNTS_SCOPINGFIELDVIEWCOMMENTS_READ_ACCESS:
    "PROJECTCMNTS_SCOPINGFIELDVIEWCOMMENTS_READ_ACCESS",
  PROJECTCMNTS_SCOPINGFIELDVIEWCOMMENTS_CRTUPD_ACCESS:
    "PROJECTCMNTS_SCOPINGFIELDVIEWCOMMENTS_CRTUPD_ACESS",
  PA1CALL_READ_ACCESS: "PA1CALL_READ_ACCESS",
  PA1CALL_CRTUPD_ACCESS: "PA1CALL_CRTUPD_ACCESS",
  COMMON_PROJECT_INFORMTAION_READ_ACCESS:
    "COMMON_PROJECT_INFORMTAION_READ_ACCESS",
  PROJECT_FOLLOW_CRTUPD_ACCESS: "PROJECT_FOLLOW_CRTUPD_ACCESS",
  PROJECT_CROSSINGS_AND_BRIDGES_READ_ACCESS:
    "PROJECT_CROSSINGS_AND_BRIDGES_READ_ACCESS",
  PROJECT_CROSSINGS_AND_BRIDGES_CRTUPD_ACCESS:
    "PROJECT_CROSSINGS_AND_BRIDGES_CRTUPD_ACCESS",
  PROJECT_COST_FUNDING_READ_ACCESS: "PROJECT_COST_FUNDING_READ_ACCESS",
  PROJECTDESIGNCONSTRUCTION_READ_ACCESS:
    "PROJECTDESIGNCONSTRUCTION_READ_ACCESS",
  ADD_RAILROAD_PROJECT_PAGE_CRTUPD_ACCESS:
    "ADD_RAILROAD_PROJECT_PAGE_CRTUPD_ACCESS",
  PROJECT_FLAGGER_NEEDS_PAGE_READ_ACCESS:
    "PROJECT_FLAGGER_NEEDS_PAGE_READ_ACCESS",
  PROJECT_FLAGGER_NEEDS_PAGE_CRTUPD_ACCESS:
    "PROJECT_FLAGGER_NEEDS_PAGE_CRTUPD_ACCESS",
  ADDEDIT_FLAGGERACTIVITIES_CRTUPD_ACCESS:
    "ADDEDIT_FLAGGERACTIVITIES_CRTUPD_ACCESS",
  SAFETY_MILESTONES_PAGE_READ_ACCESS: "SAFETY_MILESTONES_PAGE_READ_ACCESS",
  SAFETY_MILESTONES_PAGE_CRTUPD_ACCESS: "SAFETY_MILESTONES_PAGE_CRTUPD_ACCESS",
  SAFETY_PROJECTS_CONTRACTOR_BID_PAGE_READ_ACCESS:
    "SAFETY_PROJECTS_CONTRACTOR_BID_PAGE_READ_ACCESS",
  SAFETY_PROJECTS_CONTRACTOR_BID_PAGE_CRTUPD_ACCESS:
    "SAFETY_PROJECTS_CONTRACTOR_BID_PAGE_CRTUPD_ACCESS",
  PROJECT_INVOLVEMENT_PAGE_READ_ACCESS: "PROJECT_INVOLVEMENT_PAGE_READ_ACCESS",
  PROJECT_INVOLVEMENT_PAGE_CRTUPD_ACCESS:
    "PROJECT_INVOLVEMENT_PAGE_CRTUPD_ACCESS",
  PROJECT_MILESTONES_PAGE_READ_ACCESS: "PROJECT_MILESTONES_PAGE_READ_ACCESS",
  PROJECT_MILESTONES_PAGE_CRTUPD_ACCESS:
    "PROJECT_MILESTONES_PAGE_CRTUPD_ACCESS",
  WORKFLOW_NOTIFICATIONS_PAGE_READ_ACCESS:
    "WORKFLOW_NOTIFICATIONS_PAGE_READ_ACCESS",
  WORKFLOW_NOTIFICATIONS_PAGE_CRTUPD_ACCESS:
    "WORKFLOW_NOTIFICATIONS_PAGE_CRTUPD_ACCESS",
  PENNDOT_PROJECTTEAM_PAGE_READ_ACCESS: "PENNDOT_PROJECTTEAM_PAGE_READ_ACCESS",
  PENNDOT_PROJECTTEAM_PAGE_CRTUPD_ACCESS:
    "PENNDOT_PROJECTTEAM_PAGE_CRTUPD_ACCESS",
  RAILROAD_PROJECTTEAM_PAGE_READ_ACCESS:
    "RAILROAD_PROJECTTEAM_PAGE_READ_ACCESS",
  RAILROAD_PROJECTTEAM_PAGE_CRTUPD_ACCESS:
    "RAILROAD_PROJECTTEAM_PAGE_CRTUPD_ACCESS",
  PARTIES_RECORD_PAGE_READ_ACCESS: "PARTIES_RECORD_PAGE_READ_ACCESS",
  PARTIES_RECORD_PAGE_CRTUPD_ACCESS: "PARTIES_RECORD_PAGE_CRTUPD_ACCESS",
  HWYBRDG_PUC_COMMUNICATIONS_PAGE_READ_ACCESS:
    "HWYBRDG_PUC_COMMUNICATIONS_PAGE_READ_ACCESS",
  HWYBRDG_PUC_COMMUNICATIONS_PAGE_CRTUPD_ACCESS:
    "HWYBRDG_PUC_COMMUNICATIONS_PAGE_CRTUPD_ACCESS",
  PROJECT_HISTORY_PAGE_READ_ACCESS: "PROJECT_HISTORY_PAGE_READ_ACCESS",
  RAILROAD_PLAN_REVIEW_PAGE_READ_ACCESS:
    "RAILROAD_PLAN_REVIEW_PAGE_READ_ACCESS",
  RAILROAD_PLAN_REVIEW_PAGE_CRTUPD_ACCESS:
    "RAILROAD_PLAN_REVIEW_PAGE_CRTUPD_ACCESS",
  RAILROADESTIMATES_PAGE_READ_ACCESS: "RAILROADESTIMATES_PAGE_READ_ACCESS",
  RAILROADESTIMATES_PAGE_CRTUPD_ACCESS: "RAILROADESTIMATES_PAGE_CRTUPD_ACCESS",
  ACCEPT_ESTIMATE_BUTTON_PAGE_ACCESS: "ACCEPT_ESTIMATE_BUTTON_PAGE_ACCESS",
  RAILROAD_CERTIFICATIONS_PAGE_READ_ACCESS:
    "RAILROAD_CERTIFICATIONS_PAGE_READ_ACCESS",
  RAILROAD_CERTIFICATIONS_PAGE_CRTUPD_ACCESS:
    "RAILROAD_CERTIFICATIONS_PAGE_CRTUPD_ACCESS",
  GENERATE_RAILROAD_CERTIFICATIONS_READ_ACCESS:
    "GENERATE_RAILROAD_CERTIFICATIONS_READ_ACCESS",
  GENERATE_RAILROAD_CERTIFICATIONS_UPDATE_ACCESS:
    "GENERATE_RAILROAD_CERTIFICATIONS_UPDATE_ACCESS",
  PREVIEW_RAILROAD_CERTIFICATIONS_READ_ACCESS:
    "PREVIEW_RAILROAD_CERTIFICATIONS_READ_ACCESS",
  VIEW_CONDITIONAL_CERTIFICATIONS_LIST_READ_ACCESS:
    "VIEW_CONDITIONAL_CERTIFICATIONS_LIST_READ_ACCESS",
  VIEW_CONDITIONAL_CERTIFICATIONS_LIST_CRTUPD_ACCESS:
    "VIEW_CONDITIONAL_CERTIFICATIONS_LIST_CRTUPD_ACCESS",
  RAILROAD_WORK_SUMMARY_READ_ACCESS: "RAILROAD_WORK_SUMMARY_READ_ACCESS",
  RAILROAD_WORK_SUMMARY_PAGE_CRTUPD_ACCESS:
    "RAILROAD_WORK_SUMMARY_PAGE_CRTUPD_ACCESS",
  GRADECROSSINGINVENTORYLIST_READ_ACCESS:
    "GRADECROSSINGINVENTORYLIST_READ_ACCESS",
  GRADE_CROSSING_RECORD_READ_ACCESS: "GRADE_CROSSING_RECORD_READ_ACCESS",
  GRADE_CROSSING_RECORD_CRTUPD_ACCESS: "GRADE_CROSSING_RECORD_CRTUPD_ACCESS",
  MANAGE_RAILROAD_COMPANIES_READ_ACCESS:
    "MANAGE_RAILROAD_COMPANIES_READ_ACCESS",
  MANAGE_RAILROAD_COMPANIES_CRTUPD_ACCESS:
    "MANAGE_RAILROAD_COMPANIES_CRTUPD_ACCESS",
  ASSIGN_DISTRICTS_READ_ACCESS: "ASSIGN_DISTRICTS_READ_ACCESS",
  ASSIGN_DISTRICTS_CRTUPD_ACCESS: "ASSIGN_DISTRICTS_CRTUPD_ACCESS",
  DISTRICT_OFFICE_READ_ACCESS: "DISTRICT_OFFICE_READ_ACCESS",
  DISTRICT_OFFICE_CRTUPD_ACCESS: "DISTRICT_OFFICE_CRTUPD_ACCESS",
  TEMPLATE_SIGNATURE_PAGE_READ_ACCESS: "TEMPLATE_SIGNATURE_PAGE_READ_ACCESS",
  TEMPLATE_SIGNATURE_PAGE_CRTUPD_ACCESS:
    "TEMPLATE_SIGNATURE_PAGE_CRTUPD_ACCESS",
  PROJECT_DOCUMENTS_PAGE_READ_ACCESS: "PROJECT_DOCUMENTS_PAGE_READ_ACCESS",
  PROJECT_DOCUMENTS_PAGE_CRTUPD_ACCESS: "PROJECT_DOCUMENTS_PAGE_CRTUPD_ACCESS",
  COMMON_PROJECT_INFORMATION_READ_ACCESS:
    "COMMON_PROJECT_INFORMATION_READ_ACCESS",
  COMMON_PROJECT_INFORMATION_CRTUPD_ACCESS:
    "COMMON_PROJECT_INFORMATION_CRTUPD_ACCESS",
  MPMS_OVERNIGHT_PROCESS_ACCESS: "MPMS_OVERNIGHT_PROCESS_ACCESS",
  STATUS_TRANSITION_ACTIONS_READ_ACCESS:
    "STATUS_TRANSITION_ACTIONS_READ_ACCESS",
  STATUS_TRANSITION_ACTIONS_CRTUPD_ACCESS:
    "STATUS_TRANSITION_ACTIONS_CRTUPD_ACCESS",
};
