import useMapFunctions from "@/composables/gisMap";import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import AddToImpactedList from "@/modules/grade-crossing-and-bridges/components/AddToImpactedList.vue";
import ProjectBridges from "@/modules/grade-crossing-and-bridges/components/ProjectBridges.vue";
import DraggableTable from "@/modules/shared/components/DraggableTable.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import DialogComponent from "@/modules/shared/components/SharedConfirmationDialog.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import router from "@/router";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { useRefreshDataStore } from "../../../stores/dataResresh";
export default {
  name: "GradeCrossingAndBridges",
  components: {
    VueTable,
    AddToImpactedList,
    ProjectBridges,
    DraggableTable,
    LoadingOverlay,
    DialogComponent,
  },
  props: ["id", "panel"],
  emits: ["refreshMpmsData"],
  setup(props, { emit }) {
    const userRefreshDataStore = useRefreshDataStore();
    const checkboxConfirmationDialog = ref(null);
    const { navigateToGISMap } = useMapFunctions();
    let estimatedProjectFunding = ref([]);
    const key = ref("dotNumber");
    const id = ref(props?.id);
    const loading = ref(true);
    const NoRailroadInvolvementDisabled = ref();
    const noRailroadInvolvement = ref();
    let pendingNewCrossing = ref();
    let fedStatusDisabled = ref(false);
    let list1 = ref([]);
    let list2 = ref([]);
    let list3 = ref([]);
    let panel = ref([0, 1]);
    let dragDisableCheck = ref([
      { key: "sourceid", value: ["Within Vicinity"] },
      { key: "railroadCompanyCount", value: 0 },
    ]);
    let otherEventsPerformed = ref(false);
    const table1Header = ref([
      {
        name: "DOT#",
        key: "dotNumber",
        isLink: true,
        showLinkIndicator: true,
        alignLeft: true,
        columnWidth: "10",
        disabled: false,
      },
      { name: "RR Owner", key: "xingownr", columnWidth: "10" },
      { name: "BR-Key", key: "bmskey", columnWidth: "8" },
      { name: "RR Milepost", key: "milepost", columnWidth: "10" },
      { name: "Crossing Status", key: "crossingstatus", columnWidth: "10" },
      { name: "Crossing Position", key: "posxing", columnWidth: "10" },
      { name: "Lat/Long", key: "latlong" },
      {
        name: "Source",
        key: "sourceid",
        columnWidth: "11",
      },
      {
        name: "Action",
        key: "",
        isAction: true,
        isDelete: true,
        deleteButtonText: "Remove",
        deleteButtonCheckKey: "showDeleteRowButton",
        isDeleteButtonDisabledKey: "deleteButtonDisabled",
        isRowAdd: true,
        addButtonText: "Remove",
        addButtonCheckKey: "showAddRowButton",
        columnWidth: "8",
        disabled: false,
      },
      {
        name: "View on Map",
        key: "streetViewLink",
        isLink: true,
        isUnderline: true,
        columnWidth: "8",
        disabled: false,
      },
    ]);
    const table2Header = ref([
      {
        name: "DOT#",
        key: "dotNumber",
        isLink: true,
        showLinkIndicator: true,
        alignLeft: true,
        columnWidth: "10",
        disabled: false,
      },
      { name: "RR Owner", key: "xingownr", columnWidth: "10" },
      { name: "BR-Key", key: "bmskey", columnWidth: "8" },
      { name: "RR Milepost", key: "milepost", columnWidth: "10" },
      { name: "Crossing Status", key: "crossingstatus", columnWidth: "10" },
      { name: "Crossing Position", key: "posxing", columnWidth: "10" },
      { name: "Lat/Long", key: "latlong" },
      {
        name: "Action",
        key: "",
        isAction: true,
        isDelete: true,
        deleteButtonText: "Remove",
        deleteButtonCheckKey: "showDeleteRowButton",
        isDeleteButtonDisabledKey: "deleteButtonDisabled",
        isRowAdd: true,
        addButtonText: "Add To Impacted",
        addButtonCheckKey: "showAddRowButton",
        isRowOther: true,
        otherButtonText: "Add To Nearby",
        otherButtonCheckKey: "showOtherRowButton",
        columnWidth: "8",
        disabled: false,
      },
      {
        name: "View on Map",
        key: "streetViewLink",
        isLink: true,
        isUnderline: true,
        columnWidth: "8",
        disabled: false,
      },
    ]);
    const table3Header = ref([
      {
        name: "DOT#",
        key: "dotNumber",
        isLink: true,
        showLinkIndicator: true,
        alignLeft: true,
        columnWidth: "10",
        disabled: false,
      },
      { name: "RR Owner", key: "xingownr", columnWidth: "10" },
      { name: "BR-Key", key: "bmskey", columnWidth: "8" },
      { name: "RR Milepost", key: "milepost", columnWidth: "10" },
      { name: "Crossing Status", key: "crossingstatus", columnWidth: "10" },
      { name: "Crossing Position", key: "posxing", columnWidth: "10" },
      { name: "Lat/Long", key: "latlong" },
      {
        name: "Source",
        key: "sourceid",
        columnWidth: "11",
      },
      {
        name: "Action",
        key: "",
        isAction: true,
        isDelete: true,
        deleteButtonText: "Remove",
        deleteButtonCheckKey: "showDeleteRowButton",
        isDeleteButtonDisabledKey: "deleteButtonDisabled",
        isRowAdd: true,
        addButtonText: "Remove",
        addButtonCheckKey: "showAddRowButton",
        columnWidth: "10",
        disabled: false,
      },
      {
        name: "View on Map",
        key: "streetViewLink",
        isLink: true,
        isUnderline: true,
        columnWidth: "8",
        disabled: false,
      },
    ]);
    const isUserAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const accessCheck = async () => {
      const readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_CROSSINGS_AND_BRIDGES_READ_ACCESS
      );
      const updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_CROSSINGS_AND_BRIDGES_CRTUPD_ACCESS
      );
      isUserAccess.value.read = readResponse || false;
      isUserAccess.value.update = updateResponse || false;
      table1Header.value.forEach((header) => {
        if (header.key === "Action") {
          header.disabled = !isUserAccess.value.update;
        }
        if (header.key === "dotNumber" || header.key === "streetViewLink") {
          header.disabled = !isUserAccess.value.read;
        }
      });
      table2Header.value.forEach((header) => {
        if (header.key === "Action") {
          header.disabled = !isUserAccess.value.update;
        }
        if (header.key === "dotNumber" || header.key === "streetViewLink") {
          header.disabled = !isUserAccess.value.read;
        }
      });
      table3Header.value.forEach((header) => {
        if (header.key === "Action") {
          header.disabled = !isUserAccess.value.update;
        }
        if (header.key === "dotNumber" || header.key === "streetViewLink") {
          header.disabled = !isUserAccess.value.read;
        }
      });
    };
    watch(
      () => [props],
      () => {
        if (props.id) {
          id.value = props.id;
          panel.value = [0, 1];
          getCrossingList();
        }
        if (props.panel) {
          panel.value = props.panel;
        }
      },
      { deep: true }
    );
    watch(
      () => [userRefreshDataStore.refreshTabsData],
      () => {
        if (userRefreshDataStore.refreshTabsData) {
          getCrossingList();
          userRefreshDataStore.setRefreshData(false);
        }
      }
    );
    onMounted(async () => {
      id.value = props.id;
      await accessCheck();
      if (isUserAccess.value.read) {
        await getCrossingList();
      }
    });
    const refreshAddToImpactedList = async () => {
      await getCrossingList();
    };
    const updateOtherEventsPerformed = () => {
      otherEventsPerformed.value = false;
    };
    async function getCrossingList() {
      loading.value = true;
      try {
        const response = await axios.get(
          CONFIG_ENDPOINT.GCB_CROSSING + "?mpms=" + id.value
        );
        noRailroadInvolvement.value = response.data.noRailroadInvolvement;
        pendingNewCrossing.value = response.data.pendingNewCrossing;
        fedStatusDisabled.value = response.data.fedStatusDisabled;
        list1.value = processCrossingData(
          response.data?.projectCrossingDTO,
          "Impacted"
        );
        NoRailroadInvolvementDisabled.value = list1.value?.length > 0;
        list3.value = processCrossingData(
          response.data?.projectCrossingDTO,
          "Nearby"
        );
        list2.value = processCrossingData(
          response.data?.projectCrossingDTO,
          "Non-Impacted"
        );
        loading.value = false;
      } catch (err) {
        console.error("Error fetching data:", err);
        list1.value = [];
        list2.value = [];
        list3.value = [];
        loading.value = false;
      }
    }
    function processCrossingData(data, impactType) {
      return data
        ?.filter((item) => item.impacted === impactType)
        ?.map((item) => ({
          ...item,
          streetViewLink: "streetViewLink",
          showAddRowButton: checkDraggable(item),
          showOtherRowButton: checkDraggable(item),
          showDeleteRowButton:
            item?.impacted === "Non-Impacted"
              ? !hideWithinVicinityDeleteButton(item)
              : !checkDraggable(item),
          deleteButtonDisabled: !iconButtonsDisabled(item),
          showLinkIndicator: item?.railroadCompanyCount > 0,
        }));
    }
    const addToImpactedList = (event, impactType) => {
      updateCrossing(impactType, event);
    };
    const addToVicinityList = (event) => {
      updateCrossing("Non-Impacted", event);
    };
    const updateCrossing = (impacted, item) => {
      if (isUserAccess.value.update) {
        axios
          .put(CONFIG_ENDPOINT.GCB_CROSSING_PUT + impacted, item)
          .then(() => {
            otherEventsPerformed.value = true;
            getCrossingList();
          })
          .catch(() => {
            otherEventsPerformed.value = true;
            getCrossingList();
          });
      }
    };
    const deleteClicked = (event, tableType = "Impacted") => {
      let dotNumber;
      if (tableType === "Nearby" && list3.value[event]) {
        dotNumber = list3.value[event].dotNumber;
      } else if (tableType === "Impacted" && list1.value[event]) {
        dotNumber = list1.value[event].dotNumber;
      } else if (tableType === "Vicinity" && list2.value[event]) {
        dotNumber = list2.value[event].dotNumber;
      } else {
        console.error(`Invalid tableType provided: ${tableType}`);
        return;
      }
      if (!dotNumber) {
        console.error("DOT number is undefined or null");
        return;
      }
      if (isUserAccess.value.update) {
        axios
          .delete(
            CONFIG_ENDPOINT.GCB_CROSSING + "/" + dotNumber + "?mpms=" + id.value
          )
          .then(() => {
            otherEventsPerformed.value = true;
            getCrossingList();
          })
          .catch(() => {
            otherEventsPerformed.value = true;
            getCrossingList();
          });
      }
    };
    const linkClicked = (event) => {
      if (event.key === "streetViewLink") {
        navigateToGISMap("crossing", event.value.dotNumber);
      } else {
        window.scrollTo(0, 0);
        router.push({ path: "/GCMS/DOT/" + event.value });
      }
    };
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    const yesClicked = async (event) => {
      if (event) {
        loading.value = true;
        let payload = {
          mpmsNumber: id.value,
          userid: userData.value?.userID,
          metaData: {
            docType: "Railroad Certification",
            filename: null,
            description: "Railroad Certification",
            mpmsNumber: id.value,
          },
        };
        try {
          await axios.post(
            CONFIG_ENDPOINT.NO_RAILROAD_INVOLVEMENT_GRADE_CROSSING_STATUS_CHANGE +
              id.value +
              "?noRailroadInvolvement=true",
            payload,
            {
              headers: {
                userId: userData.value?.userID,
                password: userData.value?.userID,
              },
            }
          );
          otherEventsPerformed.value = true;
          getCrossingList();
          emit("refreshMpmsData", true);
        } catch (error) {
          otherEventsPerformed.value = true;
          console.error("Error during API call:", error);
          getCrossingList();
        } finally {
          otherEventsPerformed.value = true;
          loading.value = false;
        }
      }
    };
    const hideWithinVicinityDeleteButton = (item) => {
      return item?.["sourceid"] === "Within Vicinity";
    };
    const iconButtonsDisabled = (item) => {
      if (item?.["sourceid"] === "MPMS") {
        return false;
      }
      let disableCheck = null;
      dragDisableCheck.value.forEach((dragOption) => {
        let isDisabled = false;
        if (dragOption.key === "sourceid") {
          isDisabled = !dragOption.value.includes(item?.[dragOption.key]);
        } else {
          isDisabled = item?.[dragOption.key] === dragOption.value;
        }
        if (disableCheck == null) {
          disableCheck = isDisabled;
        } else {
          disableCheck = disableCheck && isDisabled;
        }
      });
      return disableCheck;
    };
    const checkDraggable = (item) => {
      let dragCheck = null;
      dragDisableCheck.value.forEach((dragOption) => {
        let isDraggable = false;
        if (
          (dragOption.key === "sourceid" &&
            dragOption.value.includes(item?.[dragOption.key])) ||
          item?.[dragOption.key] === dragOption.value
        ) {
          isDraggable = true;
        }
        if (dragCheck == null) {
          dragCheck = isDraggable;
        } else {
          dragCheck = dragCheck && isDraggable;
        }
      });
      return dragCheck;
    };
    const updateNoRailroadInvolvement = (event) => {
      if (event) {
        checkboxConfirmationDialog.value
          .open("Warning", [
            "WARNING: By selecting 'No Railroad Involvement' you are certifying there are no railroad crossings and/or railroad tracks impacted by the limits of the project. A letter certifying there is no railroad involvement will be generated and sent to your District Contract Management Unit for inclusion into the ECMS contract.",
            "Click OK to continue and set the Project Status to Completed.",
            "Click Cancel to return to the page and clear the No Railroad Involvement checkbox.",
          ])
          .then((resolve) => {
            if (resolve) {
              yesClicked(true);
            } else {
              getCrossingList();
            }
          });
      }
    };
    const toggleProjectPendingNewCrossing = async (event) => {
      if (!isUserAccess.value.update) {
        return;
      }
      loading.value = true;
      try {
        await axios.post(
          CONFIG_ENDPOINT.TOGGLE_PROJECT_PENDING_NEW_CROSSING +
            id.value +
            "?pendingNewCrossing=" +
            event
        );
        otherEventsPerformed.value = true;
        getCrossingList();
        emit("refreshMpmsData", true);
      } catch (error) {
        otherEventsPerformed.value = true;
        console.error("Error during API call:", error);
        getCrossingList();
      } finally {
        otherEventsPerformed.value = true;
        loading.value = false;
      }
    };
    return {
      updateNoRailroadInvolvement,
      estimatedProjectFunding,
      key,
      list1,
      list2,
      panel,
      table1Header,
      table2Header,
      id,
      dragDisableCheck,
      addToImpactedList,
      addToVicinityList,
      updateCrossing,
      deleteClicked,
      refreshAddToImpactedList,
      linkClicked,
      loading,
      yesClicked,
      NoRailroadInvolvementDisabled,
      noRailroadInvolvement,
      list3,
      table3Header,
      checkboxConfirmationDialog,
      isUserAccess,
      updateOtherEventsPerformed,
      otherEventsPerformed,
      pendingNewCrossing,
      toggleProjectPendingNewCrossing,
      fedStatusDisabled,
    };
  },
};
