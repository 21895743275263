<template>
  <v-card class="p-64">
    <v-card-text>
      <div class="page-title">
        <h1>Grade Crossing Projects</h1>
      </div>
      <FeatureTable
        :headers="headers"
        :items="activeProjects"
        :showExportButton="false"
        :exportFilName="''"
        :showClearFilterButton="true"
        :tableHeader="''"
        :navigateToMpmsDetails="true"
        :clickable="true"
        :navigationKey="'mpmsNumber'"
        :itemsPerPage="paginationData?.items"
        :linkedPagination="true"
        :totalItems="paginationData?.total"
        :lookupData="lookupData"
        :oldMultipleFilter="multipleFilter"
        :showHistorical="true"
        :showLegends="true"
        @updatePaginationPerPage="updatePaginationPerPage"
        @pageChange="pageChange"
        @sortUpdate="sortUpdate"
        @textFilterChange="textFilterChange"
        @multipleFiltersChange="multipleFiltersChange"
        @clearAllFilter="clearAllFilter"
        @linkClicked="linkClicked($event)"

      >
      </FeatureTable>
      <br><br>

    </v-card-text>
    <DialogComponent ref="dialogComponent"></DialogComponent>
  </v-card>
</template>

<script>
import GradeCrossingProjects from "@/modules/dot-details/scripts/GradeCrossingProjects.js";
export default GradeCrossingProjects;
</script>
