import { RBAC_CONTROLLER } from "@/constants/rbacconstants";import AuthorizationMilestones from "@/modules/manageproject/components/AuthorizationMilestones.vue";
import ConstructionMilestones from "@/modules/manageproject/components/ConstructionMilestones.vue";
import ContractDevelopmentMilestones from "@/modules/manageproject/components/ContractDevelopmentMilestones.vue";
import CoordinationMilestones from "@/modules/manageproject/components/CoordinationMilestones.vue";
import ScopingMilestones from "@/modules/manageproject/components/ScopingMilestones.vue";
import ViewUploadSafetyMilestoneDocuments from "@/modules/manageproject/components/ViewUploadSafetyMilestoneDocuments.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useProjectServiceStore } from "@/stores/projectService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";

import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "SafetyMilestones",
  components: {
    ScopingMilestones,
    PrimaryButton,
    SecondaryButton,
    ViewUploadSafetyMilestoneDocuments,
    AuthorizationMilestones,
    CoordinationMilestones,
    ContractDevelopmentMilestones,
    ConstructionMilestones,
    BannerMessage,
  },
  props: ["id", "panel", "refreshData"],
  emits: ["saveMilestone", "updateRefreshFlag", "refreshMpmsData"],
  setup(props, { emit }) {
    const route = useRoute();
    const id = route.params.id;
    let panel = ref([0, 1, 2, 3, 4, 5]);
    let responseObject = ref({});
    let tab = ref("safetyMilestones");
    const enablePost = ref(true);
    const scoping = ref(null);

    const fetchScoping = ref(false);
    const fetchAuthorization = ref(false);
    const fetchCoordination = ref(false);
    const fetchContractDevelopment = ref(false);
    const fetchConstructionData = ref(false);

    const projectServiceStore = useProjectServiceStore();
    const showBanner = ref(false);

    const isUserMilestonesAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const milestonesAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.SAFETY_MILESTONES_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.SAFETY_MILESTONES_PAGE_CRTUPD_ACCESS
      );
      isUserMilestonesAccess.value.read = readResponse || false;
      isUserMilestonesAccess.value.update = updateResponse || false;
    };

    onMounted(async () => {
      await milestonesAccessCheck();
      if (!isUserMilestonesAccess.value.read) {
        fetchScoping.value = false;
        fetchAuthorization.value = false;
        fetchCoordination.value = false;
        fetchContractDevelopment.value = false;
        fetchConstructionData.value = false;
      }
    });

    const saveData = (object) => {
      let temp = Object.assign(responseObject.value, object);
      responseObject.value = temp;
    };

    async function postData() {
      let response = await projectServiceStore.postSafetyMilestones(
        route.params.id,
        responseObject.value
      );
      if (response) {
        responseObject.value = {};
        enablePost.value = true;
        showBanner.value = true;
        window.scroll(0, 0);

        emit("refreshMpmsData", true);
        updateScopingData(true);
        updateAuthorizationData(true);
        updateCoordinationData(true);
        updateContractDevelopmentData(true);
        updateConstruction(true);
      }
    }

    const hideSuccessBanner = () => {
      showBanner.value = false;
    };

    watch(
      () => [responseObject.value],
      () => {
        if (Object.keys(responseObject.value).length !== 0) {
          enablePost.value = false;
        } else {
          enablePost.value = true;
        }
      },
      { deep: true }
    );
    watch(
      () => [tab.value],
      () => {
        if (tab.value == "safetyMilestones") {
          updateScopingData(true);
          updateAuthorizationData(true);
          updateCoordinationData(true);
          updateContractDevelopmentData(true);
          updateConstruction(true);
        }
      },
      { deep: true }
    );

    const updateScopingData = (flag) => {
      fetchScoping.value = flag;
    };

    const updateAuthorizationData = (flag) => {
      fetchAuthorization.value = flag;
    };

    const updateCoordinationData = (flag) => {
      fetchCoordination.value = flag;
    };

    const updateContractDevelopmentData = (flag) => {
      fetchContractDevelopment.value = flag;
    };

    const updateConstruction = (flag) => {
      fetchConstructionData.value = flag;
    };

    const clear = () => {
      updateScopingData(true);
      updateAuthorizationData(true);
      updateCoordinationData(true);
      updateContractDevelopmentData(true);
      updateConstruction(true);

      responseObject.value = {};
      enablePost.value = true;
    };
    const expandToggle = (type) => {
      if (type === "expand") {
        panel.value = [0, 1, 2, 3, 4, 5];
      } else {
        panel.value = [];
      }
    };

    return {
      id,
      panel,
      saveData,
      postData,
      enablePost,
      scoping,
      fetchScoping,
      fetchAuthorization,
      fetchContractDevelopment,
      fetchCoordination,
      fetchConstructionData,
      tab,
      clear,
      expandToggle,
      showBanner,
      hideSuccessBanner,
      isUserMilestonesAccess,
      updateScopingData,
      updateAuthorizationData,
      updateCoordinationData,
      updateContractDevelopmentData,
      updateConstruction,
    };
  },
};
