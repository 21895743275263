import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import VueTable from "@/modules/shared/components/VueTable.vue";
import axios from "axios";
import { onMounted, ref, watch } from "vue";

const TotalPennDot = ref(0);
const TotalRailroad = ref(0);
const EstimatedRailRoadCostData = ref([]);
const EstimatedRailRoadCostLookup = ref([]);
const tableData = ref([]);
const columnData = ref([
  { name: "Railroad", key: "railroadName", isBold: true, alignLeft: true },
  { name: "Agreement No", key: "agreementNumber" },
  { name: "Total PennDOT Costs", key: "totalPenndotCosts" },
  { name: "Total Railroad Costs", key: "totalRailroadCosts" },
  { name: "Status", key: "status" },
]);

export default {
  name: "EstimatedRailroadCost",
  components: { VueTable },
  props: {
    id: { type: Number, default: null },
  },
  setup(props) {
    onMounted(async () => {
      await getEstimatedRailRoadCostData();
    });

    watch(
      () => [props.id],
      () => {
        if (props.id) {
          getEstimatedRailRoadCostData();
        }
      },
      { deep: true }
    );

    async function getEstimatedRailRoadCostData() {
      await axios
        .get(
          CONFIG_ENDPOINT.COSTANDFUNDING_ESTIMATED_ESTIMATED_RAILROAD_COST +
            props.id
        )
        .then((response) => {
          tableData.value = response.data.estRailroads;
          TotalPennDot.value = 0.0;
          for (let i = 0; i < response.data.estRailroads?.length; i++) {
            TotalPennDot.value += tableData.value[i].totalPenndotCosts;
            TotalRailroad.value += tableData.value[i].totalRailroadCosts;
            tableData.value[i].totalPenndotCosts =
              "$" +
              parseFloat(tableData.value[i].totalPenndotCosts)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            tableData.value[i].totalRailroadCosts =
              "$" +
              parseFloat(tableData.value[i].totalRailroadCosts)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          tableData.value.push({
            railRoad: "TOTALS",
            agreementNo: "",
            totalPenndotCosts:
              "$" +
              parseFloat(TotalPennDot.value)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            totalRailroadCosts:
              "$" +
              parseFloat(TotalRailroad.value)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            status: "",
          });
        })
        .catch((err) => {
         
        });
    }
    return {
      EstimatedRailRoadCostData,
      columnData,
      tableData,
      EstimatedRailRoadCostLookup,
      TotalRailroad,
      TotalPennDot,
    };
  },
};
