<template>
  <v-form v-model="isFormValid">
    <v-card class="dot-part-form">
      <v-card-text v-if="PartVDetails">
        <div class="page-title">
          <h1>Public Highway Information</h1>
        </div>
        <div class="env-header mb-20">
          <h2 class="locationHeaderText">Highway Information</h2>
        </div>
        <div class="env-infp">
          <div class="env-content">
            <v-row>
              <v-col cols="6">
                <label for="highwaySystem" class="mpms-env-label"
                  ><v-row>
                    <v-col cols="1">1.</v-col>
                    <v-col cols="5">
                      <b class="textColumn required"
                        ><span>Highway System: </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        id="highwaySystem"
                        :rules="[requiredRule]"
                        :items="highwaySystem"
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO.highwaySystem
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label
                  for="functionalClassificationOfRoadArea"
                  class="mpms-env-label"
                  ><v-row>
                    <v-col cols="1">2.</v-col>
                    <v-col cols="5">
                      <b class="textColumn required"
                        ><span
                          >Functional Classification of Road at Crossing:
                        </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        id="functionalClassificationOfRoadArea"
                        :rules="[requiredRule]"
                        :items="functionalClassificationOfRoadArea"
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO
                            .functionalClassificationOfRoadArea
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                      <v-select
                        :rules="[requiredRule]"
                        :items="functionalClassificationOfRoad"
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO
                            .functionalClassificationOfRoad
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label for="crossingOnStateHighwaySystem" class="mpms-env-label"
                  ><v-row>
                    <v-col cols="1">3.</v-col>
                    <v-col cols="5">
                      <b class="textColumn required"
                        ><span>Is Crossing on State Highway System? </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        id="crossingOnStateHighwaySystem"
                        :rules="[requiredRule]"
                        :items="crossingOnStateHighwaySystem"
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO
                            .crossingOnStateHighwaySystem
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label for="highwaySpeedLimitMPH" class="mpms-env-label"
                  ><v-row>
                    <v-col cols="1">4.</v-col>
                    <v-col cols="5">
                      <b class="textColumn required"
                        ><span>Highway Speed Limit MPH: </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        id="highwaySpeedLimitMPH"
                        :rules="[requiredRule, maxHighwaySpeedLimitRule]"
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO
                            .highwaySpeedLimitMPH
                        "
                        outlined
                        @keydown="keydownAllowNumberOnly($event)"
                        :maxLength="3"
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                      <v-select
                        :rules="[requiredRule]"
                        :items="highwaySpeedLimitStatus"
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO
                            .highwaySpeedLimitStatus
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select></v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label for="lrsRouteID" class="mpms-env-label"
                  ><v-row>
                    <v-col cols="1">5.</v-col>
                    <v-col cols="5">
                      <b class="textColumn"
                        ><span>Linear Referencing system (LRS Route ID):</span>
                      </b>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        id="lrsRouteID"
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO.lrsRouteID
                        "
                        :maxLength="50"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label for="lrsMilepost" class="mpms-env-label"
                  ><v-row>
                    <v-col cols="1">6.</v-col>
                    <v-col cols="5">
                      <b class="textColumn"><span>LRS Milepost: </span></b>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        id="lrsMilepost"
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO.lrsMilepost
                        "
                        outlined
                        :maxLength="50"
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label for="annualAverageDailyTraffic" class="mpms-env-label"
                  ><v-row>
                    <v-col cols="1">7.</v-col>
                    <v-col cols="5">
                      <b class="textColumn"
                        >Annual Average Daily Traffic (AADT):</b
                      >
                    </v-col>
                    <v-col cols="4">
                      {{
                        PartVDetails.gradeCrossingPartFiveDTO
                          .annualAverageDailyTraffic
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label for="aadtYear" class="mpms-env-label"
                  ><v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="5">
                      <b class="textColumn">AADT Year:</b>
                    </v-col>
                    <v-col cols="5" id="aadtYear">
                      {{ PartVDetails.gradeCrossingPartFiveDTO.aadtYear }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label for="estimatedPercentTrucks" class="mpms-env-label"
                  ><v-row>
                    <v-col cols="1">8.</v-col>
                    <v-col cols="5">
                      <b class="textColumn">Estimated Percent Trucks:</b>
                    </v-col>
                    <v-col cols="5" id="estimatedPercentTrucks">
                      {{
                        PartVDetails.gradeCrossingPartFiveDTO
                          .estimatedPercentTrucks
                      }}%
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label for="regularlyUsedBySchoolBuses" class="mpms-env-label"
                  ><v-row>
                    <v-col cols="1">9.</v-col>
                    <v-col cols="5">
                      <b class="textColumn"
                        ><span>Regularly used by School Buses? </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        id="regularlyUsedBySchoolBuses"
                        :items="regularlyUsedBySchoolBuses"
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO
                            .regularlyUsedBySchoolBuses
                        "
                        @update:modelValue="
                          updateRegularlyUsedBySchoolBuses($event)
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                      <span
                        :class="{
                          required:
                            PartVDetails.gradeCrossingPartFiveDTO
                              .regularlyUsedBySchoolBuses === 'Yes',
                        }"
                      >
                        <span>Average Number Per Day:</span>
                      </span>
                      <v-text-field
                        v-if="
                          PartVDetails.gradeCrossingPartFiveDTO
                            .regularlyUsedBySchoolBuses != 'Yes'
                        "
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO
                            .averageNumberOfBusesPerDay
                        "
                        outlined
                        :maxLength="3"
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                      <v-text-field
                        v-if="
                          PartVDetails.gradeCrossingPartFiveDTO
                            .regularlyUsedBySchoolBuses === 'Yes'
                        "
                        :rules="[requiredRule]"
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO
                            .averageNumberOfBusesPerDay
                        "
                        outlined
                        :maxLength="3"
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label for="emergencyServicesRoute" class="mpms-env-label"
                  ><v-row>
                    <v-col cols="1">10.</v-col>
                    <v-col cols="5">
                      <b class="textColumn">
                        <span>Emergency Services Route: </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        id="emergencyServicesRoute"
                        :items="emergencyServicesRoute"
                        v-model="
                          PartVDetails.gradeCrossingPartFiveDTO
                            .emergencyServicesRoute
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
            </v-row>
            <ReasonForUpdate
              :class="{ PartVDetails: true }"
              :isFormValid="isFormValid"
              :reasonToUpdateList="reasonToUpdateList"
              :reasonForUpdate="
                PartVDetails.gradeCrossingPartFiveDTO.reasonForUpdate
              "
              @valueChange="valueChange($event)"
              @saveClicked="saveClicked()"
              @cancelClicked="cancelClicked()"
              :updatedDataFlagger="updatedDataFlagger"
              :isDisabled="
                !isUserGradeCrossingAccess.update ||
                !isFormValid ||
                !valueChanged
              "
            ></ReasonForUpdate>
            <v-col
              cols="6"
              v-if="
                updatedDataFlagger &&
                !PartVDetails.gradeCrossingPartFiveDTO.reasonForUpdate
              "
            >
              <label for="reasonForUpdate" class="mpms-env-label"
                ><v-row
                  ><v-col cols="1"> </v-col>
                  <v-col cols="5">
                    <b class="textColumn"><span></span></b>
                  </v-col>
                  <v-col cols="5">
                    <div class="errorRow">
                      <div class="error">
                        <div>Required</div>
                      </div>
                    </div>
                  </v-col></v-row
                ></label
              >
            </v-col>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-form>
</template>
<script>
import PartVPublicHwyInfo from "@/modules/dot-details/scripts/PartVPublicHwyInfo.js";
export default PartVPublicHwyInfo;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
