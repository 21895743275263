import {
  requiredRule,
  validDateFutureDate_Inspections,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import AddComments from "@/modules/projectinformation/components/AddComments.vue";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import moment from "moment";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave } from "vue-router";



export default {
  name: "App",
  components: {
    PrimaryButton,
    SecondaryButton,
    AddComments,
    DatePicker,
    FeatureTable,
  },
  props: ["dotId", "tab"],
  emits: ["showBanner", "updatePaginationPerPage", "pageChange"],
  setup(props, context) {
    const projectList = ref([]);
    const loading = ref(true);
    const finalValidation = ref(false);
    const commentMaxLength = ref(200);
    let today = moment(new Date()).format("YYYY-MM-DD");
    let valueChanged = ref(false);
    let isInitialised = ref(false);
    let originalValue = ref({
      inspectionDate: "",
      crossingChanges: "",
      inspectedBy: "",
      comments: "",
    });

    const newInspection = ref({
      inspectionDate: "",
      crossingChanges: "",
      inspectedBy: "",
      comments: "",
    });
    const headers = ref([
      {
        title: "Crossing Inspection Date",
        sortType: "date",
        key: "inspectionDate",
        width: "9%",
      },
      {
        title: "Crossing Changes",
        sortType: "text",
        key: "crossingChanges",
        width: "7%",
      },
      {
        title: "Inspected By",
        key: "inspectedBy",
        sortType: "text",
        width: "16%",
      },
      {
        title: "Comments",
        key: "comments",
        width: "50%",
        sortable: false,
      },
    ]);

    
    const isUserInspectionAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const inspectionAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.DISTRICT_GRADE_CROSSING_INSPECTIONS_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.DISTRICT_GRADE_CROSSING_INSPECTIONS_CRTUPD_ACCESS
      );
      isUserInspectionAccess.value.read = readResponse || false;
      isUserInspectionAccess.value.update = updateResponse || false;
      loading.value = false;
    };

    onMounted(async () => {

      await inspectionAccessCheck();
      if (isUserInspectionAccess.value.read){
        isInitialised.value = true;
        valueChanged.value = false;
        await fetchData();
      }
     
    });


    const preventNumericInput = ($event) => {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode > 47 && keyCode < 58) {
        $event.preventDefault();
      }
    };
    watch(
      () => [props.tab],
      () => {
        if (props.tab === "Inspections") {
          fetchData();
        }
      },
      { deep: true }
    );
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      let checkKeys = [
        "inspectionDate",
        "crossingChanges",
        "inspectedBy",
        "comments",
      ];
      for (const key of checkKeys) {
        if (
          (key === "inspectionDate" &&
            moment(x["inspectionDate"]).format("YYYY-MM-DD") !==
              moment(y["inspectionDate"]).format("YYYY-MM-DD")) ||
          x[key] !== y[key]
        ) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          next();
          valueChanged.value = false;
        } else {
          next(false);
        }
      } else {
        next();
        valueChanged.value = false;
      }
    });
    watch(
      () => [newInspection.value],
      () => {
        if (
          newInspection.value.crossingChanges == "Yes" &&
          newInspection.value.inspectedBy &&
          newInspection.value.inspectionDate &&
          newInspection.value.comments != ""
        ) {
          finalValidation.value = true;
        } else if (
          newInspection.value.crossingChanges == "No" &&
          newInspection.value.inspectedBy &&
          newInspection.value.inspectionDate
        ) {
          finalValidation.value = true;
        } else {
          finalValidation.value = false;
        }
        if (
          isInitialised.value &&
          !objectsAreSame(newInspection.value, originalValue.value)
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );

    const paginationData = ref({
      page: 1,
      items: 10,
      total: 0,
    });
    const sortData = ref({
      key: "inspectionDate",
      order: "desc",
    });

    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    async function fetchData() {
      loading.value = true;
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;

      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }

      axios
        .get(CONFIG_ENDPOINT.INSPECTIONS + dotId.value + filterText)
        .then((response) => {
          loading.value = false;
          projectList.value = response.data.data;
          paginationData.value.total = response.data.totalElements;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key),
            };
          });
        })
        .catch((err) => {
          loading.value = false;
          projectList.value = [];
          paginationData.value.total = response.data.totalElements || 0;
        });
    }
    const checkFieldDisabled = (key) => {
      return key === "comments";
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const showBannerMessage = (event) => {
      context.emit("showBanner", event);
    };
    const dotId = computed({
      get: () => {
        return props?.dotId;
      },
    });
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    const saveData = () => {
      let temp = newInspection.value.inspectionDate;
      let tempDate =
        temp.slice(5, 7) + "/" + temp.slice(8, 10) + "/" + temp.slice(0, 4);
      let responseObject = {
        dotNumber: dotId.value,
        inspectionDate: tempDate,
        crossingChanges: newInspection.value.crossingChanges,
        inspectedBy: newInspection.value.inspectedBy,
        comments: newInspection.value.comments,
        userID: userData.value?.userID,
      };

      axios
        .post(CONFIG_ENDPOINT.INSPECTIONS + dotId.value, responseObject)
        .then((response) => {
          projectList.value = response.data.data;
          isInitialised.value = true;
          valueChanged.value = false;
          newInspection.value = {};
          originalValue.value = {};
          paginationData.value.total = response.data.totalElements;
          fetchData();
        })
        .catch((err) => {
          projectList.value = [];
          newInspection.value = {};
          originalValue.value = {};
        });
    };
    const cancelData = () => {
      isInitialised.value = true;
      valueChanged.value = false;
      newInspection.value = {};
      originalValue.value = {};
    };
    const dateChange = (event) => {
      if (event?.target?.value) {
        newInspection.value.inspectionDate = event.target.value;
      } else if (event) {
        newInspection.value.inspectionDate = event;
      }
    };
    const isDateValidated = ref(false);
    const checkDateValidation = (value) => {
      isDateValidated.value = value;
    };
    const checkFormValidation = computed({
      get: () => {
        return isDateValidated.value;
      },
    });

    return {
      dateChange,
      checkDateValidation,
      checkFormValidation,
      projectList,
      loading,
      headers,
      showBannerMessage,
      dotId,
      newInspection,
      saveData,
      cancelData,
      paginationData,
      updatePaginationPerPage,
      pageChange,
      sortData,
      sortUpdate,
      requiredRule,
      finalValidation,
      commentMaxLength,
      preventNumericInput,
      today,
      validDateFutureDate_Inspections,
      isUserInspectionAccess,
    };
  },
};
