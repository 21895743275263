import useMapFunctions from "@/composables/gisMap";import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import HopLocationInfo from "@/modules/locationInformation/components/HopLocationInfo.vue";
import DataTable from "@/modules/shared/components/DataTable.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import axios from "axios";
import { onMounted, ref, watch } from "vue";
export default {
  name: "App",
  components: { DataTable, LoadingOverlay, PrimaryButton, HopLocationInfo },
  props: ["id"],
  setup(props) {
    const { navigateToGISMap } = useMapFunctions();
    const id = ref();
    let locationInfo = ref([]);
    let bolInfo = ref([]);
    let urHopInfo = ref([]);
    let loading = ref(true);
    let itemsPerPage = ref(5);
    let locationHeaders = ref([
      {
        title: "County",
        key: "county",
        sortType: "text",
      },
      {
        title: "SR",
        key: "stateRoute",
        sortType: "text",
      },
      {
        title: "SEC",
        key: "sec",
        sortType: "text",
      },
      {
        title: "Begin Segment",
        key: "beginSegment",
        sortType: "text",
      },
      {
        title: "Begin Offset",
        key: "beginOffset",
        sortType: "text",
      },
      {
        title: "Begin Lat/Long",
        key: "beginLatLong",
        sortType: "text",
      },
      {
        title: "End Segment",
        key: "endSegment",
        sortType: "text",
      },
      {
        title: "End Offset",
        key: "endOffset",
        sortType: "text",
      },
      {
        title: "Road Name",
        key: "roadName",
        sortType: "text",
      },
      {
        title: "End Lat/Long",
        key: "endLatLong",
        sortType: "text",
      },
    ]);
    let bolHeaders = ref([
      {
        title: "Licensee Name",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        key: "licenseName",
        sortType: "text",
      },
      {
        title: "SR",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        key: "srNo",
        sortType: "text",
      },
      {
        title: "Segment",
        filter: null,
        key: "segment",
        sortType: "text",
      },
      {
        title: "Offset",
        filter: null,
        key: "offSet",
        sortType: "text",
      },
      {
        title: "License #",
        filter: null,
        key: "licenseNo",
        sortType: "text",
      },
      {
        title: "BR-Key",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        key: "brKey",
        sortType: "text",
      },
      {
        title: "Status",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        key: "statusCode",
        sortType: "text",
      },
      {
        title: "Sub Type",
        filter: null,
        key: "subType",
        sortType: "text",
      },
      {
        title: "Ultimate Weight",
        filter: null,
        key: "ultimateWeight",
        sortType: "text",
      },
      {
        title: "Date Issued",
        filter: null,
        key: "dateIssued",
        sortType: "text",
      },
    ]);
    let hopHeaders = ref([
      {
        title: "Applicant Name",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        key: "applicantName",
        sortType: "text",
      },
      {
        title: "County",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        key: "countyName",
        sortType: "text",
      },
      {
        title: "SR",
        key: "stateRoute",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
      },
      {
        title: "Begin Segment",
        key: "segmentBegin",
        sortType: "text",
      },
      {
        title: "End Segment",
        key: "segmentEnd",
        sortType: "text",
      },
      {
        title: "Begin Offset",
        key: "offsetBegin",
        sortType: "text",
      },
      {
        title: "End Offset",
        key: "offsetEnd",
        sortType: "text",
      },
      {
        title: "Status",
        key: "applStatus",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
      },
      {
        title: "Permit #",
        key: "permitNo",
        sortType: "text",
      },
      {
        title: "Sub Type",
        key: "subType",
        sortType: "text",
      },
      {
        title: "Permit Use",
        key: "permitUse",
        sortType: "text",
      },
      {
        title: "Date Issued",
        key: "dateIssued",
        sortType: "date",
      },
    ]);
    let urHopHeaders = ref([
      {
        title: "Utility Name",
        key: "utilityName",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
      },
      {
        title: "SR",
        key: "sr",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
      },
      {
        title: "SEC",
        key: "sec",
        sortType: "text",
      },
      {
        title: "MPMS #",
        key: "mpmsNumber",
        sortType: "number",
      },
      {
        title: "Permit #",
        key: "permitNumber",
        sortType: "text",
      },
      {
        title: "Date Issued",
        key: "dateIssued",
        sortType: "text",
      },
    ]);
    watch(
      () => [props.id],
      () => {
        if (props.id) {
          id.value = props.id;
          getProjectLocations();
          getBOLInfo();
          getURHOPInfo();
        }
      },
      { deep: true }
    );
    let locationInfoLoading = ref("Loading data from E-Permittng System (EPS)");
    let bolInfoLoading = ref(
      "BOLs from E-Permitting System (EPS) are loading ..."
    );
    let urHopInfoLoading = ref(
      "UR HOPs from E-Permitting System (EPS) are loading ..."
    );
    onMounted(async () => {
      id.value = props.id;
      loading.value = true;
      if (id.value) {
        getProjectLocations();
        getBOLInfo();
        getURHOPInfo();
      }
    });
    async function getProjectLocations() {
      axios
        .get(CONFIG_ENDPOINT.LOCATION_INFO_LOCATION + id.value)
        .then((response) => {
          locationInfo.value = response.data;
          locationInfoLoading.value = "No Data Available";
          locationInfoLoading.value = "No Data Available";
          locationHeaders.value.forEach((header, index) => {
            locationHeaders.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(locationInfo.value, header.key),
            };
          });
        })
        .catch((err) => {
          locationInfoLoading.value = "No Data Available";
          locationInfoLoading.value = "No Data Available";
          console.log(err);
          locationInfo.value = [];
        });
    }
    async function getBOLInfo() {
      axios
        .get(CONFIG_ENDPOINT.LOCATION_INFO_BOL_INFO + id.value)
        .then((response) => {
          bolInfo.value = response.data;
          bolInfoLoading.value = "No Data Available";
          bolInfoLoading.value = "No Data Available";
          bolHeaders.value.forEach((header, index) => {
            bolHeaders.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(bolInfo.value, header.key),
            };
          });
        })
        .catch((err) => {
          bolInfoLoading.value = "No Data Available";
          bolInfoLoading.value = "No Data Available";
          console.log(err);
          bolInfo.value = [];
        });
    }
    async function getURHOPInfo() {
      axios
        .get(CONFIG_ENDPOINT.LOCATION_INFO_URHOP_INFO + id.value)
        .then((response) => {
          urHopInfo.value = response.data;
          urHopInfoLoading.value = "No Data Available";
          urHopInfoLoading.value = "No Data Available";
          urHopHeaders.value.forEach((header, index) => {
            urHopHeaders.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(urHopInfo.value, header.key),
            };
          });
        })
        .catch((err) => {
          urHopInfoLoading.value = "No Data Available";
          urHopInfoLoading.value = "No Data Available";
          console.log(err);
          urHopInfo.value = [];
        });
    }
    const checkFieldDisabled = (list, key) => {
      if (!list.length) {
        return true;
      }
      if (key === "projectIdentifier") {
        return true;
      }
      let availableData = list.filter((data) => !data[key]);
      return availableData.length === list.length;
    };
    return {
      id,
      locationInfo,
      bolInfo,
      urHopInfo,
      loading,
      itemsPerPage,
      locationHeaders,
      bolHeaders,
      hopHeaders,
      urHopHeaders,
      locationInfoLoading,
      bolInfoLoading,
      urHopInfoLoading,
      navigateToGISMap,
    };
  },
};
