import useDateField from "@/composables/dateField.js";import useMapFunctions from "@/composables/gisMap";
import { arrayRequiredRule, requiredRule } from "@/composables/validationRules";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent1 from "@/modules/manageproject/components/RailroadWorkSummaryDocumentDialog.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useDocumentStore } from "@/stores/document";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
import { ADD_RAILROAD_NO_DATA_FOUND } from "../../../constants/displayTexts";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    BannerMessage,
    DialogComponent1,
    DocumentsList,
  },
  name: "RailroadWorkSummary",
  props: ["dotId"],
  emits: ["refreshMpmsData"],
  setup(props, { emit }) {
    const documentStore = useDocumentStore();
    const updateDocumentList = ref(false);
    const railroadWorkSummaryDocumentDialogRef = ref(null);
    const filesList = ref([]);
    const otherButtonClicked = () => {
      openDialog(false);
    };
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };

    async function fetchEstimateData() {
      updateDocumentList.value = true;
    }

    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const { navigateToGISMap } = useMapFunctions();
    const route = useRoute();
    const id = route.params.id;
    const projectInvolvementData = ref(null);
    const isFormValid = ref(false);
    const showBannerMessage = ref(false);
    const valueChanged = ref(false);
    const originalValue = ref();
    const isInitialised = ref(false);
    const selectedRailroad = ref(null);
    const railroadList = ref([]);
    const railroadMap = ref({});
    const lookupData = ref({});
    const selectedRailroadDetails = ref({});
    const originalSelectedRailroadDetails = ref({});
    const finalObject = ref({});
    const workTypeMap = ref({});
    const railroadId = ref(null);
    const crossingDetails = ref([]);
    const originalCrossingDetails = ref([]);
    const showSaveBannerMessage = ref(false);
    const staticRailroad = ref(false);
    const isLoading = ref(true);
    const crossingInitialized = ref(0);
    const dataChanged = ref(false);
    const dotNumbersList = ref([]);
    let formData = ref(null);
    const detailMaxLength = computed({
      get: () => {
        return 500;
      },
    });

    const isUserRailroadWorkSummaryAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const railroadAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROAD_WORK_SUMMARY_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROAD_WORK_SUMMARY_PAGE_CRTUPD_ACCESS
      );
      isUserRailroadWorkSummaryAccess.value.read = readResponse || false;
      isUserRailroadWorkSummaryAccess.value.update = updateResponse || false;
      isLoading.value = false;
    };
    const hasDataChanged = (newData, originalData) => {
      return JSON.stringify(newData) !== JSON.stringify(originalData);
    };
    onMounted(async () => {
      await railroadAccessCheck();
      if (isUserRailroadWorkSummaryAccess.value.read) {
        isInitialised.value = false;
        valueChanged.value = false;
        getProjectInvolvementData();
        fetchLookupData();
      }
    });
    watch(
      () => [
        selectedRailroad.value,
        selectedRailroadDetails.value,
        crossingDetails.value,
      ],
      (newVal, oldVal) => {
        if (
          isInitialised.value /* originalSelectedRailroad.value != selectedRailroad.value || */ &&
          hasDataChanged(
            originalSelectedRailroadDetails.value,
            selectedRailroadDetails.value
          )
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    watch(
      () => selectedRailroad.value,
      () => {
        if (selectedRailroad.value) {
          fetchRailroad();
          fetchCrossingDetails();
        }
      }
    );

    watch(
      () => crossingDetails.value,
      () => {
        if (crossingInitialized.value == 1) {
          crossingInitialized.value += 1;
        } else {
          crossingInitialized.value += 1;
          dataChanged.value = true;
        }
      },
      { deep: true }
    );

    watch(
      () => selectedRailroadDetails.value,
      () => {
        if (
          hasDataChanged(
            originalSelectedRailroadDetails.value,
            selectedRailroadDetails.value
          )
        ) {
          dataChanged.value = true;
        } else if (crossingInitialized.value == 1) {
          dataChanged.value = false;
        } else if (crossingInitialized.value >= 3) {
          dataChanged.value = true;
        } else {
          dataChanged.value = false;
        }
      },
      { deep: true }
    );

    const updateButtonClicked = (event) => {
      openDialog(true, event);
    };

    const openDialog = (isUpdate, event) => {
      formData.value = [];
      railroadWorkSummaryDocumentDialogRef.value
        .open(
          isUpdate
            ? "Update Document"
            : "View/Upload Railroad Work Summary Documents",
          id,
          selectedRailroad,
          railroadList.value,
          isUpdate,
          event,
          dotNumbersList.value,
          railroadId.value
        )
        .then((resolve) => {
          if (resolve) {
            fetchEstimateData();
          }
        });
    };
    function getProjectInvolvementData() {
      axios
        .get(CONFIG_ENDPOINT.VIEW_PROJECT_INVOLVEMENT + id)
        .then((res) => {
          projectInvolvementData.value = setProjectInvolvement(res.data);
          originalValue.value = JSON.parse(
            JSON.stringify(setProjectInvolvement(res.data))
          );
          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    function fetchCrossingDetails() {
      axios
        .get(
          CONFIG_ENDPOINT.RAILROAD_WORK_SUMMARY_CROSSING_DETAILS +
            id +
            "/" +
            railroadId.value
        )
        .then((response) => {
          originalCrossingDetails.value = response.data.data;
          crossingDetails.value = response.data.data;
          crossingInitialized.value = 1;
          dotNumbersList.value = [];
          for (let index in crossingDetails.value) {
            dotNumbersList.value.push(
              crossingDetails.value[index].dotNumber.replace("DOT# ", "")
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const getNoDataColspan = computed({
      get: () => {
        let totalColumns = 10;
        return totalColumns;
      },
    });

    function fetchLookupData() {
      axios
        .get(CONFIG_ENDPOINT.RAILROAD_FORCE_ACCOUNT_ESTIMATE_LOOKUP + id)
        .then((response) => {
          lookupData.value = response.data;
          createLookupMap();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const createLookupMap = () => {
      railroadList.value = [];
      for (let railroad in lookupData.value.railroads) {
        let temp = lookupData.value.railroads[railroad].railroadCompanyName;
        railroadList.value.push(temp);
        railroadMap.value[temp] =
          lookupData.value.railroads[railroad].railroadCompanyId;
      }
      if (railroadList.value.length == 1) {
        selectedRailroad.value = railroadList.value[0];
        staticRailroad.value = true;
      } else {
        staticRailroad.value = false;
      }
    };
    function fetchRailroad() {
      railroadId.value = railroadMap.value[selectedRailroad.value];
      axios
        .get(
          CONFIG_ENDPOINT.RAILROAD_WORK_SUMMARY_GET_RAILROAD +
            id +
            "/" +
            railroadId.value
        )
        .then((response) => {
          finalObject.value = response.data.data;
          selectedRailroadDetails.value = setProjectInvolvement(
            finalObject.value
          );
          originalSelectedRailroadDetails.value = setProjectInvolvement(
            finalObject.value
          );
          selectedRailroadDetails.value.estimatedConstructionStartDate =
            getDate(
              selectedRailroadDetails.value.estimatedConstructionStartDate
            );
          originalSelectedRailroadDetails.value.estimatedConstructionStartDate =
            getDate(
              selectedRailroadDetails.value.estimatedConstructionStartDate
            );
          fetchEstimateData();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const setProjectInvolvement = (list) => {
      let selectedWorkTypes = [];
      for (let index in list.workTypes) {
        workTypeMap.value[list.workTypes[index].workType] =
          list.workTypes[index];
        if (list.workTypes[index].associationPresent) {
          selectedWorkTypes.push(list.workTypes[index].workType);
        }
      }
      list = {
        ...list,
        selectedWorkTypes: selectedWorkTypes,
      };
      return list;
    };
    const saveClicked = () => {
      for (let index in selectedRailroadDetails.value.selectedWorkTypes) {
        workTypeMap.value[
          selectedRailroadDetails.value.selectedWorkTypes[index]
        ].associationPresent = true;
      }
      let crossingDetailsObject = [];
      for (let index in crossingDetails.value) {
        let object = {
          dotNumber: crossingDetails.value[index].dotNumber,
          plannedWork: crossingDetails.value[index].plannedWork,
          workOrderNumber: crossingDetails.value[index].workOrderNumber,
        };
        crossingDetailsObject.push(object);
      }
      let object = {
        railroadProjectIdentifier:
          selectedRailroadDetails.value.railroadProjectIdentifier,
        workTypes: selectedRailroadDetails.value.workTypes,
        crossingDetails: crossingDetailsObject,
      };
      postObject(object);
    };
    const cancelSave = () => {
      window.scrollTo(0, 0);
      fetchRailroad();
      fetchCrossingDetails();
      isInitialised.value = false;
      valueChanged.value = false;
      dataChanged.value = false;
    };
    const hideBanner = () => {
      showSaveBannerMessage.value = false;
    };
    const removeWorkType = (railroadIndex, index) => {
      let name = selectedRailroadDetails.value.selectedWorkTypes[index];
      selectedRailroadDetails.value.selectedWorkTypes.splice(index, 1);

      for (let index in selectedRailroadDetails.value.workTypes) {
        if (selectedRailroadDetails.value.workTypes[index].workType == name) {
          selectedRailroadDetails.value.workTypes[
            index
          ].associationPresent = false;
        }
      }
    };
    const postObject = (object) => {
      axios
        .post(
          CONFIG_ENDPOINT.RAILROAD_WORK_SUMMARY_POST +
            id +
            "/" +
            railroadId.value,
          object
        )
        .then((response) => {
          getProjectInvolvementData();
          fetchLookupData();
          showSaveBannerMessage.value = true;
          window.scrollTo(0, 0);
          emit("refreshMpmsData", true);
          isInitialised.value = false;
          valueChanged.value = false;
          dataChanged.value = false;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          showBannerMessage.value = false;
          emit("BannerUpdate", {
            bannerType: "success",
            bannerMessage: "",
            showBanner: showBannerMessage.value,
          });
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    });
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    return {
      documentStore,
      updateDocumentList,
      railroadWorkSummaryDocumentDialogRef,
      filesList,
      updateDocumentListFlag,
      otherButtonClicked,
      id,
      projectInvolvementData,
      arrayRequiredRule,
      saveClicked,
      detailMaxLength,
      isFormValid,
      hideBanner,
      showBannerMessage,
      ADD_RAILROAD_NO_DATA_FOUND,
      cancelSave,
      selectedRailroad,
      railroadList,
      railroadMap,
      createLookupMap,
      fetchLookupData,
      lookupData,
      selectedRailroadDetails,
      removeWorkType,
      finalObject,
      postObject,
      workTypeMap,
      fetchCrossingDetails,
      crossingDetails,
      getNoDataColspan,
      navigateToGISMap,
      showSaveBannerMessage,
      staticRailroad,
      requiredRule,
      updateButtonClicked,
      fetchEstimateData,
      isUserRailroadWorkSummaryAccess,
      isLoading,
      dataChanged,
      originalSelectedRailroadDetails,
      originalCrossingDetails,
      crossingInitialized,
      dotNumbersList,
      railroadId,
    };
  },
};
