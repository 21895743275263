import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useUserStore = defineStore("user", () => {
  let loggedInUser = ref(null);
  let isAuthenticated = ref(false);

  const setUser = (user) => {
    loggedInUser.value = user;
  };
  const setIsAuthenticated = (value) => {
    isAuthenticated.value = value;
  };

  const getTimeOutData = async () => {
    let url = CONFIG_ENDPOINT.GCMS_MANAGEUSER_TIMEOUT;
    return await axios
      .get(url)
      .then((response) => {
        console.log("response.data.data", response.data.data);
        return response.data.data;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  return {
    loggedInUser,
    isAuthenticated,
    setUser,
    setIsAuthenticated,
    getTimeOutData,
  };
});
