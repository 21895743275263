<template>
  <v-dialog v-model="dialog" max-width="800px" max-height="3000px">
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">PUC Documents</div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text>
          <v-form v-model="isFormValid" class="scoping-dialog-form">
            <v-row>
              <v-col class="dialogLabeltext" cols="8">
                <label for="docketNum">Docket Number: </label>
                <label for="docketNum">{{ docketNum }} </label>
              </v-col>
            </v-row>

            <br />
            <div v-for="index in linksArray" :key="index">
              <v-row cols="12" style="display: flex">
                <v-col v-if="!pucNotExist">
                  <v-row cols="12" style="display: flex">
                    <v-col
                      style="display: flex; padding-left: 12px !important"
                      cols="6"
                    >
                      <button
                        @click="clicked(index.docURL)"
                        type="button"
                        class="pucLink"
                      >
                        {{ index.documentName }}
                      </button>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-col v-if="pucNotExist">
              <label for="error" class="pucError">
                {{ error }}
              </label>
            </v-col>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import PucDocumentsModal from "@/modules/dot-details/scripts/PucDocumentsModal.js";
export default PucDocumentsModal;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
@import "@/modules/cost-and-funding/assets/ScopingRailRoadCostsDialog.scss";
</style>
