<template>
  <v-dialog v-model="dialog" max-width="800px" max-height="3000px">
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24 confirmationTitle">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text>
          <div class="impactedMessage">
            {{ confirmationMessage }}
          </div>
          <br />
          <v-col v-if="displayError">
            <label class="pucError" for="error">
              {{ error }}
            </label>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-row class="confirmationButtons" cols="12">
            <PrimaryButton
              @buttonClicked="confirmAction"
              :buttonText="'YES'"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'NO'"
              class="ml-10"
            ></SecondaryButton>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
  <script>
import ContactListDeleteConfirmation from "@/modules/gcmsmain/admin/submenu/scripts/ContactListDeleteConfirmation.js";
export default ContactListDeleteConfirmation;
</script>
  <style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
@import "@/modules/cost-and-funding/assets/ScopingRailRoadCostsDialog.scss";
</style>
  